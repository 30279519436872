import { useWealthaboutStore } from "@/stores/wealthaboutStore";

export default defineNuxtPlugin(nuxtApp => {
  const { cleanUser, cleanClient } = useWealthaboutStore();
  const { status, token, refresh, signIn, signOut, refreshToken } = useAuth();
  let setTimeoutAutoLogout = null;

  // Función para hacer login
  const login = async (username, password) => {
    await signIn({ username, password }, { redirect: false });
    if (status.value === "authenticated") {
      // Se guarda el refresh token ya que nuxt-auth no lo guarda por defecto
      createAutoLogout(nuxtApp.$helpers.parseJwt(refreshToken.value));
    } else {
      const error = new Error("Error al iniciar sesión");
      error.response = { status: 401 };
      throw error;
    }
  };

  // Función para hacer logout
  const logout = (isAutoLogout = false) => {
    deleteAutoLogout();
    // Si estamos en el cliente, se hace logout de nuxt-auth
    signOut();
    isAutoLogout
      ? useRouter().push("/login?autoLogout=" + isAutoLogout)
      : useRouter().push("/login");
    setTimeout(() => {
      cleanUser();
      cleanClient();
    }, 300);
  };

  // Función para crear un setTimeout que haga logout automáticamente
  const createAutoLogout = refreshTokenDecoded => {
    // No hacemos nada si estamos en el servidor
    if (import.meta.server) return;
    // Antes de crear un nuevo setTimeoutAutoLogout, se elimina el anterior
    deleteAutoLogout();
    // Se crea según el tiempo de expiración del refreshToken mas 1 segundo
    setTimeoutAutoLogout = setTimeout(
      () => logout(true),
      new Date(refreshTokenDecoded.exp * 1000) - Date.now() + 1000
    );
  };
  const deleteAutoLogout = () => {
    // Eliminamos el setTimeoutAutoLogout
    clearTimeout(setTimeoutAutoLogout);
  };

  // Función para comprobar si el token ha expirado y si no, pedir un nuevo token o hacer logout si el refresh token ha expirado
  const checkRefreshToken = async () => {
    try {
      // Si el usuario no ha hecho login, no se hace nada
      if (status.value === "unauthenticated") return false;
      const tokenDecoded = nuxtApp.$helpers.parseJwt(token.value);
      // Se comprueba si el token ha expirado
      if (Date.now() > new Date(tokenDecoded.exp * 1000)) {
        // El token ha expirado
        const refreshTokenDecoded = nuxtApp.$helpers.parseJwt(
          refreshToken.value
        );
        // Si el refresh token no ha expirado, se pide un nuevo token
        if (Date.now() < new Date(refreshTokenDecoded.exp * 1000)) {
          await fetchRefreshToken();
          return true;
        } else {
          // Si el refresh token ha expirado, se hace logout
          logout(true);
          return false;
        }
      }
      return true;
    } catch (error) {
      console.error("Error en checkRefreshToken", error);
      console.error("Error al comprobar el token:", token.value);
      console.error("Error al comprobar el refresh token:", refreshToken.value);
      console.error("Se procede a hacer logout");
      logout(true);
      return false;
    }
  };
  // Función para pedir un nuevo token y guardarlo en las cookies
  const fetchRefreshToken = async () => {
    deleteAutoLogout();
    // Llamada a la API para pedir un nuevo token
    await refresh();
    if (refreshToken.value) {
      // Se actualizan los tokens
      createAutoLogout(nuxtApp.$helpers.parseJwt(refreshToken.value));
    }
  };
  // Si estamos en el cliente se añaden los triggers de eventos
  if (import.meta.client) {
    // Tiempo de espera para ejecutar la función de comprobación del token
    const debounceTime = 1000;

    // Creamos objetos para pasar las variables por referencia
    const debounceMouseMove = { id: null };
    const debounceScroll = { id: null };

    const debounceCheckRefreshToken = debounce => {
      return () => {
        if (debounce.id) clearTimeout(debounce.id);
        debounce.id = setTimeout(checkRefreshToken, debounceTime);
      };
    };

    // TRIGGERS de eventos para detectar actividad del usuario

    window.addEventListener(
      "mousemove",
      debounceCheckRefreshToken(debounceMouseMove)
    );
    window.addEventListener(
      "scroll",
      debounceCheckRefreshToken(debounceScroll)
    );
    window.addEventListener("keypress", checkRefreshToken);
    window.addEventListener("click", checkRefreshToken);
  }
  return {
    provide: {
      session: {
        login,
        logout,
        checkRefreshToken,
      },
    },
  };
});
