import { getCustomRequiredActions } from "@/assets/logic/wizard/utils.js";
import { wizardRequiredActions, wizardHighlightElement } from "@/assets/logic/types";

export default {
  [wizardRequiredActions.PURCHASEPRICE]: {
    title: getCustomRequiredActions(wizardRequiredActions.PURCHASEPRICE),
    description:
      "Rellene el coste de adquisición. Es un dato importante para calcular la plusvalía, que puede tener un impacto importante en caso de venta futura del inmueble. Introduce el valor directamente en el campo resaltado.",
    highlightElement: wizardHighlightElement.PURCHASEPRICE_PROPERTY_FIELD,
    closeOnClick: false
  },
  [wizardRequiredActions.HABITUALLIVING]: {
    title: getCustomRequiredActions(wizardRequiredActions.HABITUALLIVING),
    description: "Marca este inmueble como vivienda habitual si es parte de tu lugar de residencia.",
    highlightElement: wizardHighlightElement.ASSET_CARD_INFO_ACTIONS,
    actionsDiscard: "Descartar"
  },
  [wizardRequiredActions.MORTGAGE]: {
    title: getCustomRequiredActions(wizardRequiredActions.MORTGAGE),
    description: "¿Tienes hipoteca?",
    actionsDiscard: "No tengo",
    actionsContinue: "Tengo",
    steps: [
      {
        description: "Si el inmueble tiene hipoteca, establezca sus condiciones aquí, pinchando en los tres puntos.",
        highlightElement: wizardHighlightElement.ASSET_CARD_INFO_ACTIONS,
        nextOnClick: true
      }
    ]
  },
  [wizardRequiredActions.CADASTRALREFERENCE]: {
    title: getCustomRequiredActions(wizardRequiredActions.CADASTRALREFERENCE),
    description: "Rellene la referencia catastral. Es un dato importante para obtener información sobre el inmueble.",
    actionsContinue: "Tiene",
    actionsDiscard: "No tiene",
    steps: [
      {
        description: "Introduce el valor directamente en el campo resaltado.",
        highlightElement: wizardHighlightElement.CADASTRAL_PROPERTY_FIELD,
        closeOnClick: false
      }
    ]
  },
  [wizardRequiredActions.CADASTRALVALUE]: {
    title: getCustomRequiredActions(wizardRequiredActions.CADASTRALVALUE),
    description:
      "Rellene los valores catastrales de la superficie y del suelo. Es un dato importante para calcular la plusvalía, que puede tener un impacto importante en caso de venta futura del inmueble.",
    actionsContinue: "Completar",
    steps: [
      {
        description: "Introduce el valor en el campo resaltado.",
        highlightElement: wizardHighlightElement.CADASTRAL_PROPERTY_VAlUE_SURFACE,
        actionsContinue: "Siguiente",
        closeOnClick: false
      },
      {
        description: "Introduce el valor en el campo resaltado.",
        highlightElement: wizardHighlightElement.CADASTRAL_PROPERTY_VALUE_CONSTRUCTION,
        closeOnClick: false
      }
    ]
  },
  [wizardRequiredActions.PROPERTYVALUE]: {
    title: getCustomRequiredActions(wizardRequiredActions.PROPERTYVALUE),
    description: "Rellene el valor del inmueble. Es un dato imprescindible para múltiples análisis y proyecciones.",
    highlightElement: wizardHighlightElement.PREFIX_CHART_EVOLUTION_TITLE_FIELD(wizardHighlightElement.PROPERTY_VALUE),
    closeOnClick: false
  }
};
