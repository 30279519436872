export default {
  // Paleta Figma
  gray50: "#F5F5F5",
  gray100: "#C0C0C0",
  gray500: "#505050",
  grey200: "#AAAAAA",
  green50: "#C6C4DC",
  blue50: "#E8EAFE",
  darkBlue50: "#DFFCFC",
  yellow50: "#FCE4E0",
  yellow500: "#F39483",
  orange50: "#E0F8FC",
  orange500: "#83E2F3",
  red50: "#FEF2F1",
  red500: "#FF406E",

  // Monitor lo que tengo
  privateDebt500: "#98CEFF",
  otherAssets500: "#72B6D4",

  // Charts (Negative)
  negativeChart3: "#FFA426",
  negativeChart5: "#FFEA03",

  // Charts (Positive)
  positiveChart5: "#00EDCA",
  positiveChart7: "#FFAD31",
  evolutionChart: "#A8AFCA",

  // Liquidez
  notLiquid: "#5F819F",

  // Unidad Familiar
  memberChart1: "#F72585",
  memberChart2: "#7209B7",
  memberChart3: "#3A0CA3",
  memberChart4: "#4361EE",
  memberChart6: "#26F2FF",

  // Nuevos colores --> Diseño de Victor Huelamo --> fecha 30/marzo/2022

  // SECTIONS

  // Familia -> Members
  colorSectionFamily01: "#FE595E",

  // Invest
  colorSectionInvest01: "#FFB933",

  // Debt
  colorSectionDebt01: "#FFCA3A",

  // Property
  colorSectionProperty01: "#8AC926",

  // Other
  colorSectionOther01: "#6A4C93",

  // CHARTS

  // VitalMonitor
  balance: "#764B9D",
  savings: "#764B9D",
  liquidity: "#764B9D",
  active: "#4359F7",
  passive: "#EF5A6F",
  income: "#4359F7",
  expense: "#EF5A6F",

  // Line chart
  colorChartLineMain: "#4359F7",
  colorChartLineSecondary: "#D76A66",

  // Pie chart
  colorChartOthers: "#ACACAC",

  // Wizard
  colorMaturityChartValueBackground: "#4359F72A",

  // FONTS
  colorTypeMain: "#2F3343",

  // EVENTS
  colorAssetValue: "#429BBE",
  colorAssetIncome: "#2A9D8F",
  colorAssetMortgage: "#D6A839",

  // SUBCATEGORIES
  // hogar
  community: "#DC832B",
  housingRental: "#E59854",
  supplies: "#ECAE7B",
  houseKeeping: "#F1C4A1",
  telephoneTvInternet: "#F2DAC8",
  decorationFurniture: "#F5CFC0",
  homeInsurance: "#E36738",
  ford: "#EC6738",
  ibi: "#D63F00",
  domesticService: "#F1C4A2",

  // vehículo y transporte
  vehicleMaintenance: "#D8AC3B",
  vehicleTransport: "#E0B962",
  publicTransport: "#E7C786",
  taxis: "#ECD4A9",
  vehicleInsurance: "#F0E2CD",
  vehicleLoan: "#E8E6C8",
  tolls: "#DEDCA0",
  parkingGarage: "#D2D277",
  fuel: "#C4C84C",

  // compras
  financialCards: "#028BA8",
  electronics: "#7FB3C5",
  pets: "#A6C7D3",
  clothesComplements: "#CCDCE2",
  giftsToys: "#CDD1E6",
  beautyPerfumery: "#A8B2DC",

  // ocio y viajes
  leisureTravel: "#17B77C",
  cinemaTheater_shows: "#5BC392",
  hotelLodging: "#85CFA9",
  lotteriesBets: "#ABDBC1",
  cafesRestaurants: "#CEE6D8",
  booksMusicGames: "#D2EAED",
  trainPlaneTransport: "#B2E3E9",

  // otros gastos
  feesInterest: "#54478C",
  otherLoansGuarantees: "#7466A0",
  otherInsurance: "#9387B4",
  taxPayments: "#B2A9C8",
  transfers: "#D1CCDC",
  subscriptions: "#E0C8E8",
  ong: "#CE9FE0",
  otherExpenses: "#BA76D6",
  cashiers: "#A54ACC",
  checks: "#8C00C2",
  dividends: "#4359F7",
  extraordinaryDividends: "#FDBB84",

  // alimentación
  supermarketsFood: "#82A448",

  // educación, salud y deporte
  educationHealthSport: "#D8D100",
  healthInsurance: "#E1D74F",
  optics: "#E8DD7B",
  pharmacy: "#EDE3A3",
  education: "#F0EACA",
  sportGym: "#D5E3D1",
  dentistDoctor: "#B9D6B2",
  lifeInsurance: "#9DC894",
  extracurricularActivities: "#80BA76",

  // Calendario vital
  vitalCalendarBirthdateFill: "#FFF2C5",
  vitalCalendarBirthdateBorder: "#FFE49F",
  vitalCalendarSchoolFill: "#E5F5E0",
  vitalCalendarSchoolBorder: "#A1D99B",
  vitalCalendarUniversityFill: "#FEE8C8",
  vitalCalendarUniversityBorder: "#FDBB84",
  vitalCalendarLaboralLifeFill: "#E0E7FF",
  vitalCalendarLaboralLifeBorder: "#A5B4FC",
  vitalCalendarEmancipationFill: "#FFD8D8",
  vitalCalendarEmancipationBorder: "#FF9F9F",
  vitalCalendarRetirementFill: "#E5F5E0",
  vitalCalendarRetirementBorder: "#A1D99B",
  vitalCalendarDeathFill: "#FFD8D8",
  vitalCalendarDeathBorder: "#FF9F9F",
  vitalCalendarFamilyIncorporationFill: "#E0E7FF",
  vitalCalendarFamilyIncorporationBorder: "#A5B4FC",
  vitalCalendarNullFill: "#FAFAFA",
  vitalCalendarNullBorder: "#EAEAEA",

  // New colors

  // General
  colorWhite: "#FFFFFF",
  colorBlack: "#000000",

  // Corporate
  corporateLightBlue: "#00E5E5",
  corporateDarkBlue: "#1B1475",
  corporateBlue: "#4359F7",
  corporateGradient: "linear-gradient(90deg, #4359F7 0%, #00E5E5 100%)",
  corporateRadial: "radial-gradient(#00E5E5, #4359F7)",
  corporateP01: "#00E5E5",
  corporateP02: "#08C2D3",
  corporateP03: "#0C8AB4",
  corporateP04: "#10639F",
  corporateP05: "#17438E",
  corporateP06: "#1B1475",

  // Indigo palette
  colorIndigo10: "#F8F9FF",
  colorIndigo25: "#F2F5FF",
  colorIndigo50: "#EEF2FF",
  colorIndigo100: "#E0E7FF",
  colorIndigo200: "#C7D2FE",
  colorIndigo300: "#A5B4FC",
  colorIndigo400: "#818CF8",
  colorIndigo500: "#6366F1",
  colorIndigo600: "#4F46E5",
  colorIndigo700: "#4338CA",
  colorIndigo800: "#3730A3",
  colorIndigo900: "#312E81",

  // Grey palette
  colorGrey10: "#FAFAFA",
  colorGrey25: "#EAEAEA",
  colorGrey50: "#D5D5D5",
  colorGrey100: "#BFBFBF",
  colorGrey200: "#AAAAAA",
  colorGrey300: "#959595",
  colorGrey400: "#808080",
  colorGrey500: "#6A6A6A",
  colorGrey600: "#555555",
  colorGrey700: "#404040",
  colorGrey800: "#2B2B2B",
  colorGrey900: "#151515",

  // Carmine palette
  colorCarmine10: "#FFF8F8",
  colorCarmine25: "#FFEBEB",
  colorCarmine50: "#FFD8D8",
  colorCarmine100: "#FFC5C5",
  colorCarmine200: "#FFB2B2",
  colorCarmine300: "#FF9E9F",
  colorCarmine400: "#E77A7A",
  colorCarmine500: "#CF5555",
  colorCarmine600: "#C34343",
  colorCarmine700: "#B72F30",
  colorCarmine800: "#AB1E1D",
  colorCarmine900: "#9F0A0B",

  // Members
  owner: "#F72485",
  partner: "#7208B7",
  child1: "#4361EE",
  child2: "#4895EF",
  child3: "#4AAFF0",
  child4: "#4CC9F0",
  child5: "#39DEF8",
  child6: "#26F2FF",
  dependant1: "#F75F39",
  dependant2: "#F07E4D",
  dependant3: "#F0954B",
  dependant4: "#F0AD48",

  // Assets
  member: "#FF595F",
  property: "#8AC926",
  investment: "#FFB933",
  otherAsset: "#6A4C93",
  portfolio: "#1982C4",
  privateDebt: "#98CDFF",
  holding: "#FFB933",

  // Liability
  mortgage: "#FF5E59",
  credit: "#FFA426",
  otherLiability: "#FFEA03",

  // Income
  incomeJob: "#A5B4FC",
  incomePension: "#A1D99B",
  incomeOthers: "#F7B787",
  incomeExtraordinary: "#FFDD95",
  incomeProperty: "#8AC926",
  incomeInvestment: "#FFB933",
  incomeOtherAssets: "#6A4C93",

  // Investment-type
  deposit: "#FDCDAB",
  bankAccount: "#B4E2CD",

  // Expense
  food: "#E7298A",
  shopping: "#7FC97F",
  freeTime: "#FFAD32",
  sportEducation: "#8743F7",
  taxes: "#4359F7",
  insurances: "#06D6A0",
  home: "#00D5FF",
  transport: "#1C9E76",
  health: "#E41A1B",
  holidays: "#E78AC3",
  otherExpense: "#AAAAAA",

  // Taxes
  irpf: "#C5E493",
  socialSecurity: "#8CC1E2",
  wealthTax: "#DEF0CE",
  corporateTax: "#C5E493",
  largeFortuneSolidarityTax: "#FEDFEA",
  solidarityContributionTax: "#FDC086",
  localCapitalGain: "#9BCACE",
  ibiTax: "#FFACAF",
  fordTax: "#ABADD0",
  ivaTax: "#FFECBA",
  tie: "#764B9D",
  others: "#4359F7",

  // Risk-families
  riskFamilyMoneyMarket: "#C5E493",
  riskFamilyFixedIncome: "#8CC1E2",
  riskFamilyBalanced: "#9BCACE",
  riskFamilyEquity: "#FFACAF",
  riskFamilyAlternative: "#ABADD0",
  riskFamilyHedgeFunds: "#FEDFEA",
  riskFamilyPrivateEquity: "#E8D8ED",
  riskFamilyPrivateDebtRisk: "#DEF0CE",
  riskFamilyInfrestructure: "#FFECBA",
  riskFamilyRealEstate: "#FDBF6F",
  none: "#C0C0C0",

  // Savings-origin
  debtReduction: "#01ECCA",
  saving: "#8743F7",
  revalorization: "#FFAD32",

  // Liquidity
  liquid: "#E5F3FF",
  illiquid: "#5F819F",

  // Actions
  apply: "#4459F7",
  delete: "#F72485",

  // Gauge
  gaugeDarkRed: "#943131",
  gaugeRed: "#D76A66",
  gaugeYellow: "#F4D064",
  gaugeGreen: "#6FC884",
  gaugeDarkGreen: "#5CA56D",

  // Sections
  sectionBackground: "#F7F9FB",
  zebra: "#F0F2F4",

  // Warning
  alarm: "#FF0000",

  // General
  background: "#F9F9F9",

  // Deflactado
  deflacted: "#45AE43",

  // Notifications
  info: "#4F46E5",
  infoSecondary: "#EDEDFC",
  infoBorder: "#CDCCF7",
  warning: "#ECB009",
  warningSecondary: "#FDF7E6",
  warningBorder: "#FAE9BA",
  error: "#DC2626",
  errorSecondary: "#FBE9E9",
  errorBorder: "#F5C2C2",
  success: "#15803D",
  successSecondary: "#E8F2EC",
  successBorder: "#BEDBC9",

  // Patrimonial summary
  patrimonialInvestmentsInverted: "#AF880B",
  patrimonialPortfolioAccountsDeposits: "#4CC9F0",
  patrimonialPortfolioInvestments: "#4AAFF0",
  patrimonialPortfolioRetirement: "#4895EF",

  // Chart
  lineMain: "#4359F7",
  areaMain: "#4359F766",

  // extension docs
  imgColor: "#FEBE00",
  imgBackground: "#FFECBA80",
  xlsColor: "#1B9E77",
  xlsBackground: "#1B9E770D",

  // Education
  school: "#A1D99B",
  university: "#FDBB84",

  // Asset proposal expenses
  localCapitalGainProposal: "#FDCC8A",
  liabilityAmortizationProposal: "#E34A33",
  irpfProposal: "#FC8D59",
  otherExpensesProposal: "#B30000"
};
