import axios from "axios";
import { useWizardStore } from "@/stores/wizardStore";
// Importa Axios

export default defineNuxtPlugin(nuxtApp => {
  const wizardStore = useWizardStore();

  const myAxios = axios.create({});
  const { token } = useAuth();
  let debounceTimeout = null;
  const debounce = func => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => func(), 1000);
  };
  myAxios.interceptors.request.use(async config => {
    if (!config.url.includes("/refresh")) {
      // Si antes de hacer la llamada, el token ha expirado, se pide un nuevo token
      await nuxtApp.$session.checkRefreshToken();
      config.headers.Authorization = token.value || "";
    }
    return config;
  });

  myAxios.interceptors.response.use(
    response => {
      const config = response.config;
      if (
        config &&
        config.url.includes("/wealthabouts/") &&
        config.method !== "get" &&
        !config.url.includes("/allow-data")
      ) {
        debounce(() => wizardStore.fetchMaturityData());
      }
      return response.data;
    },
    error => {
      // Si el error es un 401, se reintenta la llamada. Es un caso raro pero puede pasar que cuando se
      // compruebe si el token es valido antes de hacer la llamada justo despúes caduque y
      // responda con un 401, en ese caso repetimos la llamada por si es el caso
      const originalRequest = error.config;
      // Verificar si el error es un 401 y no hemos intentado refrescar el token aún
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Marcamos que ya intentamos refrescar el token
        // Reintentar la solicitud original
        return myAxios(originalRequest);
      }

      // Si no se puede resolver el error, lo devolvemos
      return Promise.reject(error);
    }
  );
  return {
    provide: {
      axios: myAxios,
    },
  };
});
