import { userFeatureTypes } from "@/assets/logic/types";
import { useWealthaboutStore } from "@/stores/wealthaboutStore";

export default defineNuxtPlugin(() => {
  const wealthaboutStore = useWealthaboutStore();
  return {
    provide: {
      tags: {
        isHolding() {
          return wealthaboutStore.isHolding;
        },
        isSimulation() {
          return wealthaboutStore.isSimulation;
        },
        isManager() {
          return wealthaboutStore.isManager;
        },
        isAdmin() {
          return wealthaboutStore.isAdmin;
        },
        isClient() {
          return wealthaboutStore.isClient;
        },
        isEarlyAdopter() {
          return wealthaboutStore.isEarlyAdopter;
        },
        isCompanyHiddenClients() {
          return wealthaboutStore.userAvailableFeatures.includes("hidden-clients");
        },
        isOpenfinanceLinkEntityAvailable() {
          return wealthaboutStore.isOpenfinanceLinkEntityAvailable;
        },
        isTester() {
          return wealthaboutStore.isTester;
        },
        // Comprobaciones de Base única
        isLiteOnly() {
          return wealthaboutStore.isBaseLite;
        },
        isBasicProjectionOnly() {
          return wealthaboutStore.isBaseBasicProjection;
        },
        // Comprobaciones de Base y superiores
        isLiteOrHigher() {
          return this.isLiteOnly() || this.isBasicProjectionOrHigher();
        },
        isBasicProjectionOrHigher() {
          return this.isBasicProjectionOnly();
        },
        isReadOnly() {
          return wealthaboutStore.userAvailableFeatures.includes(userFeatureTypes.READ_ONLY);
        },
        isInsuranceMock() {
          return wealthaboutStore.userAvailableFeatures.includes("insurance-mock");
        },
      },
    },
  };
});
