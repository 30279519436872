import * as types from "@/assets/logic/types";
import colors from "@/assets/logic/colors";

const member = {
  customization: {
    [types.memberTypes.OWNER]: {
      title: "Usuario",
      color: colors.owner,
      defaultColor: colors.owner,
      icon: "crown",
    },
    [types.memberTypes.PARTNER]: {
      title: "Cónyuge",
      color: colors.partner,
      defaultColor: colors.partner,
      icon: "partner",
    },
    [types.memberTypes.CHILD]: {
      title: "Hijo",
      color: colors.child1,
      defaultColor: colors.child1,
      icon: "face",
    },
    [types.memberTypes.FAMILY]: {
      title: "Unidad familiar",
      color: colors.member,
      defaultColor: colors.member,
      icon: "people_alt",
      route: "members-family",
    },
    MIEMBROS: {
      title: "Miembros",
      color: colors.member,
      defaultColor: colors.member,
      icon: "person",
      route: "members-family",
    },
    [types.memberTypes.DEPENDENT]: {
      title: "Dependiente",
      color: colors.dependant1,
      defaultColor: colors.dependant1,
      icon: "assist_walker",
    },
    [types.memberTypes.HOLDING]: {
      title: "Compañía",
      color: colors.member,
      defaultColor: colors.member,
      icon: "business",
      route: "members-holding",
    },
    CHILD1: { color: colors.child1, defaultColor: colors.child1 },
    CHILD2: { color: colors.child2, defaultColor: colors.child2 },
    CHILD3: { color: colors.child3, defaultColor: colors.child3 },
    CHILD4: { color: colors.child4, defaultColor: colors.child4 },
    CHILD5: { color: colors.child5, defaultColor: colors.child5 },
    CHILD6: { color: colors.child6, defaultColor: colors.child6 },
  },
};

const common = {
  customization: {
    [types.assetTypes.REAL_ESTATE]: {
      title: "Inmuebles",
      color: colors.property,
      icon: "home_work",
      route: "real-estate",
    },
    [types.assetTypes.PRIVATE_SHARES]: {
      title: "Inversiones empresariales",
      color: colors.investment,
      icon: "rocket_launch",
      route: "private-shares",
    },
    [types.assetTypes.PRIVATE_SHARES_HOLDING]: {
      title: "Sociedad patrimonial",
      color: colors.investment,
      icon: "business",
      route: "private-shares",
    },
    [types.assetTypes.FINANCIAL_INSTRUMENT]: {
      title: "Portfolio financiero",
      color: colors.portfolio,
      icon: "account_balance",
      route: "portfolio",
    },
    [types.assetTypes.PRIVATE_DEBT]: {
      title: "Deuda privada",
      color: colors.privateDebt,
      icon: "icon-private-debt",
      route: "private-debts",
    },
    [types.assetTypes.OTHER]: {
      title: "Otros activos",
      color: colors.otherAsset,
      icon: "layers",
      route: "other-assets",
    },
    ...member.customization,
  },
};

const portfolio = {
  customization: {
    [types.portfolioTypes.CURRENT_ACCOUNT]: {
      title: "Cuentas corrientes y ahorro",
    },
    [types.portfolioTypes.SECURITIES_ACCOUNT]: { title: "Cuentas de valores" },
    [types.portfolioTypes.CREDIT_CARD]: { title: "Tarjetas" },
    [types.portfolioTypes.LIABILITY]: { title: "Deudas" },
    [types.familyRiskType.MONEY_MARKET]: {
      title: "Monetario",
      color: colors.riskFamilyMoneyMarket,
      order: 1,
    },
    [types.familyRiskType.FIXED_INCOME]: {
      title: "Renta Fija",
      color: colors.riskFamilyFixedIncome,
      order: 2,
    },
    [types.familyRiskType.BALANCE]: {
      title: "Renta mixta",
      color: colors.riskFamilyBalanced,
      order: 3,
    },
    [types.familyRiskType.EQUITY]: {
      title: "Renta Variable",
      color: colors.riskFamilyEquity,
      order: 4,
    },
    [types.familyRiskType.ALTERNATIVE]: {
      title: "Alternativos",
      color: colors.riskFamilyAlternative,
      order: 5,
    },
    [types.familyRiskType.HEDGE_FUNDS]: {
      title: "Hedge funds",
      color: colors.riskFamilyHedgeFunds,
      order: 6,
    },
    [types.familyRiskType.PRIVATE_EQUITY]: {
      title: "Private equity",
      color: colors.riskFamilyPrivateEquity,
      order: 7,
    },
    [types.familyRiskType.PRIVATE_DEBT]: {
      title: "Private debt",
      color: colors.riskFamilyPrivateDebtRisk,
      order: 8,
    },
    [types.familyRiskType.INFRASTRUCTURES]: {
      title: "Infraestructuras",
      color: colors.riskFamilyInfrestructure,
      order: 9,
    },
    [types.familyRiskType.REAL_ESTATE]: {
      title: "Real estate",
      color: colors.riskFamilyRealEstate,
      order: 10,
    },
    [types.familyRiskType.UNCLASSIFIED]: {
      title: "Sin clasificar",
      color: colors.none,
    },
    [types.financialAssetType.ACCOUNT]: {
      title: "Cuenta corriente",
      color: colors.bankAccount,
    },
    [types.financialAssetType.DEPOSIT]: {
      title: "Depósito",
      color: colors.deposit,
    },
    ...member.customization,
  },
  groupingsRelation: {
    alternative: types.familyRiskType.ALTERNATIVE,
    equity: types.familyRiskType.EQUITY,
    balance: types.familyRiskType.BALANCE,
    fixedIncome: types.familyRiskType.FIXED_INCOME,
    moneyMarket: types.familyRiskType.MONEY_MARKET,
    unclassified: types.familyRiskType.UNCLASSIFIED,
    accounts: types.financialAssetType.ACCOUNT,
    deposits: types.financialAssetType.DEPOSIT,
  },
};

const taxes = {
  customization: {
    IRPF: { title: "IRPF", color: colors.irpf },
    SOCIAL_SECURITY: {
      title: "Seguridad Social",
      color: colors.socialSecurity,
    },
    WEALTH_TAX: { title: "Patrimonio", color: colors.wealthTax },
    LARGE_FORTUNE_SOLIDARITY_TAX: {
      title: "ITSGF",
      color: colors.largeFortuneSolidarityTax,
    },
    LOCAL_CAPITAL_GAIN: {
      title: "Plusvalía Municipal",
      color: colors.localCapitalGain,
    },
    IBI: { title: "IBI", color: colors.ibiTax },
    FORD: { title: "Vado", color: colors.fordTax },
    IVA: { title: "IVA", color: colors.ivaTax },
    CORPORATE_TAX: { title: "Sociedades", color: colors.corporateTax },
    SOLIDARITY_CONTRIBUTION: {
      title: "Cuota de Solidaridad",
      color: colors.solidarityContributionTax,
    },
  },
};

const wizard = {
  customization: {
    [types.wizardMaturityLevel.BEGINNER]: { title: "Principiante" },
    [types.wizardMaturityLevel.BASIC]: { title: "Básico" },
    [types.wizardMaturityLevel.INTERMEDIATE]: { title: "Intermedio" },
    [types.wizardMaturityLevel.ADVANCE]: { title: "Avanzado" },
    [types.wizardMaturityLevel.EXPERT]: { title: "Experto" },
  },
};

const whatHave = {
  customization: {
    [types.assetTypes.REAL_ESTATE]: {
      title: "Inmuebles",
      color: colors.property,
      route: "real-estate",
    },
    [types.assetTypes.PRIVATE_SHARES]: {
      title: "Inversiones empresariales",
      color: colors.investment,
      route: "private-shares",
    },
    [types.assetTypes.PRIVATE_DEBT]: {
      title: "Deuda privada",
      color: colors.privateDebt,
      route: "private-debts",
    },
    [types.assetTypes.OTHER]: {
      title: "Otros activos",
      color: colors.otherAsset,
      route: "other-assets",
    },
    [types.assetTypes.FINANCIAL_INSTRUMENT]: {
      title: "Portfolio financiero",
      color: colors.portfolio,
      route: "portfolio",
    },
    [types.liquidityTypes.LIQUID]: { title: "Líquido", color: colors.liquid },
    [types.liquidityTypes.NOT_LIQUID]: {
      title: "No líquido",
      color: colors.illiquid,
    },
    ...member.customization,
  },
  groupingsRelation: {
    financialPortfolio: types.assetTypes.FINANCIAL_INSTRUMENT,
    mercantile: types.assetTypes.PRIVATE_SHARES,
    others: types.assetTypes.OTHER,
    privateDebt: types.assetTypes.PRIVATE_DEBT,
    properties: types.assetTypes.REAL_ESTATE,
    liquid: types.liquidityTypes.LIQUID,
    notLiquid: types.liquidityTypes.NOT_LIQUID,
  },
};

const whatOwe = {
  customization: {
    [types.liabilityTypes.MORTGAGE]: {
      title: "Hipotecas",
      color: colors.mortgage,
    },
    [types.liabilityTypes.CREDIT]: {
      title: "Créditos al consumo",
      color: colors.credit,
    },
    [types.liabilityTypes.OTHER]: {
      title: "Otros",
      color: colors.otherLiability,
    },
    ...member.customization,
  },
  groupingsRelation: {
    credit: types.liabilityTypes.CREDIT,
    mortgage: types.liabilityTypes.MORTGAGE,
    others: types.liabilityTypes.OTHER,
  },
};

const whatEarn = {
  customization: {
    [types.inventoryTypes.PROPERTIES]: {
      title: "Rentas de inmueble",
      color: colors.incomeProperty,
      route: "assets-distributor-real-estate",
    },
    [types.inventoryTypes.INVESTMENTS]: {
      title: "Rentas de inversiones empresariales",
      color: colors.incomeInvestment,
      route: "assets-distributor-private-shares",
    },
    [types.inventoryTypes.PRIVATE_DEBT]: {
      title: "Rentas de deuda privada",
      color: colors.privateDebt500,
      route: "assets-distributor-private-debts",
    },
    [types.inventoryTypes.OTHER_ASSET]: {
      title: "Rentas de otro activo",
      color: colors.incomeOtherAssets,
      route: "assets-distributor-other-assets",
    },
    [types.inventoryTypes.LABOUR]: {
      title: "Rentas del trabajo",
      color: colors.incomeJob,
      route: "members-family",
    },
    [types.inventoryTypes.RETIREMENT]: {
      title: "Pensión",
      color: colors.incomePension,
      route: "members-family",
    },
    [types.inventoryTypes.DIVIDENDS]: {
      title: "Dividendos",
      color: colors.incomeInvestment,
      route: "members-family",
    },
    [types.inventoryTypes.OTHERS]: {
      title: "Otros ingresos",
      color: colors.incomeOthers,
      route: "members-family",
    },
    [types.inventoryTypes.EXTRAORDINARY]: {
      title: "Ingresos extraordinarios",
      color: colors.incomeExtraordinary,
      route: "members-family",
    },
    ...member.customization,
  },
  groupingsRelation: {
    mercantile: types.inventoryTypes.INVESTMENTS,
    retirement: types.inventoryTypes.RETIREMENT,
    labour: types.inventoryTypes.LABOUR,
    privateDebt: types.inventoryTypes.PRIVATE_DEBT,
    properties: types.inventoryTypes.PROPERTIES,
    otherAssets: types.inventoryTypes.OTHER_ASSET,
    others: types.inventoryTypes.OTHERS,
    extraordinary: types.inventoryTypes.EXTRAORDINARY,
  },
};

const subcategories = {
  home: {
    [types.subCategoryTypes.COMMUNITY]: { color: colors.community },
    [types.subCategoryTypes.HOUSING_RENTAL]: { color: colors.housingRental },
    [types.subCategoryTypes.SUPPLIES]: { color: colors.supplies },
    [types.subCategoryTypes.HOUSEKEEPING]: { color: colors.houseKeeping },
    [types.subCategoryTypes.TELEPHONE_TV_INTERNET]: {
      color: colors.telephoneTvInternet,
    },
    [types.subCategoryTypes.DECORATION_FURNITURE]: {
      color: colors.decorationFurniture,
    },
    [types.subCategoryTypes.HOME]: { color: colors.home },
    [types.subCategoryTypes.FORD]: { color: colors.ford },
    [types.subCategoryTypes.IBI]: { color: colors.ibi },
    [types.subCategoryTypes.DOMESTIC_SERVICE]: {
      color: colors.domesticService,
    },
  },
  vehicle: {
    [types.subCategoryTypes.VEHICLE_MAINTENANCE]: {
      color: colors.vehicleMaintenance,
    },
    [types.subCategoryTypes.VEHICLE_TRANSPORT]: {
      color: colors.vehicleTransport,
    },
    [types.subCategoryTypes.PUBLIC_TRANSPORT]: {
      color: colors.publicTransport,
    },
    [types.subCategoryTypes.TAXIS]: { color: colors.taxis },
    [types.subCategoryTypes.VEHICLE_LOAN]: { color: colors.vehicleLoan },
    [types.subCategoryTypes.TOLLS]: { color: colors.tolls },
    [types.subCategoryTypes.PARKING_GARAGE]: { color: colors.parkingGarage },
    [types.subCategoryTypes.FUEL]: { color: colors.fuel },
  },
  purchase: {
    [types.subCategoryTypes.FINANCIAL_CARDS]: { color: colors.financialCards },
    [types.subCategoryTypes.SHOPPING]: { color: colors.shopping },
    [types.subCategoryTypes.ELECTRONICS]: { color: colors.electronics },
    [types.subCategoryTypes.PETS]: { color: colors.pets },
    [types.subCategoryTypes.CLOTHES_COMPLEMENTS]: {
      color: colors.clothesComplements,
    },
    [types.subCategoryTypes.GIFTS_TOYS]: { color: colors.giftsToys },
    [types.subCategoryTypes.BEAUTY_PERFUMERY]: {
      color: colors.beautyPerfumery,
    },
  },
  leisure: {
    [types.subCategoryTypes.LEISURE_TRAVEL]: { color: colors.leisureTravel },
    [types.subCategoryTypes.CINEMA_THEATER_SHOWS]: {
      color: colors.cinemaTheater_shows,
    },
    [types.subCategoryTypes.HOTEL_LODGING]: { color: colors.hotelLodging },
    [types.subCategoryTypes.LOTTERIES_BETS]: { color: colors.lotteriesBets },
    [types.subCategoryTypes.CAFES_RESTAURANTS]: {
      color: colors.cafesRestaurants,
    },
    [types.subCategoryTypes.BOOKS_MUSIC_GAMES]: {
      color: colors.booksMusicGames,
    },
    [types.subCategoryTypes.TRAIN_PLANE_TRANSPORT]: {
      color: colors.trainPlaneTransport,
    },
  },
  taxes: {
    [types.subCategoryTypes.IRPF]: { color: colors.irpf },
    [types.subCategoryTypes.SOCIAL_SECURITY]: { color: colors.socialSecurity },
    [types.subCategoryTypes.WEALTH_TAX]: { color: colors.wealthTax },
    [types.subCategoryTypes.LARGE_FORTUNE_SOLIDARITY_TAX]: {
      color: colors.largeFortuneSolidarityTax,
    },
    [types.subCategoryTypes.SOLIDARITY_CONTRIBUTION]: {
      color: colors.solidarityContributionTax,
    },
    [types.subCategoryTypes.LOCAL_CAPITAL_GAIN]: {
      color: colors.localCapitalGain,
    },
    [types.subCategoryTypes.IBI]: { color: colors.ibiTax },
    [types.subCategoryTypes.OTHERS]: { color: colors.otherTaxes },
  },
  insurances: {
    [types.subCategoryTypes.HOME_INSURANCE]: { color: colors.homeInsurance },
    [types.subCategoryTypes.HEALTH_INSURANCE]: {
      color: colors.healthInsurance,
    },
    [types.subCategoryTypes.LIFE_INSURANCE]: { color: colors.lifeInsurance },
    [types.subCategoryTypes.VEHICLE_INSURANCE]: {
      color: colors.vehicleInsurance,
    },
    [types.subCategoryTypes.OTHER_INSURANCE]: { color: colors.otherInsurance },
  },
  others: {
    [types.subCategoryTypes.FEES_INTEREST]: { color: colors.feesInterest },
    [types.subCategoryTypes.OTHER_LOANS_GUARANTEES]: {
      color: colors.otherLoansGuarantees,
    },
    [types.subCategoryTypes.TAX_PAYMENTS]: { color: colors.taxPayments },
    [types.subCategoryTypes.TRANSFERS]: { color: colors.transfers },
    [types.subCategoryTypes.SUBSCRIPTIONS]: { color: colors.subscriptions },
    [types.subCategoryTypes.ONG]: { color: colors.ong },
    [types.subCategoryTypes.OTHER_EXPENSES]: { color: colors.otherExpenses },
    [types.subCategoryTypes.CASHIERS]: { color: colors.cashiers },
    [types.subCategoryTypes.CHECKS]: { color: colors.checks },
    [types.subCategoryTypes.DIVIDENDS]: { color: colors.dividends },
  },
  food: {
    [types.subCategoryTypes.SUPERMARKETS_FOOD]: {
      color: colors.supermarketsFood,
    },
    [types.subCategoryTypes.FOOD]: { color: colors.food },
  },
  education: {
    [types.subCategoryTypes.EDUCATION_HEALTH_SPORT]: {
      color: colors.educationHealthSport,
    },
    [types.subCategoryTypes.OPTICS]: { color: colors.optics },
    [types.subCategoryTypes.PHARMACY]: { color: colors.pharmacy },
    [types.subCategoryTypes.EDUCATION]: { color: colors.education },
    [types.subCategoryTypes.SPORT_GYM]: { color: colors.sportGym },
    [types.subCategoryTypes.DENTIST_DOCTOR]: { color: colors.dentistDoctor },
    [types.subCategoryTypes.EXTRACURRICULAR_ACTIVITIES]: {
      color: colors.extracurricularActivities,
    },
  },
};

const whatSpend = {
  customization: {
    [types.categoryTypes.HOME]: {
      title: "Hogar",
      color: colors.home,
      icon: "home",
      subcategories: subcategories.home,
    },
    [types.categoryTypes.VEHICLE]: {
      title: "Vehículo y transporte",
      color: colors.transport,
      icon: "car",
      subcategories: subcategories.vehicle,
    },
    [types.categoryTypes.PURCHASE]: {
      title: "Compras",
      color: colors.shopping,
      icon: "shopping_cart",
      subcategories: subcategories.purchase,
    },
    [types.categoryTypes.LEISURE]: {
      title: "Ocio y viajes",
      color: colors.freeTime,
      icon: "explore",
      subcategories: subcategories.leisure,
    },
    [types.categoryTypes.OTHERS]: {
      title: "Otros gastos",
      color: colors.otherExpense,
      icon: "fiber_smart_record",
      subcategories: subcategories.others,
    },
    [types.categoryTypes.FOOD]: {
      title: "Alimentación",
      color: colors.food,
      icon: "restaurant",
      subcategories: subcategories.food,
    },
    [types.categoryTypes.EDUCATION]: {
      title: "Educación, salud y deporte",
      color: colors.sportEducation,
      icon: "school",
      subcategories: subcategories.education,
    },
    [types.categoryTypes.TAXES]: {
      title: "Impuestos",
      color: colors.taxes,
      icon: "account_balance",
      subcategories: subcategories.taxes,
    },
    [types.categoryTypes.INSURANCES]: {
      title: "Seguros",
      color: colors.insurances,
      icon: "security",
      subcategories: subcategories.insurances,
    },
    [types.assetTypes.REAL_ESTATE]: {
      title: "Inmuebles",
      color: colors.property,
      icon: "home_work",
      route: "assets-distributor-real-estate",
    },
    [types.assetTypes.PRIVATE_SHARES]: {
      title: "Inversiones empresariales",
      color: colors.investment,
      icon: "rocket_launch",
      route: "assets-distributor-private-shares",
    },
    [types.assetTypes.OTHER]: {
      title: "Otros activos",
      color: colors.otherAsset,
      icon: "layers",
      route: "assets-distributor-other-assets",
    },
    // Añadimos un tipo especial otros (temporal)
    OtherResources: {
      title: "Otros recursos",
      color: colors.gray100,
      icon: "icon-other-assets",
    },
    ...member.customization,
  },
  groupingsRelation: {
    housing: types.categoryTypes.HOME,
    transportation: types.categoryTypes.VEHICLE,
    shopping: types.categoryTypes.PURCHASE,
    leisureTime: types.categoryTypes.LEISURE,
    otherExpenses: types.categoryTypes.OTHERS,
    food: types.categoryTypes.FOOD,
    educationHealthSports: types.categoryTypes.EDUCATION,
    taxes: types.categoryTypes.TAXES,
    insurances: types.categoryTypes.INSURANCES,
  },
};

const whatSave = {
  customization: {
    [types.assetTypes.REAL_ESTATE]: {
      title: "Inmuebles",
      color: colors.corporateLightBlue,
      route: "real-estate",
    },
    [types.assetTypes.PRIVATE_SHARES]: {
      title: "Inversiones empresariales",
      color: colors.orange500,
      route: "private-shares",
    },
    [types.assetTypes.PRIVATE_DEBT]: {
      title: "Deuda privada",
      color: colors.privateDebt500,
      route: "private-debts",
    },
    [types.assetTypes.OTHER]: {
      title: "Otros activos",
      color: colors.otherAssets500,
      route: "other-assets",
    },
    [types.assetTypes.FINANCIAL_INSTRUMENT]: {
      title: "Portfolio financiero",
      color: colors.yellow500,
      route: "portfolio",
    },
    [types.analysisSavingsTypes.CURRENT]: {
      title: "Ahorro corriente",
      color: colors.saving,
    },
    [types.analysisSavingsTypes.REDEEMDEBT]: {
      title: "Reducción de deuda",
      color: colors.positiveChart5,
    },
    [types.analysisSavingsTypes.REVALORIZATION]: {
      title: "Revalorización de activos",
      color: colors.positiveChart7,
    },
    [types.liquidityTypes.LIQUID]: { title: "Líquido", color: colors.liquid },
    [types.liquidityTypes.NOT_LIQUID]: {
      title: "No líquido",
      color: colors.notLiquid,
    },
    ...member.customization,
  },
  groupingsRelation: {
    currentSavings: types.analysisSavingsTypes.CURRENT,
    redeemDebt: types.analysisSavingsTypes.REDEEMDEBT,
    revalorization: types.analysisSavingsTypes.REVALORIZATION,
    liquid: types.liquidityTypes.LIQUID,
    notLiquid: types.liquidityTypes.NOT_LIQUID,
  },
};
const flows = {
  customization: {
    [types.flowsTypes.SAVINGS]: {
      text: "Ahorro corriente",
      icon: { positive: "file_download", negative: "file_upload" },
    },
    [types.flowsTypes.SALES]: {
      text: "Ventas de activos",
      icon: "file_download",
    },
    [types.flowsTypes.PURCHASES]: {
      text: "Compras de activos",
      icon: "file_upload",
    },
    [types.flowsTypes.LOANS]: { text: "Préstamos", icon: "file_download" },
    [types.flowsTypes.PRIVATE_DEBT]: {
      text: "Deudas privadas",
      icon: "file_download",
    },
    [types.flowsTypes.INHERITANCES]: {
      text: "Herencias",
      icon: "file_download",
    },
    [types.flowsTypes.EXTRAORDINARY_INCOME]: {
      text: "Ingresos extraordinarios",
      icon: "file_download",
    },
    [types.flowsTypes.EXTRAORDINARY_EXPENSES]: {
      text: "Gastos extraordinarios",
      icon: "file_upload",
    },
    [types.flowsTypes.PORTFOLIO_REVALORIZATION]: {
      text: "Revalorizacion del Portfolio",
      icon: { positive: "trend_up", negative: "trend_down" },
    },
  },
};

const incomes = {
  customization: {
    [types.incomeTypes.LABOUR]: {
      title: "Rentas del trabajo",
      color: colors.incomeJob,
    },
    [types.incomeTypes.RETIREMENT]: {
      title: "Pensión",
      color: colors.incomePension,
    },
    [types.incomeTypes.DIVIDENDS]: {
      title: "Dividendos",
      color: colors.dividends,
    },
    [types.incomeTypes.OTHERS]: {
      title: "Otros ingresos",
      color: colors.otherIncome,
    },
  },
  groupingsRelation: {
    labour: types.incomeTypes.LABOUR,
    retirement: types.incomeTypes.RETIREMENT,
    dividends: types.incomeTypes.DIVIDENDS,
    others: types.incomeTypes.OTHERS,
  },
};

const expenses = {
  customization: {
    [types.expensesType.DIVIDENDS]: {
      title: "Dividendos",
      color: colors.incomeJob,
    },
  },
  groupingsRelation: {
    labour: types.expensesType.DIVIDENDS,
  },
};

const education = {
  customization: {
    [types.educationTypes.SCHOOL]: { title: "Colegio", color: colors.school },
    [types.educationTypes.UNIVERSITY]: {
      title: "Universidad",
      color: colors.university,
    },
  },
  groupingsRelation: {
    school: types.educationTypes.SCHOOL,
    university: types.educationTypes.UNIVERSITY,
  },
};

const irpfSteps = {
  customization: {
    [types.irpfSteps.RCM]: {
      blankLeftColumns: 1,
      group: 0,
      title: "rcm",
      subtitle: "rcm-description",
    },
    [types.irpfSteps.GPP]: {
      group: 0,
      title: "gpp",
      subtitle: "gpp-description",
    },
    [types.irpfSteps.BIG]: {
      group: 1,
      title: "big",
      subtitle: "big-description",
    },
    [types.irpfSteps.BIA]: {
      group: 1,
      title: "bia",
      subtitle: "bia-description",
    },
    [types.irpfSteps.BLG]: {
      group: 2,
      title: "blg",
      subtitle: "blg-description",
    },
    [types.irpfSteps.BLA]: {
      group: 2,
      title: "bla",
      subtitle: "bla-description",
    },
    [types.irpfSteps.MPF]: {
      group: 3,
      title: "mpf",
      subtitle: "mpf-description",
    },
    [types.irpfSteps.CGE]: {
      group: 4,
      title: "cge",
      subtitle: "cge-description",
    },
    [types.irpfSteps.CGA]: {
      group: 4,
      title: "cga",
      subtitle: "cga-description",
    },
    [types.irpfSteps.CAE]: {
      group: 4,
      title: "cae",
      subtitle: "cae-description",
    },
    [types.irpfSteps.CAA]: {
      group: 4,
      title: "caa",
      subtitle: "caa-description",
    },
    [types.irpfSteps.CIE]: {
      group: 5,
      title: "cie",
      subtitle: "cie-description",
    },
    [types.irpfSteps.CIA]: {
      group: 5,
      title: "cia",
      subtitle: "cia-description",
    },
    [types.irpfSteps.CLE]: {
      group: 6,
      title: "cle",
      subtitle: "cle-description",
    },
    [types.irpfSteps.CLA]: {
      group: 6,
      title: "cla",
      subtitle: "cla-description",
    },
    [types.irpfSteps.CL]: { group: 7, title: "cl", subtitle: "cl-description" },
    [types.irpfSteps.CD]: { group: 8, title: "cd", subtitle: "cd-description" },
  },
};

const isSteps = {
  customization: {
    [types.isSteps.CPG]: {
      group: 0,
      title: "cpg",
      subtitle: "cpg-description",
    },
    [types.isSteps.GPP]: {
      group: 0,
      title: "gpp",
      subtitle: "gpp-description",
    },
    [types.isSteps.BI]: {
      group: 1,
      title: "bi",
      subtitle: "bi-description",
    },
    [types.isSteps.CI]: {
      group: 2,
      title: "ci",
      subtitle: "ci-description",
    },
    [types.isSteps.CL]: {
      group: 3,
      title: "cl",
      subtitle: "cl-description",
    },
    [types.isSteps.CD]: {
      group: 4,
      title: "cd",
      subtitle: "cd-description",
    },
  },
};

const ipSteps = {
  customization: {
    [types.ipSteps.BI]: {
      group: 0,
      title: "bi",
      subtitle: "bi-description",
    },
    [types.ipSteps.BL]: {
      group: 1,
      title: "bl",
      subtitle: "bl-description",
    },
    [types.ipSteps.CIR]: {
      group: 2,
      title: "cir",
      subtitle: "cir-description",
    },
    [types.ipSteps.CAI]: {
      group: 3,
      title: "cai",
      subtitle: "cai-description",
    },
  },
};

const itsgfSteps = {
  customization: {
    [types.itsgfSteps.BI]: {
      group: 0,
      title: "bi",
      subtitle: "bi-description",
    },
    [types.itsgfSteps.BL]: {
      group: 1,
      title: "bl",
      subtitle: "bl-description",
    },
    [types.itsgfSteps.CIR]: {
      group: 2,
      title: "cir",
      subtitle: "cir-description",
    },
    [types.itsgfSteps.CAI]: {
      group: 3,
      title: "cai",
      subtitle: "cai-description",
    },
  },
};

const taxesSteps = {
  customization: {
    [types.subCategoryTypes.IRPF]: {
      customSteps: irpfSteps,
      type: "irpf",
    },
    [types.subCategoryTypes.CORPORATE_TAX]: {
      customSteps: isSteps,
      type: "is",
    },
    [types.subCategoryTypes.WEALTH_TAX]: {
      customSteps: ipSteps,
      type: "ip",
    },
    [types.subCategoryTypes.LARGE_FORTUNE_SOLIDARITY_TAX]: {
      customSteps: itsgfSteps,
      type: "itsgf",
    },
  },
};

const custom = {
  whatHave,
  whatOwe,
  whatEarn,
  whatSpend,
  whatSave,
  portfolio,
  member,
  common,
  taxes,
  wizard,
  flows,
  incomes,
  education,
  expenses,
};

const customizeItems = function (groupingsItem, customItem) {
  return Object.entries(groupingsItem)
    .map(x => ({
      id: x[0],
      value: x[1],
      custom:
        customItem.customization[x[0]] ||
        customItem.customization[customItem.groupingsRelation[x[0]]],
    }))
    .filter(x => x.value)
    .sort((a, b) => b.value - a.value)
    .sort((a, b) => {
      if (typeof a.custom !== "object" || typeof b.custom !== "object")
        return 0;
      if ("order" in a.custom && "order" in b.custom) {
        return a.custom.order - b.custom.order;
      } else if ("order" in a.custom) {
        return -1; // Si a tiene order y b no, a va antes
      } else if ("order" in b.custom) {
        return 1; // Si b tiene order y a no, b va antes
      } else {
        return 0; // Si ninguno tiene order, no hay diferencia
      }
    })
    .map(x => ({
      asset: x.custom?.title || x.id,
      amount: x.value,
      color: x.custom?.color || "",
      fillColor: x.custom?.fillColor || x.custom?.color + "A5", // Utilizamos el color con un 65% de transparencia, si no hay fillColor
    }));
};

const customizeMembers = function (groupingsItem, members, customItem) {
  let childCounter = 1;
  return Object.entries(groupingsItem)
    .map(x => ({
      id: x[0],
      value: x[1],
      alias: members.find(m => m.id === x[0])?.alias,
      type: members.find(m => m.id === x[0])?.relationship,
    }))
    .filter(x => x.value)
    .sort((a, b) => b.value - a.value)
    .map(x => {
      const type = x.type === "CHILD" ? `${x.type}${childCounter++}` : x.type;
      return {
        asset: x.alias,
        amount: x.value,
        color: customItem.customization[type]
          ? customItem.customization[type].color
          : "",
      };
    });
};

const pieChartSeries = function (items, total) {
  const data = items.map(x => ({
    type: x?.asset,
    name: x.alias,
    y: x.amount,
    color: x.color,
  }));
  return [{ data }];
};

const pieChartNewSeries = function (items, total) {
  const data = items.map(x => ({
    type: x?.asset,
    name: x?.asset,
    y: x.amount,
    color: x.color,
  }));
  return [{ data }];
};

const stackedColumnChartSeries = function (data, customItem) {
  const aliases = Object.entries(data)
    .filter(x => x[1].value)
    .map(x => Object.keys(x[1].value.types))
    .flat();
  return [...new Set(aliases)].map(x => {
    const previous = data.previous?.value?.types[x]
      ? data.previous.value.types[x]
      : 0;
    const current = data.current?.value?.types[x]
      ? data.current.value.types[x]
      : 0;
    const next = data.next?.value?.types[x] ? data.next.value.types[x] : 0;
    return {
      name: customItem.groupingsRelation[x]
        ? customItem.customization[customItem.groupingsRelation[x]].title
        : x,
      data: [previous, current, next],
      color: customItem.groupingsRelation[x]
        ? customItem.customization[customItem.groupingsRelation[x]].color
        : "",
    };
  });
};

const stackedColumnChartNewSeries = function (items) {
  const data = items.map(x => ({
    name: x?.asset,
    data: [x.amount],
    color: x.color,
  }));
  return data;
};

const stackedChartSeries = function (groupingsItem, customItem) {
  const customized = customizeItems(groupingsItem, customItem);
  return customized.map(x => ({
    name: x.asset,
    data: Object.entries(x.amount).map(x => x[1]),
    color: x.color,
    fillColor: x.fillColor,
    years: Object.entries(x.amount).map(x => x[0]),
  }));
};

const gaugeChartSeries = function (data) {
  return [
    {
      name: "",
      data: [
        {
          color: colors.corporateBlue,
          radius: "100%",
          innerRadius: "80%",
          y: data,
        },
      ],
    },
  ];
};

const ratiosChart = function (ratio) {
  const range = ratio.range.find(range => ratio.value <= range.value);
  const rangeName = range
    ? range.name
    : ratio.range[ratio.range.length - 1].name;
  const ratioCustom = analysisRatiosInfo[ratio.ratioName];
  const ratioRangeCustom = ratiosRangesInfo(ratio.ratioName, rangeName);
  const ratioData = {
    title: ratioCustom.title,
    tooltip: ratioCustom.info,
    value: ratio.value,
    valueType: ratioCustom.valueType,
    text: ratioRangeCustom.text,
    description: ratioRangeCustom.description,
    ranges: ratio.range.map((range, idx) => {
      return {
        range: range.value,
        color: ratioCustom.rangeColors[idx],
      };
    }),
    isPositive: ratioRangeCustom.isPositive,
    color: ratioRangeCustom.color,
  };
  return ratioData;
};

const customizeTaxDetails = function (data) {
  const taxSteps = taxesSteps.customization[data.type];
  if (!taxSteps) {
    return {
      type: null,
      levels: [],
    };
  }
  const formattedData = {
    type: taxSteps.type,
    levels: [],
  };

  Object.entries(taxSteps.customSteps.customization).forEach(
    ([key, customization]) => {
      const item = data.nodes[key];
      if (item) {
        const breakdown = item.breakdown.map(breakdown => {
          return Object.entries(breakdown).map(([key, value]) => {
            return {
              title:
                "tax-details." +
                taxSteps.type +
                "." +
                key.toLowerCase() +
                "-description",
              value,
            };
          });
        });

        if (formattedData.levels.length === customization.group)
          formattedData.levels.push([]);
        if (customization.blankLeftColumns) {
          for (let i = 0; i < customization.blankLeftColumns; i++) {
            formattedData.levels[customization.group].push({
              blank: true,
              connections: [],
            });
          }
        }
        formattedData.levels[customization.group].push({
          key,
          value: item.value,
          connections: item.connections,
          breakdown,
          title: taxSteps.customSteps.customization[key].title,
          subtitle: taxSteps.customSteps.customization[key].subtitle,
        });
      }
    }
  );

  return formattedData;
};

const fieldsWizardRequiredActions = {
  customization: {
    [types.wizardRequiredActions.WORKINGLIFE]: "Vida laboral",
    [types.wizardRequiredActions.LOANS]: "Crédito personal",
    [types.wizardRequiredActions.INCOMES]: "Rentas del trabajo",
    [types.wizardRequiredActions.PURCHASEPRICE]: "Precio de adquisición",
    [types.wizardRequiredActions.MORTGAGE]: "Hipoteca",
    [types.wizardRequiredActions.CADASTRALREFERENCE]: "Referencia catastral",
    [types.wizardRequiredActions.CADASTRALVALUE]: "Valor catastral",
    [types.wizardRequiredActions.PROPERTYVALUE]: "Valor del inmueble",
    [types.wizardRequiredActions.HABITUALLIVING]: "Vivienda habitual",
    [types.wizardRequiredActions.SHARES]: "Empresa",
    [types.wizardRequiredActions.HOLDINGWEALTHABOUTID]:
      "Sociedad Patrimonial no visitada",
    [types.wizardRequiredActions.ENTITIES]: "Entidades bancarias",
    [types.wizardRequiredActions.OTHERASSETS]: "Otro activo",
    [types.wizardRequiredActions.DOCIRPF]: "IRPF pendiente de subir",
    [types.wizardRequiredActions.DOCCONTRIBUTIONBASES]:
      "Bases de Contribución pendiente de subir",
    [types.wizardRequiredActions.DOCWORKINGLIFE]:
      "Vida Laboral pendiente de subir",
    [types.wizardRequiredActions.DOCBI]: "Bienes Inmuebles pendiente de subir",
  },
};

const buyAssets = {
  [types.assetTypes.REAL_ESTATE]: {
    title: "Nuevo inmueble",
    alias: "Alias del inmueble",
    description:
      "Si sabes que vas a adquirir un nuevo inmueble, puedes indicarlo aquí.",
  },
  [types.assetTypes.PRIVATE_SHARES]: {
    title: "Nueva inversión empresarial",
    alias: "Alias de la inversión empresarial",
    description:
      "Si sabes que vas a adquirir una nueva participación, puedes indicarlo aquí.",
  },
  [types.assetTypes.OTHER]: {
    title: "Nuevo activo",
    alias: "Alias del activo",
    description:
      "Si sabes que vas a adquirir un nuevo activo, puedes indicarlo aquí.",
  },
};
const newAssets = {
  [types.assetTypes.REAL_ESTATE]: {
    alias: "Añadir inmueble",
  },
  [types.assetTypes.PRIVATE_SHARES]: {
    alias: "Añadir participación",
  },
  [types.assetTypes.OTHER]: {
    alias: "Añadir activo",
  },
};

const analysisRatiosInfo = {
  [types.analysisRatiosTypes.INDEBTEDNESS]: {
    title: "Nivel de endeudamiento",
    info: "El nivel de endeudamiento mide el porcentaje de los ingresos destinados a pagar las cuotas de las deudas actuales. Para reducir el nivel de endeudamiento pueden aumentarse los ingresos o amortizar las deudas actuales.",
    rangeColors: [colors.gaugeGreen, colors.gaugeYellow, colors.gaugeRed],
    valueType: "percentage",
  },
  [types.analysisRatiosTypes.SOLVENCY]: {
    title: "Nivel de solvencia",
    info: "El nivel de solvencia indica el número de veces que el activo es superior al pasivo. Aumentar los activos y/o reducir las deudas son dos formas de mejorar este ratio.",
    rangeColors: [colors.gaugeRed, colors.gaugeYellow, colors.gaugeGreen],
    valueType: "numeric",
  },
  [types.analysisRatiosTypes.EMERGENCY_FUND]: {
    title: "Nivel del fondo de emergencia",
    info: "El nivel del fondo de emergencia indica el número de meses que se podrían afrontar si se perdieran todos los ingresos. Aumentar los activos líquidos y/o reducir los gastos mejorarán este estadístico.",
    rangeColors: [colors.gaugeRed, colors.gaugeYellow, colors.gaugeGreen],
    valueType: "numeric",
  },
  [types.analysisRatiosTypes.SAVINGS]: {
    title: "Ratio de ahorro",
    info: "El ratio de ahorro mide el porcentaje de dinero ahorrado (ahorro corriente y reducción de deuda) con respecto a los ingresos. Es importante aumentar la capacidad de ahorro reduciendo los gastos y/o aumentando los ingresos.",
    rangeColors: [colors.gaugeRed, colors.gaugeYellow, colors.gaugeGreen],
    valueType: "percentage",
  },
  [types.analysisRatiosTypes.FINANCIAL_INDEPENDENCE]: {
    title: "Nivel de independencia financiera",
    info: "El nivel de independencia financiera mide la posibilidad de vivir de las rentas patrimoniales. Incrementar los ingresos procedentes de alquiler de inmuebles y dividendos y/o reducir los gastos puede mejorar este ratio.",
    rangeColors: [colors.gaugeRed, colors.gaugeGreen],
    valueType: "percentage",
  },
  [types.analysisRatiosTypes.FINANCIAL_STRENGTH]: {
    title: "Nivel de fortaleza financiera",
    info: "El nivel de independencia financiera mide la posibilidad de vivir de las rentas patrimoniales. Incrementar los ingresos procedentes de alquiler de inmuebles y dividendos y/o reducir los gastos puede mejorar este ratio.",
    rangeColors: [
      colors.gaugeDarkRed,
      colors.gaugeRed,
      colors.gaugeYellow,
      colors.gaugeGreen,
      colors.gaugeGreen,
    ],
    valueType: "numeric",
  },
  [types.goalRatiosTypes.AVAILABLECAPITAL]: {
    title: "Sobre el patrimonio disponible",
    rangeColors: [colors.gaugeGreen, colors.gaugeYellow, colors.gaugeDarkRed],
    valueType: "percentage",
  },
  [types.goalRatiosTypes.SAVINGSCAPACITY]: {
    title: "Sobre la capacidad de ahorro",
    rangeColors: [colors.gaugeGreen, colors.gaugeYellow, colors.gaugeDarkRed],
    valueType: "percentage",
  },
};

const ratiosRangeMatrix = {
  [types.analysisRatiosTypes.INDEBTEDNESS]: {
    [types.analysisRatiosRanges.LOW_LEVEL]: {
      text: "Nivel bajo",
      isPositive: true,
      description:
        "Tu nivel de endeudamiento es bajo, lo que indica que el impacto que tienen las cuotas de tus deudas en los ingresos es de solo un <value>. Es importante mantener este nivel de endeudamiento para no verse ahogado por las deudas.",
    },
    [types.analysisRatiosRanges.MEDIUM_LEVEL]: {
      text: "Nivel medio",
      isPositive: false,
      description:
        "Aunque tu nivel de endeudamiento no es malo, sería recomendable reducir el impacto que tienen las cuotas de las deudas en los ingresos puesto que supone un <value> de los mismos. Reducir el nivel de endeudamiento es vital para no verse ahogado por las deudas y poder dedicar los ingresos a otras actividades.",
    },
    [types.analysisRatiosRanges.HIGH_LEVEL]: {
      text: "Nivel alto",
      isPositive: false,
      description:
        "Tu nivel de endeudamiento es preocupante y sería recomendable reducirlo cuanto antes. El impacto que tienen las cuotas de las deudas en los ingresos es de un <value>. Mantener esta situación en el tiempo puede suponer un riesgo financiero importante.",
    },
  },
  [types.analysisRatiosTypes.SOLVENCY]: {
    [types.analysisRatiosRanges.LOW_SOLVENCY]: {
      text: "Solvencia baja",
      isPositive: false,
      description:
        "Tienes una solvencia baja. Tu activo es <value> veces superior a tu pasivo.\n\nEs importante mejorar este ratio ya que podrías tener problemas para amortizar las deudas pendientes en caso de que necesites vender los activos de tu patrimonio debido a una situación inesperada.",
    },
    [types.analysisRatiosRanges.MEDIUM_SOLVENCY]: {
      text: "Solvencia media",
      isPositive: false,
      description:
        "Tienes una solvencia media. Tu activo es sólo <value> veces superior a tu pasivo.\n\nPara mejorar la solvencia debes reducir el capital pendiente de tus deudas o aumentar el valor de tus activos.",
    },
    [types.analysisRatiosRanges.HIGH_SOLVENCY]: {
      text: "Solvencia alta",
      isPositive: true,
      description:
        "Tienes una solvencia alta. Tu activo es más de <value> veces superior a tu pasivo.\n\nPuedes mejorar aún más la solvencia aumentando el valor de tus activos o reduciendo las deudas.",
    },
  },
  [types.analysisRatiosTypes.EMERGENCY_FUND]: {
    [types.analysisRatiosRanges.LOW_LEVEL]: {
      text: "Nivel bajo",
      isPositive: false,
      description:
        "Tu fondo de emergencia es bajo y puede suponer un problema en caso de pérdida completa de ingresos. Es importante aumentar los activos líquidos de los que dispones ya que sólo podrías afrontar <value> meses en caso de pérdida completa de ingresos.",
    },
    [types.analysisRatiosRanges.MEDIUM_LEVEL]: {
      text: "Nivel medio",
      isPositive: false,
      description:
        "Aunque tu fondo de emergencia no es malo, deberías aumentar los activos líquidos de los que dispones ya que, en caso de pérdida completa de ingresos, sólo podrás afrontar <value> meses.",
    },
    [types.analysisRatiosRanges.HIGH_LEVEL]: {
      text: "Nivel alto",
      isPositive: true,
      description:
        "Tienes un buen fondo de emergencia. Tus activos líquidos te permitirían vivir <value> meses en caso de la pérdida completa de los ingresos.",
    },
  },
  [types.analysisRatiosTypes.SAVINGS]: {
    [types.analysisRatiosRanges.LOW_SAVINGS]: {
      text: "Ahorro bajo",
      isPositive: false,
      description:
        "Tu nivel de ahorro es bajo y supone un problema en tu situación financiera actual y futura.\n\nEl nivel de ahorro actual no te permite incrementar tu fondo de emergencia o invertir en activos financieros que te aporten rentabilidad en el medio-largo plazo. Sería recomendable reducir considerablemente los gastos estructurales no esenciales.",
    },
    [types.analysisRatiosRanges.MEDIUM_SAVINGS]: {
      text: "Ahorro medio",
      isPositive: false,
      description:
        "Aunque tu nivel de ahorro no es malo, sería recomendable aumentarlo reduciendo ciertos gastos estructurales no esenciales.\n\nActualmente tu capacidad de ahorro supone un <value> de tus ingresos y aunque te permite seguir incrementando poco a poco tu fondo de emergencia, necesitarás de varios años para aumentarlo considerablemente o tener mayor patrimonio líquido invertible.",
    },
    [types.analysisRatiosRanges.HIGH_SAVINGS]: {
      text: "Ahorro alto",
      isPositive: true,
      description:
        "Tu nivel de ahorro es bastante bueno. Actualmente estás ahorrando el <value> de tus ingresos.\n\nEsta capacidad de ahorro te permite seguir incrementando tu fondo de emergencia e invertir parte del ahorro para generar mayores beneficios en el medio-largo plazo.",
    },
  },
  [types.analysisRatiosTypes.FINANCIAL_INDEPENDENCE]: {
    [types.analysisRatiosRanges.DEPENDENCE]: {
      text: "Independiente",
      isPositive: false,
      description:
        "Dependes en gran medida de tu trabajo.\n\nPara alcanzar el 100% de independencia y poder vivir sin trabajar deberías recibir más rentas del patrimonio para así cubrir todos tus gastos actuales.",
    },
    [types.analysisRatiosRanges.INDEPENDENCE]: {
      text: "Independiente",
      isPositive: true,
      description:
        "¡Lo has conseguido! Eres completamente independiente.\n\nCon tu situación actual no sería necesario trabajar para poder cubrir todos los gastos estructurales incurridos por tu unidad familiar. Actualmente las rentas procedentes del patrimonio son superiores a los gastos e incluso te generan un ahorro",
    },
  },
  [types.analysisRatiosTypes.FINANCIAL_STRENGTH]: {
    [types.analysisRatiosRanges.DEFICIENT_STRENGTH]: {
      text: "¡Deficiente!",
      isPositive: false,
      description:
        "Tu situación financiera está en peligro.\n\nTu futuro financiero tanto en el corto como en el largo plazo pende de un hilo. Cualquier evento imprevisto que pudiera ocurrir te pondrá contra las cuerdas y en una situación muy complicada.\n\nEs importante actuar lo más pronto posible para empezar a revertir la situación.",
      color: colors.gaugeDarkRed,
    },
    [types.analysisRatiosRanges.BAD_STRENGTH]: {
      text: "Malo",
      isPositive: false,
      description:
        "Tu situación financiera está en peligro.\n\nTu futuro financiero tanto en el corto como en el largo plazo está comprometido. Cualquier evento imprevisto que pudiera ocurrir puede empeorar tu salud financiera y ponerte en una situación complicada.\n\nEs importante actuar para revertir la situación y mejorar tu situación financiera hasta unos parámetros que te permitan tener una mayor tranquilidad. ",
      color: colors.gaugeRed,
    },
    [types.analysisRatiosRanges.MODERATE_STRENGTH]: {
      text: "Moderado",
      isPositive: false,
      description:
        "Tu situación financiera podría mejorar.\n\nTu futuro financiero es estable pero no estás cubierto ante cualquier imprevisto que pudiera ocurrir. Dependiendo del evento imprevisto que pudiera ocurrir te podría colocar en una situación financiera complicada.\n\nEs importante seguir mejorando tu situación y proyección financiera para cubrir cualquier imprevisto y tener una mayor tranquilidad.",
      color: colors.gaugeYellow,
    },
    [types.analysisRatiosRanges.NICE_STRENGTH]: {
      text: "Bueno",
      isPositive: true,
      description:
        "Tu situación financiera es buena.\n\nAunque no estás completamente cubierto ante cualquier imprevisto que pudiera ocurrir, tu situación financiera te permite afrontarlos con poco riesgo de afectar a tu futuro financiero tanto en el corto plazo como en el largo plazo.\n\nEn cualquier caso, es importante seguir mejorando el índice de fortaleza financiera para asegurar un buen plan vital.",
      color: colors.gaugeGreen,
    },
    [types.analysisRatiosRanges.EXCELLENT_STRENGTH]: {
      text: "¡Excelente!",
      isPositive: true,
      description:
        "Tu situación financiera es estable y sana.\n\nEstás cubierto ante cualquier imprevisto que pudiera ocurrir y a la vez generando riqueza, tanto en el corto como en el largo plazo.\n\nEs importante seguir manteniendo este nivel del índice de fortaleza financiera para asegurar un buen plan vital.",
      color: colors.gaugeGreen,
    },
  },
  [types.goalRatiosTypes.AVAILABLECAPITAL]: {
    [types.analysisRatiosRanges.LOW_LEVEL]: {
      text: "Nivel bajo",
      isPositive: true,
    },
    [types.analysisRatiosRanges.MEDIUM_LEVEL]: {
      text: "Nivel medio",
      isPositive: false,
    },
    [types.analysisRatiosRanges.HIGH_LEVEL]: {
      text: "Nivel alto",
      isPositive: false,
    },
  },
  [types.goalRatiosTypes.SAVINGSCAPACITY]: {
    [types.analysisRatiosRanges.LOW_LEVEL]: {
      text: "Nivel bajo",
      isPositive: true,
    },
    [types.analysisRatiosRanges.MEDIUM_LEVEL]: {
      text: "Nivel medio",
      isPositive: false,
    },
    [types.analysisRatiosRanges.HIGH_LEVEL]: {
      text: "Nivel alto",
      isPositive: false,
    },
  },
};

const ratiosRangesInfo = (ratioName, rangeName) => {
  return ratiosRangeMatrix[ratioName][rangeName];
};

const balanceTable = {
  MIEMBROS: {
    title: "Miembros",
  },
  [types.assetTypes.REAL_ESTATE]: { title: "Inmuebles" },
  [types.assetTypes.PRIVATE_SHARES]: { title: "Inv. empresariales" },
  [types.assetTypes.PRIVATE_DEBT]: { title: "Deuda privada" },
  [types.assetTypes.OTHER]: { title: "Otros activos" },
  [types.assetTypes.FINANCIAL_INSTRUMENT]: { title: "Portfolio financiero" },
  [types.liabilityTypes.MORTGAGE]: { title: "Hipotecas" },
  [types.liabilityTypes.CREDIT]: { title: "Créditos al consumo" },
  [types.liabilityTypes.OTHER]: { title: "Otros" },
  [types.incomeTypes.PROPERTIES]: { title: "Rentas inmobiliarias" },
  [types.incomeTypes.INVESTMENTS]: { title: "Dividendos empresariales" },
  [types.incomeTypes.PRIVATE_DEBT]: { title: "Rentas deudas privadas" },
  [types.incomeTypes.OTHER_ASSET]: { title: "Rentas otros activos" },
  [types.incomeTypes.LABOUR]: { title: "Trabajo" },
};

const documentsExtensions = {
  [types.documentsExtensions.PDF]: {
    title: "PDF",
    color: colors.colorCarmine500,
    backgroundColor: colors.colorCarmine25,
  },
  [types.documentsExtensions.DOC]: {
    title: "DOC",
    color: colors.colorIndigo500,
    backgroundColor: colors.colorIndigo25,
  },
  [types.documentsExtensions.XLS]: {
    title: "XLS",
    color: colors.xlsColor,
    backgroundColor: colors.xlsBackground,
  },
  [types.documentsExtensions.JPG]: {
    title: "JPG",
    color: colors.imgColor,
    backgroundColor: colors.imgBackground,
  },
  [types.documentsExtensions.PNG]: {
    title: "PNG",
    color: colors.imgColor,
    backgroundColor: colors.imgBackground,
  },
  [types.documentsExtensions.JPEG]: {
    title: "JPEG",
    color: colors.imgColor,
    backgroundColor: colors.imgBackground,
  },
  [types.documentsExtensions.EXT]: {
    title: "EXT",
    color: colors.colorGrey500,
    backgroundColor: colors.colorGrey50,
  },
};

const proposalStrategyTypesCustom = {
  [types.proposalStrategyTypes.AGGRESSIVE]: { title: "Agresiva" },
  [types.proposalStrategyTypes.MODERATE]: { title: "Moderada" },
  [types.proposalStrategyTypes.CONSERVATIVE]: { title: "Conservadora" },
  [types.proposalStrategyTypes.PERSONALIZED]: { title: "Personalizada" },
};

export {
  custom,
  customizeItems,
  customizeMembers,
  pieChartSeries,
  pieChartNewSeries,
  stackedColumnChartSeries,
  stackedColumnChartNewSeries,
  gaugeChartSeries,
  stackedChartSeries,
  ratiosChart,
  customizeTaxDetails,
  fieldsWizardRequiredActions,
  buyAssets,
  newAssets,
  analysisRatiosInfo,
  balanceTable,
  documentsExtensions,
  proposalStrategyTypesCustom,
};
