export const memberTypes = {
  OWNER: "OWNER",
  PARTNER: "PARTNER",
  CHILD: "CHILD",
  OTHER: "OTHER",
  FAMILY: "FAMILY",
  HOLDING: "HOLDING",
  DEPENDENT: "DEPENDENT",
};

export const assetTypes = {
  REAL_ESTATE: "INMUEBLE",
  PRIVATE_SHARES: "ACCIONES PRIVADAS",
  PRIVATE_SHARES_HOLDING: "ACCIONES PRIVADAS HOLDING",
  PRIVATE_DEBT: "DEUDA PRIVADA",
  FINANCIAL_INSTRUMENT: "INSTRUMENTO FINANCIERO",
  OTHER: "OTROS ACTIVOS",
  ASSET_DISTRIBUTOR: "DISTRIBUIDOR DE ACTIVOS",
};

export const portfolioTypes = {
  CURRENT_ACCOUNT: "currentAccount",
  SECURITIES_ACCOUNT: "securitiesAccount",
  CREDIT_CARD: "creditCard",
  LIABILITY: "liability",
  LOAN: "Loan",
  CREDIT: "Credit",
  CONFIRMING: "Confirming",
};

export const securitiesAccountTypes = {
  SECURITIES_ACCOUNT_INVESTMENTS: "investments",
  SECURITIES_ACCOUNT_TRASACTIONS: "transactions",
};

export const liabilityTypes = {
  MORTGAGE: "HIPOTECA",
  CREDIT: "CREDITO",
  OTHER: "OTRAS DEUDAS",
};

export const schoolTypes = {
  PUBLIC: "PUBLIC",
  CONCERTED: "CONCERTED",
  PRIVATE: "PRIVATE",
  CUSTOM: "CUSTOM",
};

export const universityTypes = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
  CUSTOM: "CUSTOM",
};

export const roleTypes = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  CLIENT: "CLIENT",
  EARLY_ADOPTER: "EARLY-ADOPTER",
  COMPANY_CLIENT: "COMPANY-CLIENT",
};

export const categoryTypes = {
  HOME: "Hogar",
  VEHICLE: "Vehículo y transporte",
  PURCHASE: "Compras",
  LEISURE: "Ocio y viajes",
  OTHERS: "Otros gastos",
  FOOD: "Alimentación",
  EDUCATION: "Educación, salud y deporte",
  TAXES: "Impuestos",
  INSURANCES: "Seguros",
};

export const subCategoryTypes = {
  // Hogar
  COMMUNITY: "Comunidad",
  HOUSING_RENTAL: "Alquiler vivienda",
  HOUSEKEEPING: "Mantenimiento del hogar",
  TELEPHONE_TV_INTERNET: "Teléfono, TV e internet",
  DECORATION_FURNITURE: "Decoración y mobiliario",
  SUPPLIES: "Suministros",
  HOME: "Hogar (Otros)",

  // Vehículo y transporte
  VEHICLE_MAINTENANCE: "Mantenimiento de vehículo",
  VEHICLE_TRANSPORT: "Vehículo y transporte (otros)",
  PUBLIC_TRANSPORT: "Transporte público",
  TAXIS: "Taxis",
  VEHICLE_LOAN: "Préstamo de vehículo",
  TOLLS: "Peajes",
  PARKING_GARAGE: "Parking y garaje",
  FUEL: "Combustible",

  // Compras
  FINANCIAL_CARDS: "Tarjetas financieras",
  SHOPPING: "Compras (otros)",
  ELECTRONICS: "Electrónica",
  PETS: "Mascotas",
  CLOTHES_COMPLEMENTS: "Ropa y complementos",
  GIFTS_TOYS: "Regalos y juguetes",
  BEAUTY_PERFUMERY: "Belleza y perfumería",

  // Ocio y viajes
  LEISURE_TRAVEL: "Ocio y viajes (otros)",
  CINEMA_THEATER_SHOWS: "Cine, teatro y espectáculos",
  HOTEL_LODGING: "Hotel y alojamiento",
  LOTTERIES_BETS: "Loterías y apuestas",
  CAFES_RESTAURANTS: "Cafeterías y restaurantes",
  BOOKS_MUSIC_GAMES: "Libros, música y juegos",
  TRAIN_PLANE_TRANSPORT: "Tren, avión, transporte",

  // Otros gastos
  FEES_INTEREST: "Comisiones e intereses",
  OTHER_LOANS_GUARANTEES: "Otros préstamos y avales",
  TAX_PAYMENTS: "Pagos impuestos",
  TRANSFERS: "Transferencias",
  SUBSCRIPTIONS: "Suscripciones",
  ONG: "ONG",
  OTHER_EXPENSES: "Otros gastos (otros)",
  CASHIERS: "Cajeros",
  CHECKS: "Cheques",
  DIVIDENDS: "Dividendos",

  // Alimentación
  SUPERMARKETS_FOOD: "Supermercados y alimentación",
  FOOD: "Alimentación (otros)",

  // Educación, salud y deporte
  EDUCATION_HEALTH_SPORT: "Educación, salud y deporte (otros)",
  OPTICS: "Óptica",
  PHARMACY: "Farmacia",
  EDUCATION: "Educación",
  SPORT_GYM: "Deporte y gimnasio",
  DENTIST_DOCTOR: "Dentista, médico",
  EXTRACURRICULAR_ACTIVITIES: "Actividades extraescolares",

  // Impuestos
  IRPF: "IRPF",
  SOCIAL_SECURITY: "Seguridad Social",
  WEALTH_TAX: "Patrimonio",
  CORPORATE_TAX: "Sociedades",
  LARGE_FORTUNE_SOLIDARITY_TAX: "ITSGF",
  SOLIDARITY_CONTRIBUTION: "Cuota de Solidaridad",
  LOCAL_CAPITAL_GAIN: "Plusvalía Municipal",
  IBI: "IBI",
  OTHERS: "Otros",

  // Seguros
  HOME_INSURANCE: "Hogar",
  HEALTH_INSURANCE: "Salud",
  LIFE_INSURANCE: "Vida",
  VEHICLE_INSURANCE: "Vehículo",
  OTHER_INSURANCE: "Otros seguros",
};

export const savingsTypes = {
  CURRENT: "Ahorro corriente",
  REDEMDEBT: "Reducción de deuda",
  REVALORIZATION: "Revalorización de activos",
};

export const liquidityTypes = {
  LIQUID: "Líquido",
  NOT_LIQUID: "No líquido",
};

export const digitalFootprintProducts = {
  LABORAL_LIFE: "laboralLife",
  ALL: "all",
};

export const digitalFootprintOptionTypes = {
  AUTO: "automatic",
  UPLOAD: "manual",
  MANUAL: "form",
};

export const eventsMemberTypes = {
  SALARY: "salary",
  EXTRAORDINARY_INCOME: "extraordinaryIncome",
  LOAN: "loan",
  EXPENSE: "expense",
  INHERITANCE: "inheritance",
  RETIREMENT: "retirement",
  NEW_MEMBER: "newMember",
  EDUCATION_COST: "educationCost",
  EMANCIPATION: "emancipation",
  DEATH: "death",
  HOLDING_DIVIDENDS_CHANGE: "holdingDividendsChange",
  HOLDING_DIVIDENDS_EXTRAORDINARY: "holdingDividendsExtraordinary",
};

export const eventsAssetTypes = {
  MORTGAGE: "mortgage",
  EXPENSE: "expense",
  OTHER_VALUE: "otherValue",
  COMPANY_VALUE: "companyValue",
  COMPANY_INCOME: "companyIncome",
  COMPANY_SELLING: "companySelling",
  PROPERTY_VALUE: "propertyValue",
  PROPERTY_INCOME: "propertyIncome",
  PROPERTY_SELLING: "propertySelling",
  ASSET_VALUE: "assetValue",
  ASSET_INCOME: "assetIncome",
  OTHER_ASSET_SELLING: "otherAssetSelling",
  OTHER_ASSET_INCOME: "otherAssetIncome",
  NEW_ASSET: "newAsset",
  PORTOFOLIO_REBALANCED_RATES: "portfolioRebalancedRates",
};

export const eventsTypes = {
  LIABILITY_AMORTIZATION: "liabilityAmortization",
  EXPENSE: "expense",
  UPDATE_EXPENSE: "changeValueExpense",
};

export const incomeEventTypes = [
  eventsMemberTypes.SALARY,
  eventsMemberTypes.EXTRAORDINARY_INCOME,
  eventsMemberTypes.INHERITANCE,
  eventsMemberTypes.RETIREMENT,
  eventsMemberTypes.HOLDING_DIVIDENDS_CHANGE,
  eventsAssetTypes.COMPANY_INCOME,
  eventsAssetTypes.PROPERTY_INCOME,
  eventsAssetTypes.ASSET_INCOME,
  eventsAssetTypes.OTHER_ASSET_INCOME,
];

export const expenseEventTypes = [
  eventsMemberTypes.EDUCATION_COST,
  eventsTypes.EXPENSE,
  eventsTypes.UPDATE_EXPENSE,
];

export const activeEventTypes = [
  eventsAssetTypes.NEW_ASSET,
  eventsAssetTypes.PROPERTY_VALUE,
  eventsAssetTypes.COMPANY_VALUE,
  eventsAssetTypes.ASSET_VALUE,
  eventsAssetTypes.OTHER_VALUE,
  eventsAssetTypes.COMPANY_SELLING,
  eventsAssetTypes.PROPERTY_SELLING,
  eventsAssetTypes.OTHER_ASSET_SELLING,
  eventsAssetTypes.PORTOFOLIO_REBALANCED_RATES,
];

export const passiveEventTypes = [
  eventsMemberTypes.LOAN,
  eventsAssetTypes.MORTGAGE,
  eventsTypes.LIABILITY_AMORTIZATION,
];

export const liquidityEventTypes = [
  eventsMemberTypes.SALARY,
  eventsMemberTypes.EXTRAORDINARY_INCOME,
  eventsMemberTypes.EXPENSE,
  eventsMemberTypes.INHERITANCE,
  eventsMemberTypes.EDUCATION_COST,
  eventsMemberTypes.HOLDING_DIVIDENDS_CHANGE,
  eventsAssetTypes.EXPENSE,
  eventsAssetTypes.COMPANY_INCOME,
  eventsAssetTypes.COMPANY_SELLING,
  eventsAssetTypes.PROPERTY_INCOME,
  eventsAssetTypes.PROPERTY_SELLING,
  eventsAssetTypes.ASSET_INCOME,
  eventsAssetTypes.OTHER_ASSET_SELLING,
  eventsAssetTypes.OTHER_ASSET_INCOME,
  eventsAssetTypes.NEW_ASSET,
  eventsTypes.LIABILITY_AMORTIZATION,
  eventsTypes.EXPENSE,
  eventsTypes.UPDATE_EXPENSE,
];

export const mercantileSituationTypes = {
  ACTIVE: "Activa",
  INACTIVE: "Inactiva",
};

export const wealthaboutTypes = {
  main: "main",
  simulation: "simulation",
  holding: "holding",
  holdingSimulation: "holdingSimulation",
  holdingMain: "holdingMain",
};

export const wizardMaturityLevel = {
  BEGINNER: "beginner",
  BASIC: "basic",
  INTERMEDIATE: "intermediate",
  ADVANCE: "advance",
  EXPERT: "expert",
};

export const wizardGhostDocuments = {
  IRPF: "docIRPF",
  CONTRIBUTIONBASES: "docContributionBases",
  WORKINGLIFE: "docWorkingLife",
  BI: "docBI",
};

export const wizardRequiredActions = {
  WORKINGLIFE: "workingLife",
  LOANS: "loans",
  INCOMES: "income",
  PURCHASEPRICE: "purchasePrice",
  MORTGAGE: "mortgage",
  CADASTRALREFERENCE: "cadastralReference",
  CADASTRALVALUE: "cadastralValue",
  PROPERTYVALUE: "value",
  HABITUALLIVING: "habitualLiving",
  SHARES: "shares",
  HOLDINGWEALTHABOUTID: "holdingWealthaboutId",
  ENTITIES: "entities",
  OTHERASSETS: "otherAssets",
  EDUCATIONCOST: "educationCost",
  DOCIRPF: wizardGhostDocuments.IRPF,
  DOCCONTRIBUTIONBASES: wizardGhostDocuments.CONTRIBUTIONBASES,
  DOCWORKINGLIFE: wizardGhostDocuments.WORKINGLIFE,
  DOCBI: wizardGhostDocuments.BI,
};
// Campos con PREFIX (wizardRequiredActions) son funciones para identificar elementos de componentes que se reutilizan
export const wizardHighlightElement = {
  LIABILITIES_ADD_BUTTON: "liabilities-add-button",
  MORTGAGE_ADD_BUTTON: "mortgage-add-button",
  REPOSITORY_MEMBER_BUTTON: "repository-member-button",
  INCOME_MEMBER_FIELD: "income-member-field",
  WORKINGLIFE_MEMBER_BUTTON: "workinglife-member-button",
  PURCHASEPRICE_PROPERTY_FIELD: "purchaseprice-property-field",
  CADASTRAL_PROPERTY_FIELD: "cadastral-property-field",
  PROPERTY_VALUE: "property_value",
  PROPERTY_MORTGAGE: "property_mortgage",
  ASSET_CARD_INFO_ACTIONS: "asset_card_info_actions",
  CADASTRAL_PROPERTY_VAlUE_SURFACE: "cadastral_property_value_surface",
  CADASTRAL_PROPERTY_VALUE_CONSTRUCTION:
    "cadastral_property_value_construction",
  HABITUAL_LIVING: "habitual_living",
  HOLDING_WEALTHABOUT_LINK: "holding_wealthabout_link",
  ACTIONS_DISTRIBUTOR: "actions_distributor",
  NEW_ITEM_CARD: "new_item_card",
  EXPENSE: "expense",
  PREFIX_CHART_EVOLUTION_TITLE_FIELD: prefix =>
    prefix + "-chart-evolution-title-field",
  PREFIX_CHART_EVOLUTION_ACTIONS: prefix => prefix + "-chart-evolution-actions",
  PREFIX_APP_CARD_ACTIONS: prefix => prefix + "-app-card-actions",
  ASSETS_HEADER_EDIT_BUTTOM: "assets_header_edit_buttom",
  MEMBERS_HEADER_EDIT_BUTTOM: "members_header_edit_buttom",
  MEMBERS_LOAN_ACTIONS: "members_loan_actions",
};

export const wizardResourceTypes = {
  MEMBERS: "MEMBERS",
  ASSETS: "ASSETS",
};

export const wizardEmptyTypes = {
  [assetTypes.OTHER]: "otherAssets",
  [assetTypes.FINANCIAL_INSTRUMENT]: "entities",
  [assetTypes.PRIVATE_SHARES]: "shares",
};

export const userFeatureTypes = {
  LINKED_ENTITIES: "linked-entities",
  OPENFINANCE_LINKED_ENTITIES: "openfinance-linked-entities",
  PORTFOLIO_SUMMARY: "portfolio-summary",
  READ_ONLY: "read-only",
};

export const flanksFlowTypes = {
  QUERY_PARAMS_LINK_ACCOUNT: "create-account",
  KEY_LS_TYPE: "typeFlanksFlow",
  KEY_LS_WID: "wealthaboutId",
  KEY_LS_ENTITY: "entityId",
  KEY_LS_EXTERNAL_ENTITY: "externaId",
  KEY_LS_BANK: "bankId",
  KEY_LS_CODE: "code",
  TYPE_TRANS_TOKEN: "transactionToken",
  TYPE_SCA_AUTH_TOKEN: "scaAuthToken",
  TYPE_LINK_ENTITY: "linkEntity",
  TYPE_LINK_ENTITY_TEST: "linkEntityTest",
  TYPE_CAll_BACK: "callBack",
};

// Drive types transferStatus
export const transferStatusTypes = {
  IDLE: "idle",
  UPLOADING: "uploading",
  DOWNLOADING: "downloading",
  FAILED: "failed",
};

export const typeDrives = {
  GOOGLE: "GOOGLE_DRIVE",
  ONE_DRIVE: "ONE_DRIVE",
  WEALTHABOUT: "WEALTHABOUT",
};

export const typeTransactionStatus = {
  CREATED: "created",
  INITIATED: "initiated",
  RECEIVED: "received",
  FINISHED: "finished",
  FAILED: "failed",
};

export const typeActions = {
  HABITUAL_LIVING: "habitualLiving",
  COMPANY_FAMILY_BUSINESS: "familyBusiness",
  MORTGAGE: "mortgage",
  UPDATE_MORTGAGE: "updateMortgage",
  CURRENT_MORTGAGE: "currentMortgage",
  CREDIT: "credit",
  CURRENT_CREDIT: "currentCredit",
  DELETE_MORTGAGE: "deleteMortgage",
  EXPENSE: "expense",
  OTHER_VALUE: "otherValue",
  COMPANY_VALUE: "companyValue",
  COMPANY_INCOME: "companyIncome",
  COMPANY_SELLING: "companySelling",
  PROPERTY_VALUE: "propertyValue",
  PROPERTY_INCOME: "propertyIncome",
  PROPERTY_SELLING: "propertySelling",
  ASSET_VALUE: "assetValue",
  ASSET_INCOME: "assetIncome",
  OTHER_ASSET_SELLING: "otherAssetSelling",
  OTHER_ASSET_INCOME: "otherAssetIncome",
  NEW_MEMBER: "newMember",
  INCOME: "income",
  UPDATE_INCOME: "updateIncome",
  DELETE_INCOME: "deleteIncome",
  EXTRAORDINARY_INCOME: "extraordinaryIncome",
  DIVIDENDS: "dividends",
  INHERITANCE: "inheritance",
  NEW_ASSET: "newAsset",
  NEW_HOLDING_ASSET: "newHoldingAsset",
  MORTGAGE_AMORTIZATION: "mortgageAmortization",
  SETTINGS: "settings",
  LOG_OUT: "logOut",
  CREATE_INVESTMENT: "createInvestment",
  NEW_GOAL: "newGoal",
  SIMULATION: "simulation",
  SET_HOLDING: "setHolding",
  UPDATE_EXPENSE: "changeValueExpense",
  TOGGLE_DISTRIBUTOR_LIST_MODE: "toggleDistributorListMode",
};

export const actionsTypes = {
  ACTION: "action",
  LINK: "link",
  DOWNLOAD: "download",
  HELP: "help",
  ANY: "any",
};

export const orderStatusType = {
  WRONG_INPUT_MSG: "Wrong Input",
  PROCESSING_STATUS: "Processing",
  FAILED_END_STATUS: "Failed End",
  SUCCESSFUL_END_STATUS: "Successful End",
  IRPF_NOT_FOUND: "IRPF not found",
  INIT_STATUS: "Init",
  HUNG_STATUS: "Hung",
  CANCELLED_STATUS: "Cancelled",
  WAITING_DATA_GATHERING: "Waiting data gathering",
};

export const typePropertyAmortization = {
  PAYMENT_REDUCTION: "paymentReduction",
  TERM_REDUCTION: "termReduction",
  TOTAL: "total",
  PARTIAL: "partial",
};

export const reportingTypes = {
  RETIREMENT: "retirement",
  PATRIMONIAL_SUMMARY: "patrimonialSummary",
};

export const monitorTypes = {
  OWE: "owe",
  HAVE: "have",
  SAVE: "save",
  EARN: "earn",
  SPEND: "spend",
  NET_ASSET: "netAsset",
  BALANCE: "balance",
};

export const incomeTypes = {
  LABOUR: "labour",
  RETIREMENT: "retirement",
  PROPERTIES: "properties",
  INVESTMENTS: "investments",
  PRIVATE_DEBT: "privateDebt",
  OTHER_ASSET: "otherAsset",
  DIVIDENDS: "dividends",
  OTHERS: "others",
  EXTRAORDINARY: "extraordinary",
};

export const expenseResourceTypes = {
  MEMBERS: "MIEMBROS",
  PROPERTIES: "INMUEBLE",
  PRIVATE_SHARES: "ACCIONES PRIVADAS",
  OTHER: "OTROS ACTIVOS",
};

export const expensesFilterTypes = {
  CATEGORY: "byCategory",
  RESOURCE: "byResource",
  MEMBER: "byMember",
};

export const analysisSavingsTypes = {
  CURRENT: "currentSavings",
  REDEEMDEBT: "redeemDebt",
  REVALORIZATION: "revalorization",
};

export const investmentType = {
  DEPOSIT: "deposit",
  BOND: "bond",
  STOCK: "stock",
  PENSION_PLAN: "pensionPlan",
  ETF: "etf",
  FUND: "fund",
  OTHER: "other",
};

export const financialAssetType = {
  ACCOUNT: "currentAccount",
  DEPOSIT: "deposit",
  BOND: "bond",
  STOCK: "stock",
  PENSION_PLAN: "pensionPlan",
  ETF: "etf",
  FUND: "fund",
  OTHER: "other",
};

export const familyRiskType = {
  FIXED_INCOME: "fixedIncome", // Renta fija
  EQUITY: "equity", // Renta variable
  BALANCE: "balance", // Renta mixta
  MONEY_MARKET: "moneyMarket", // Monetario
  ALTERNATIVE: "alternative", // Alternativos
  HEDGE_FUNDS: "hedgeFunds", // Hedge funds
  PRIVATE_EQUITY: "privateEquity", // Private equity
  PRIVATE_DEBT: "privateDebt", // Deuda privada
  INFRASTRUCTURES: "infrastructures", // Infraestructuras
  REAL_ESTATE: "realEstate", // Real estate
  UNCLASSIFIED: "unclassified", // Sin clasificar
};

export const flowsTypes = {
  SAVINGS: "Ahorro corriente",
  SALES: "Ventas de activos",
  PURCHASES: "Compras de activos",
  LOANS: "Préstamos",
  PRIVATE_DEBT: "Deudas privadas",
  INHERITANCES: "Herencias",
  EXTRAORDINARY_INCOME: "Ingresos extraordinarios",
  EXTRAORDINARY_EXPENSES: "Gasto extraordinario",
  PORTFOLIO_REVALORIZATION: "Revalorizacion del Portfolio",
};

export const analysisRatiosTypes = {
  INDEBTEDNESS: "indebtedness",
  SOLVENCY: "familySolvency",
  EMERGENCY_FUND: "familyEmergencyFund",
  SAVINGS: "savings",
  FINANCIAL_INDEPENDENCE: "financialIndependence",
  FINANCIAL_STRENGTH: "financialStrength",
};

export const goalRatiosTypes = {
  SAVINGSCAPACITY: "savingsCapacity",
  AVAILABLECAPITAL: "availableCapital",
};

export const analysisRatiosRanges = {
  LOW_LEVEL: "lowLevel",
  MEDIUM_LEVEL: "mediumLevel",
  HIGH_LEVEL: "highLevel",
  LOW_SOLVENCY: "lowSolvency",
  MEDIUM_SOLVENCY: "mediumSolvency",
  HIGH_SOLVENCY: "highSolvency",
  LOW_SAVINGS: "lowSavings",
  MEDIUM_SAVINGS: "mediumSavings",
  HIGH_SAVINGS: "highSavings",
  DEPENDENCE: "dependence",
  INDEPENDENCE: "independence",
  DEFICIENT_STRENGTH: "deficientStrength",
  BAD_STRENGTH: "badStrength",
  MODERATE_STRENGTH: "moderateStrength",
  NICE_STRENGTH: "niceStrength",
  EXCELLENT_STRENGTH: "excellentStrength",
};

export const revalorizationTypes = {
  BCE_GOAL: "bceGoal",
  BCE: "bce",
  IPC: "ipc",
  MANUAL: "manual",
};

export const loansTypes = {
  FIXED: "fixed",
  VARIABLE: "variable",
};

export const AmountTypes = {
  TOTAL: "Total",
  WEIGHTED: "Ponderada",
};

export const ownerPartnerVisibilityCards = {
  VITAL_CALENDAR: { id: "vitalCalendar", name: "Calendario vital" },
  INCOMES: { id: "incomes", name: "Ingresos anuales" },
  RETIREMENT: { id: "retirement", name: "Jubilación" },
  WORKING_LIFE: { id: "workingLife", name: "Vida laboral" },
  MARKET_RELATIONSHIPS: {
    id: "marketRelationships",
    name: "Relaciones mercantiles",
  },
  PERSONAL_CREDITS: { id: "personalCredits", name: "Créditos personales" },
  EXPENSES: { id: "expenses", name: "Estructura de gastos" },
  EVENTS: { id: "events", name: "Eventos" },
  DOCUMENTS: { id: "documents", name: "Documentos" },
  COMMENTS: { id: "comments", name: "Comentarios" },
};

export const childVisibilityCards = {
  VITAL_CALENDAR: { id: "vitalCalendar", name: "Calendario vital" },
  EDUCATION: { id: "education", name: "Educación" },
  EXPENSES: { id: "expenses", name: "Estructura de gastos" },
  EVENTS: { id: "events", name: "Eventos" },
  DOCUMENTS: { id: "documents", name: "Documentos" },
  COMMENTS: { id: "comments", name: "Comentarios" },
};

export const dependantVisibilityCards = {
  VITAL_CALENDAR: { id: "vitalCalendar", name: "Calendario vital" },
  INCOMES: { id: "incomes", name: "Ingresos anuales" },
  EXPENSES: { id: "expenses", name: "Estructura de gastos" },
  EVENTS: { id: "events", name: "Eventos" },
  DOCUMENTS: { id: "documents", name: "Documentos" },
  COMMENTS: { id: "comments", name: "Comentarios" },
};

export const propertiesVisibilityCards = {
  CADASTRAL_INFO: { id: "cadastralInfo", name: "Información catastral" },
  VALUE: { id: "value", name: "Valor" },
  MORTGAGE: { id: "mortgage", name: "Hipoteca" },
  INCOMES: { id: "incomes", name: "Renta anual" },
  EXPENSES: { id: "expenses", name: "Estructura de gastos" },
  EVENTS: { id: "events", name: "Eventos" },
  DOCUMENTS: { id: "documents", name: "Documentos" },
  COMMENTS: { id: "comments", name: "Comentarios" },
};

export const privateSharesVisibilityCards = {
  MERCANTILE_INFO: { id: "mercantileInfo", name: "Información mercantil" },
  VALUE: { id: "value", name: "Valor" },
  INCOMES: { id: "incomes", name: "Dividendo anual" },
  EXPENSES: { id: "expenses", name: "Estructura de gastos" },
  EVENTS: { id: "events", name: "Eventos" },
  DOCUMENTS: { id: "documents", name: "Documentos" },
  COMMENTS: { id: "comments", name: "Comentarios" },
};

export const otherAssetsVisibilityCards = {
  VALUE: { id: "value", name: "Valor" },
  INCOMES: { id: "incomes", name: "Renta anual" },
  EXPENSES: { id: "expenses", name: "Estructura de gastos" },
  EVENTS: { id: "events", name: "Eventos" },
  DOCUMENTS: { id: "documents", name: "Documentos" },
  COMMENTS: { id: "comments", name: "Comentarios" },
};

export const inventoryTypes = {
  ACTIVE: "active",
  PASSIVE: "passive",
  INCOMES: "incomes",
  EXPENSES: "expenses",
  MORTGAGE: "mortgage",
  CREDIT: "credit",
  PROPERTIES: "properties",
  MERCANTILE: "mercantile",
  OTHER_ASSET: "otherAsset",
  FINANCIAL_PORTFOLIO: "financialPortfolio",
  MEMBERS: "members",
  LABOUR: "labour",
  RETIREMENT: "retirement",
  ACCOUNTS_DEPOSITS: "accountsDeposits",
  SECURITIES_ACCOUNTS: "securitiesAccounts",
  INVESTMENTS: "investments",
  PENSION_PLANS: "pensionPlans",
  CURRENT_SAVINGS: "currentSavings",
  REDEEM_DEBT: "redeemDebt",
  REVALORIZATION: "revalorization",
  DIVIDENDS: "dividends",
  OTHERS: "others",
  EXTRAORDINARY: "extraordinary",
};

export const expensesType = {
  STRUCTURAL: "structural",
  EXTRAORDINARY: "extraordinary",
  // Gastos de los dividendos de las holdings
  DIVIDENDS: "dividends",
  EXTRAORDINARY_DIVIDENDS: "extraordinaryDividends",
  TAXES: "taxes",
  SCHOOL_COST: "schoolCost",
  UNIVERSITY_COST: "universityCost",
  DEBT_PAYMENT: "debtPayment",
  DEFAULT: "default",
};

export const documentsExtensions = {
  PDF: "pdf",
  JPG: "jpg",
  JPEG: "jpeg",
  PNG: "png",
  XLS: "xls",
  DOC: "doc",
  EXT: "ext",
};

export const notificationsTypes = {
  INFO: "INFO",
  ACTION: "ACTION",
  PROCESS: "PROCESS",
};

export const proposalStrategyTypes = {
  AGGRESSIVE: "aggressive",
  MODERATE: "moderate",
  CONSERVATIVE: "conservative",
  PERSONALIZED: "personalized",
};

export const scenarioTypes = {
  BEST: "best",
  MEDIUM: "medium",
  WORST: "worst",
};

export const educationTypes = {
  SCHOOL: "school",
  UNIVERSITY: "university",
};

export const navigationGroupTypes = {
  PUBLIC: "public",
  PUBLIC_NOT_LOGGIN: "public-not-loggin",
  MANAGEMENT: "management",
  WA_NOT_DATA: "wa-not-data",
  WA: "wa",
};

// Tax steps
export const irpfSteps = {
  RCM: "rcm",
  GPP: "gpp",
  BIG: "big",
  BIA: "bia",
  BLG: "blg",
  BLA: "bla",
  MPF: "mpf",
  CGE: "cge",
  CAE: "cae",
  CIE: "cie",
  CLE: "cle",
  CGA: "cga",
  CAA: "caa",
  CIA: "cia",
  CLA: "cla",
  CL: "cl",
  CD: "cd",
};

export const isSteps = {
  CPG: "cpg",
  GPP: "gpp",
  BI: "bi",
  CI: "ci",
  CL: "cl",
  CD: "cd",
};

export const ipSteps = {
  BI: "bi",
  BL: "bl",
  CIR: "cir",
  CAI: "cai",
};

export const itsgfSteps = {
  BI: "bi",
  BL: "bl",
  CIR: "cir",
  CAI: "cai",
};
