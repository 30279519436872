export default defineNuxtPlugin(nuxtApp => {
  const env = nuxtApp.$env.ENV;
  const title = env === "prod" ? "Wealthabout" : `${env.toUpperCase()} - Wealthabout`;

  useHead({
    title,
    meta: [
      {
        hid: "description",
        name: "description",
        content: nuxtApp.$env.DESCRIPTION_PAGE || ""
      }
    ]
  });
});
