
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as banking19wUp3D1G9xAmWNY9VeWKUEvxKfQNTptemW9q4CIvywMeta } from "/app/pages/banking.vue?macro=true";
import { default as change_45passwordHwMaQx9EzsQFBv3IGKruelsYurrkLjj80aWIqEka77QMeta } from "/app/pages/change-password.vue?macro=true";
import { default as upload_45waemJS4F_nzKibX3BdaoWvKkOdnJXkqkQITwvvFPScaKQMeta } from "/app/pages/clients/[[clientid]]/upload-wa.vue?macro=true";
import { default as annualmonitorsjwAos0dArqGycs599fVVTNQ0JLB1M_nRH01upZGvRLgMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/annualmonitors.vue?macro=true";
import { default as indextnatirtmeaMlQ4qYuA2iuo5EZqIreWfHdv8Jk0hKdz0Meta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/[assetid]/index.vue?macro=true";
import { default as repositorysTrO1nDHNE8qLHWdaKOQeKUSXOPQwqb_LPY4Pqo6vBEMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/[assetid]/repository.vue?macro=true";
import { default as settingsZ6H4fvl_45qW1aUW_45oBQFBNO3yH9uzj6tPqflAq75mZWIMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/[assetid]/settings.vue?macro=true";
import { default as _91assetid_93i_FpmhJ5WlpvZoGAR3LfmBaUt3ghRoWX4Q_N7o5yM7sMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/[assetid].vue?macro=true";
import { default as other_45assetsQPXWJvUCY5JER64Y1AlFFVM0O7egeEdztN1lXkOT7PcMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/distributor/other-assets.vue?macro=true";
import { default as private_45debts_O9sT4kx1wsNQ_KIqTC_45UniWdK_HKiPnDaxfUBBpk2UMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/distributor/private-debts.vue?macro=true";
import { default as private_45sharesDQV36g7ycnWWkQFbFWRsNbVZr6JbBFZYxyWPWs36El8Meta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/distributor/private-shares.vue?macro=true";
import { default as real_45estate1HrMGHnLFBP5feHHLiVZ3rb9l3_jBjt1VzPJ8Rof8VMMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/distributor/real-estate.vue?macro=true";
import { default as incomes_45expensesWpaTX_Sok3kOMVlZfUub8QaSf4JRcF7D2zZjPnWRwgoMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/incomes-expenses.vue?macro=true";
import { default as loadingyJMIh_8GaYzCQylREsQohho5JmJdAlCuEDM1RCYYnP4Meta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/loading.vue?macro=true";
import { default as mapFg2Y4mkZ5LlkNHGtL_45DRzUP5bBs0vw68LgeCxfAuyOQMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/map.vue?macro=true";
import { default as index3Zkvvo2_45OY1cJci4EGPBjfQTZgIZ8X8GFVYh9lNkLJkMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/[memberid]/index.vue?macro=true";
import { default as repositoryPGWZjJOamkwj_45WL1EGY_45_45eCzZkpo8UDm4gfG7bsyezIMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/[memberid]/repository.vue?macro=true";
import { default as settingsbhRMuJlr9L7Ti_45pNLBlv_45Cs_I_45FaBT4Iw9WFJBxtW_45IMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/[memberid]/settings.vue?macro=true";
import { default as _91memberid_9367Va_kRpga_5ijhzT32AUp_eXQdUD68Xmmsnq3sLeN8Meta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/[memberid].vue?macro=true";
import { default as index1X_45V17_45dO9xz_NWqZbppmxfS549IIm27V62pyrT1n0MMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/family/index.vue?macro=true";
import { default as repositoryeWDYVQrf0JlT4NomcoPgN_45iWM1HZcs78QKIEFAob6DgMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/family/repository.vue?macro=true";
import { default as indexnCGcWEnxVFZ1_45yBd6cK0fNjAhKbwxXTigc99GZOx2HwMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/holding/index.vue?macro=true";
import { default as repositoryy6iaYaEiKdeQo_cd3IvJnWtxEnjwStBxXES0lWeqbAYMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/holding/repository.vue?macro=true";
import { default as index32o0tHfCIHbAJF_CUS35Dtj1ncVZDPF5pD5QQta8kJIMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/assetproposal/index.vue?macro=true";
import { default as settingsxqadQgNB1nT982ReshSPh_45BYzGXg4xqti6jVJbHJkl0Meta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/assetproposal/settings.vue?macro=true";
import { default as assetproposal4UjE3V2idyozQ4nqAVrVaDwV_45viP7F1JruqKy5iz6OYMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/assetproposal.vue?macro=true";
import { default as indexKqRC5fQBozyqoTtPH9nsiAmpjmoRRqb8hJsxxK6dhl8Meta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/education/index.vue?macro=true";
import { default as settingsrEnADkfb7r9DKhKUpUWKvaYeTNEUv3zkMaBY0Zx2hDgMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/education/settings.vue?macro=true";
import { default as educationlgCdhL7kaUlxQgpFzWOjA6YtG7IsJTwu_459oHwFoZBosMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/education.vue?macro=true";
import { default as indexNxzf3KivdK2ywUyxM483aYD7EVKvESX8t6OsbKR0rm4Meta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/goals/[goalsid]/index.vue?macro=true";
import { default as settingsdJ0nZ_45nKk31JuIayPLCZRoNucCa8YIwVnQXZgVf0G1AMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/goals/[goalsid]/settings.vue?macro=true";
import { default as _91goalsid_933yFktR4PyhgKtCViKULz1Jquc6jepGEeJcnygF1k8OUMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/goals/[goalsid].vue?macro=true";
import { default as indexVcEbCXeONxGDVzv8LWK9eO8oTtu_45at4SVZU9vuFmIBoMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/goals/index.vue?macro=true";
import { default as inheritancesOFJZHaIR9_45hpn9G4i852NxzPncYT7vrilSZCOApJ_45ngMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/inheritances.vue?macro=true";
import { default as indexEl_4573EQdcIfgJhLan4owogqtnzZUkhaK8Uo7qhNOdNkMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/insurance/index.vue?macro=true";
import { default as indexYzi784vCTiavdEj5E3uoC2Romeg9Y_45sjG9bjvWub3IYMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/portfolioproposal/index.vue?macro=true";
import { default as settingsXkB03RRe0NlAQ1PicqB9ENlqB0Hdin8gKSsTA_egwnwMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/portfolioproposal/settings.vue?macro=true";
import { default as portfolioproposalucfLM5_NtamOTar2akrZmvsMKMbESbcWAxW9xlcJMHMMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/portfolioproposal.vue?macro=true";
import { default as detail6g9dVWuEcSjqckSrG1AtGHiWMPnSGb_qHSExAiDhVJkMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/taxes/detail.vue?macro=true";
import { default as indexhWoFb2kqyMpYZjYOJm6ID9nOpSfhgbYwzXLCDo03xSsMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/taxes/index.vue?macro=true";
import { default as what_45earnhhx_KZwWAUuDgxhIoA7BD0f7xf8EbyLo7B_SSkl5mzEMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-earn.vue?macro=true";
import { default as what_45haveXlf_45vlDh3_45xpactIZFIMIKgDlWaCdDaOrQiqyVPwllQMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-have.vue?macro=true";
import { default as what_45oweCgGVynUKpyDDixnG2rNwZAvwLo4jVYUbOZFGAH0GvSoMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-owe.vue?macro=true";
import { default as what_45saveuXZpF0B4VqpXYyo0ayxd296kuL_458k4YLV1OwCbwzW6YMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-save.vue?macro=true";
import { default as expensesp7oNAmyZeVib0_hOZjy0dKueJzniTjk7knC1y8okDKEMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-spend/expenses.vue?macro=true";
import { default as index2H_sWJx1cWuc0zvH2IkQI4n7k2Qou40ljche8b0mlzUMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-spend/index.vue?macro=true";
import { default as investmentsEuQoeTS6mQxXoxYLqHb_JcBRLeJtkc_45YLHPBY2mKQtUMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/[bankassetid]/investments.vue?macro=true";
import { default as movementscURzTgTjxzLu4Tc0NP7Sr40MqwgY_ytzquAVPdAeiBYMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/[bankassetid]/movements.vue?macro=true";
import { default as transactionsBlbXTVebRrsduBxqeUAmRkzVS6mIWio3qTCJ6hGm_45eIMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/[bankassetid]/transactions.vue?macro=true";
import { default as _91bankassetid_93ew67D5Y04cRvk50Y7vX7knoqRO8m5HViLAwzmdSIAuMMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/[bankassetid].vue?macro=true";
import { default as current_45situationVvn6eHGPbLCxbphMyE3BdjOP_XsQjvx6Cdk87tqs_jMMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/current-situation.vue?macro=true";
import { default as future_45projection_TsxLZKM3Hc9mOfwu_45H3JaQx2_45XH_4mqjV55xFZ3ixIMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/future-projection.vue?macro=true";
import { default as index_45BbTwVwBAn3mOLJqpX1MRXd_45nfHqVmsr5jgMthXmPD4Meta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/index.vue?macro=true";
import { default as settingsDP39ler8v5RMRekwqQkR3uwIrg4Smgf5kHZ_45WKKyvmwMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/settings.vue?macro=true";
import { default as summaryBS0fkdEflEygzkBzAr8sWpi8BDKXEwR_45ewjzJlklhBUMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/summary.vue?macro=true";
import { default as portfolioiOqbRZSxTrDr_45w6FHI9aBc_45HQPqlnCKxRXHJKJZT1CwMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio.vue?macro=true";
import { default as settingsAZkKZMZFEj8RzQBP77jb7xjqz1xTSOSDn7xk_45wClPfAMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/settings.vue?macro=true";
import { default as simulations8uAZPg7xxEp78SB4tHMST_NQWLRUlZWmi21gDpFvwc8Meta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/simulations.vue?macro=true";
import { default as situationUTWeZo1ZnyAycS1Kg3vMFP4sem6JP4ps0PzKfReMfkkMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/situation.vue?macro=true";
import { default as summary9wfPM8wPDbxgY5sRvQ1GxJPQjpR8pPvzx1af_dm3kgMMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/summary.vue?macro=true";
import { default as vitalmonitorsNwmT6YM81f_45Iw5Mi_ju1GlrbNyYwBStF9TFWCsr3bVQMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/vitalmonitors.vue?macro=true";
import { default as _91wealthaboutid_93gZxrcYoxHQNOruq07vFER5CFK063wlS_ugoIzeZXlZAMeta } from "/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid].vue?macro=true";
import { default as index_45R9j3Km62sci8PcMakeGb_4e_ztRrqbUFgTxim_hGcUMeta } from "/app/pages/clients/index.vue?macro=true";
import { default as ordersltx_45zyU8VoP8cIotnOxNT1RWwfbK5PGFBPpE_45BeDU5oMeta } from "/app/pages/clients/orders.vue?macro=true";
import { default as cookiespolicyfI1q5H4QK2ApERTTv3lvgHLXacBR6BBrSfiHpyC_yOYMeta } from "/app/pages/cookiespolicy.vue?macro=true";
import { default as callbackLMzNXMk_45Cdz3N_45wc_btKptU0O2eT6mfE8bw3Fw0dSJ0Meta } from "/app/pages/drive/callback.vue?macro=true";
import { default as forcederror9Wc_rvxK4x3iOjX_eT2SkZ9ywvfojZUrwvycyBUsFnUMeta } from "/app/pages/forcederror.vue?macro=true";
import { default as homeATfYOZM_NGzagjGhIsvcT2b9_cFGO0yRVLFd_45gK_451EkMeta } from "/app/pages/home.vue?macro=true";
import { default as loginKGJhNRZhH4FU_45RnHfId5ZCGRhKzPnQPbdAbdNd47TwsMeta } from "/app/pages/login.vue?macro=true";
import { default as onboardingnhM6Ok2bvnquKgRcZqSM37yb43tINEX1JyVq5nZnSbAMeta } from "/app/pages/onboarding.vue?macro=true";
import { default as panelbikRCHqWlLBpJPqEffkVEouWMnh5vkn95kvxL74ETQQMeta } from "/app/pages/panel.vue?macro=true";
import { default as privacypolicyYvfi3fpQBIuSr_m_453oSBz9haJAFZGA9hymXeE_45OWBmEMeta } from "/app/pages/privacypolicy.vue?macro=true";
import { default as reset_45passwordiWFFQc4mqHAplbYzhpvNKouyr6Qp9Bvu_45FM1ScN8_pgMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as signupXDZ9vem3RhITR7dUmFJxupvJgksTCjysSig_ObPaBwEMeta } from "/app/pages/signup.vue?macro=true";
export default [
  {
    name: "banking",
    path: "/banking",
    meta: banking19wUp3D1G9xAmWNY9VeWKUEvxKfQNTptemW9q4CIvywMeta || {},
    component: () => import("/app/pages/banking.vue")
  },
  {
    name: "change-password",
    path: "/change-password",
    meta: change_45passwordHwMaQx9EzsQFBv3IGKruelsYurrkLjj80aWIqEka77QMeta || {},
    component: () => import("/app/pages/change-password.vue")
  },
  {
    name: "clients-clientid-upload-wa",
    path: "/clients/:clientid?/upload-wa",
    meta: upload_45waemJS4F_nzKibX3BdaoWvKkOdnJXkqkQITwvvFPScaKQMeta || {},
    component: () => import("/app/pages/clients/[[clientid]]/upload-wa.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid",
    path: "/clients/:clientid?/wealthabouts/:wealthaboutid()",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid].vue"),
    children: [
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-annualmonitors",
    path: "annualmonitors",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/annualmonitors.vue")
  },
  {
    name: _91assetid_93i_FpmhJ5WlpvZoGAR3LfmBaUt3ghRoWX4Q_N7o5yM7sMeta?.name,
    path: "assets/:assetid()",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/[assetid].vue"),
    children: [
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-assets-assetid",
    path: "",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/[assetid]/index.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-assets-assetid-repository",
    path: "repository",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/[assetid]/repository.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-assets-assetid-settings",
    path: "settings",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/[assetid]/settings.vue")
  }
]
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-assets-distributor-other-assets",
    path: "assets/distributor/other-assets",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/distributor/other-assets.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-assets-distributor-private-debts",
    path: "assets/distributor/private-debts",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/distributor/private-debts.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-assets-distributor-private-shares",
    path: "assets/distributor/private-shares",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/distributor/private-shares.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-assets-distributor-real-estate",
    path: "assets/distributor/real-estate",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/assets/distributor/real-estate.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-incomes-expenses",
    path: "incomes-expenses",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/incomes-expenses.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-loading",
    path: "loading",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/loading.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-map",
    path: "map",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/map.vue")
  },
  {
    name: _91memberid_9367Va_kRpga_5ijhzT32AUp_eXQdUD68Xmmsnq3sLeN8Meta?.name,
    path: "members/:memberid()",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/[memberid].vue"),
    children: [
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-members-memberid",
    path: "",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/[memberid]/index.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-members-memberid-repository",
    path: "repository",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/[memberid]/repository.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-members-memberid-settings",
    path: "settings",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/[memberid]/settings.vue")
  }
]
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-members-family",
    path: "members/family",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/family/index.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-members-family-repository",
    path: "members/family/repository",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/family/repository.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-members-holding",
    path: "members/holding",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/holding/index.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-members-holding-repository",
    path: "members/holding/repository",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/members/holding/repository.vue")
  },
  {
    name: assetproposal4UjE3V2idyozQ4nqAVrVaDwV_45viP7F1JruqKy5iz6OYMeta?.name,
    path: "module/assetproposal",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/assetproposal.vue"),
    children: [
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-assetproposal",
    path: "",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/assetproposal/index.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-assetproposal-settings",
    path: "settings",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/assetproposal/settings.vue")
  }
]
  },
  {
    name: educationlgCdhL7kaUlxQgpFzWOjA6YtG7IsJTwu_459oHwFoZBosMeta?.name,
    path: "module/education",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/education.vue"),
    children: [
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-education",
    path: "",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/education/index.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-education-settings",
    path: "settings",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/education/settings.vue")
  }
]
  },
  {
    name: _91goalsid_933yFktR4PyhgKtCViKULz1Jquc6jepGEeJcnygF1k8OUMeta?.name,
    path: "module/goals/:goalsid()",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/goals/[goalsid].vue"),
    children: [
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-goals-goalsid",
    path: "",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/goals/[goalsid]/index.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-goals-goalsid-settings",
    path: "settings",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/goals/[goalsid]/settings.vue")
  }
]
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-goals",
    path: "module/goals",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/goals/index.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-inheritances",
    path: "module/inheritances",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/inheritances.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-insurance",
    path: "module/insurance",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/insurance/index.vue")
  },
  {
    name: portfolioproposalucfLM5_NtamOTar2akrZmvsMKMbESbcWAxW9xlcJMHMMeta?.name,
    path: "module/portfolioproposal",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/portfolioproposal.vue"),
    children: [
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-portfolioproposal",
    path: "",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/portfolioproposal/index.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-portfolioproposal-settings",
    path: "settings",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/portfolioproposal/settings.vue")
  }
]
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-taxes-detail",
    path: "module/taxes/detail",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/taxes/detail.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-module-taxes",
    path: "module/taxes",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/module/taxes/index.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-monitors-what-earn",
    path: "monitors/what-earn",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-earn.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-monitors-what-have",
    path: "monitors/what-have",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-have.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-monitors-what-owe",
    path: "monitors/what-owe",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-owe.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-monitors-what-save",
    path: "monitors/what-save",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-save.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-monitors-what-spend-expenses",
    path: "monitors/what-spend/expenses",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-spend/expenses.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-monitors-what-spend",
    path: "monitors/what-spend",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/monitors/what-spend/index.vue")
  },
  {
    name: portfolioiOqbRZSxTrDr_45w6FHI9aBc_45HQPqlnCKxRXHJKJZT1CwMeta?.name,
    path: "portfolio",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio.vue"),
    children: [
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-portfolio-bankassetid",
    path: ":bankassetid()",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/[bankassetid].vue"),
    children: [
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-portfolio-bankassetid-investments",
    path: "investments",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/[bankassetid]/investments.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-portfolio-bankassetid-movements",
    path: "movements",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/[bankassetid]/movements.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-portfolio-bankassetid-transactions",
    path: "transactions",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/[bankassetid]/transactions.vue")
  }
]
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-portfolio-current-situation",
    path: "current-situation",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/current-situation.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-portfolio-future-projection",
    path: "future-projection",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/future-projection.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-portfolio",
    path: "",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/index.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-portfolio-settings",
    path: "settings",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/settings.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-portfolio-summary",
    path: "summary",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/portfolio/summary.vue")
  }
]
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-settings",
    path: "settings",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/settings.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-simulations",
    path: "simulations",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/simulations.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-situation",
    path: "situation",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/situation.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-summary",
    path: "summary",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/summary.vue")
  },
  {
    name: "clients-clientid-wealthabouts-wealthaboutid-vitalmonitors",
    path: "vitalmonitors",
    component: () => import("/app/pages/clients/[[clientid]]/wealthabouts/[wealthaboutid]/vitalmonitors.vue")
  }
]
  },
  {
    name: "clients",
    path: "/clients",
    meta: index_45R9j3Km62sci8PcMakeGb_4e_ztRrqbUFgTxim_hGcUMeta || {},
    component: () => import("/app/pages/clients/index.vue")
  },
  {
    name: "clients-orders",
    path: "/clients/orders",
    meta: ordersltx_45zyU8VoP8cIotnOxNT1RWwfbK5PGFBPpE_45BeDU5oMeta || {},
    component: () => import("/app/pages/clients/orders.vue")
  },
  {
    name: "cookiespolicy",
    path: "/cookiespolicy",
    meta: cookiespolicyfI1q5H4QK2ApERTTv3lvgHLXacBR6BBrSfiHpyC_yOYMeta || {},
    component: () => import("/app/pages/cookiespolicy.vue")
  },
  {
    name: "drive-callback",
    path: "/drive/callback",
    meta: callbackLMzNXMk_45Cdz3N_45wc_btKptU0O2eT6mfE8bw3Fw0dSJ0Meta || {},
    component: () => import("/app/pages/drive/callback.vue")
  },
  {
    name: "forcederror",
    path: "/forcederror",
    meta: forcederror9Wc_rvxK4x3iOjX_eT2SkZ9ywvfojZUrwvycyBUsFnUMeta || {},
    component: () => import("/app/pages/forcederror.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homeATfYOZM_NGzagjGhIsvcT2b9_cFGO0yRVLFd_45gK_451EkMeta || {},
    component: () => import("/app/pages/home.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginKGJhNRZhH4FU_45RnHfId5ZCGRhKzPnQPbdAbdNd47TwsMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingnhM6Ok2bvnquKgRcZqSM37yb43tINEX1JyVq5nZnSbAMeta || {},
    component: () => import("/app/pages/onboarding.vue")
  },
  {
    name: "panel",
    path: "/panel",
    meta: panelbikRCHqWlLBpJPqEffkVEouWMnh5vkn95kvxL74ETQQMeta || {},
    component: () => import("/app/pages/panel.vue")
  },
  {
    name: "privacypolicy",
    path: "/privacypolicy",
    meta: privacypolicyYvfi3fpQBIuSr_m_453oSBz9haJAFZGA9hymXeE_45OWBmEMeta || {},
    component: () => import("/app/pages/privacypolicy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordiWFFQc4mqHAplbYzhpvNKouyr6Qp9Bvu_45FM1ScN8_pgMeta || {},
    component: () => import("/app/pages/reset-password.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupXDZ9vem3RhITR7dUmFJxupvJgksTCjysSig_ObPaBwEMeta || {},
    component: () => import("/app/pages/signup.vue")
  }
]