import { vMaska } from "maska";

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.directive("observer", {
    mounted(el, binding) {
      if (import.meta.env.SSR) return; // Si estamos en el servidor, no hacemos nada

      const expression = binding.value;

      function addClass() {
        el.classList.add(expression);
      }

      function callback(entries, observer) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            addClass();
            observer.unobserve(el);
          }
        });
      }

      function createIntersectionObserver() {
        const options = {
          root: null,
          threshold: 0.25,
          rootMargin: "-100px",
        };
        const observer = new IntersectionObserver(callback, options);
        observer.observe(el);
      }

      if (!window.IntersectionObserver) {
        addClass();
      } else {
        createIntersectionObserver();
      }
    },
  });

  vueApp.directive("click-outside", {
    mounted(el, binding) {
      if (import.meta.env.SSR) return;

      function onClickOut(event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event);
        }
      }
      el.clickOutsideEvent = onClickOut;
      setTimeout(() => {
        document.body.addEventListener("click", el.clickOutsideEvent, true);
      }, 0);
    },
    unmounted(el) {
      if (import.meta.env.SSR) return;

      document.body.removeEventListener("click", el.clickOutsideEvent);
    },
  });

  vueApp.directive("scroll", {
    mounted(el, binding) {
      if (import.meta.env.SSR) return;

      window.addEventListener("scroll", binding.value);
    },
    unmounted(el, binding) {
      if (import.meta.env.SSR) return;

      window.removeEventListener("scroll", binding.value);
    },
  });

  vueApp.directive("maska", vMaska);

  vueApp.directive("focus-trap", {
    mounted(el) {
      if (import.meta.env.SSR) return;

      const focusableElementsSelector =
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select, [tabindex]:not([tabindex="-1"])';

      el.handleKeyDown = function (event) {
        const focusableElements = Array.from(el.querySelectorAll(focusableElementsSelector));

        if (event.key === "Tab") {
          if (focusableElements.length === 0) {
            event.preventDefault();
            return;
          }

          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];

          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (!event.shiftKey && document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      };

      el.addEventListener("keydown", el.handleKeyDown);
    },
    unmounted(el) {
      if (import.meta.env.SSR) return;

      el.removeEventListener("keydown", el.handleKeyDown);
      delete el.handleKeyDown;
    },
  });

  vueApp.directive("focus-outside", {
    mounted(el, binding) {
      if (import.meta.env.SSR) return;

      el.handleFocusOut = function (event) {
        const isFocusInside = el.contains(event.target);
        const isFocusOnElementWithTabindex = el.hasAttribute("tabindex") && el === event.target;

        if (!isFocusInside && !isFocusOnElementWithTabindex) {
          binding.value(event);
        }
      };
      setTimeout(() => {
        document.addEventListener("focusin", el.handleFocusOut);
      }, 0);
    },
    unmounted(el) {
      if (import.meta.env.SSR) return;

      document.removeEventListener("focusin", el.handleFocusOut);
      delete el.handleFocusOut;
    },
  });

  vueApp.directive("focus-click", {
    mounted(el, binding) {
      if (import.meta.env.SSR) return;

      const handleClick = event => {
        const isInside = el.contains(event.target);
        binding.value(isInside);
      };

      document.addEventListener("click", handleClick);
      el._handleClick_ = handleClick;
    },
    unmounted(el) {
      if (import.meta.env.SSR) return;

      document.removeEventListener("click", el._handleClick_);
    },
  });
});
