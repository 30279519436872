import { useWealthaboutStore } from "@/stores/wealthaboutStore";

export default defineNuxtPlugin(nuxtApp => {
  // Obtén la instancia global de i18n desde las propiedades globales de Vue
  const i18n = nuxtApp.vueApp.config.globalProperties.$i18n;
  const wealthaboutStore = useWealthaboutStore();
  // Observa cambios en isHolding para cambiar el idioma dinámicamente
  watchEffect(() => {
    const newLocale = wealthaboutStore.isHolding ? "es-business" : "es";
    if (i18n.locale !== newLocale) {
      i18n.setLocale(newLocale); // Usa el método que provee el módulo para cambiar el idioma
    }
  });

  return {
    provide: {
      // Esto permite usar $t tanto en .js como en .vue
      t: nuxtApp.vueApp.config.globalProperties.$t,
    },
  };
});
