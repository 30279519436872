import { getCustomRequiredActions } from "@/assets/logic/wizard/utils.js";
import { wizardRequiredActions, wizardHighlightElement } from "@/assets/logic/types";

export default {
  [wizardRequiredActions.LOANS]: {
    title: getCustomRequiredActions(wizardRequiredActions.LOANS),
    description: "¿Tienes algún crédito personal?",
    actionsDiscard: "No tengo",
    actionsContinue: "Tengo",
    steps: [
      {
        description: "Si tiene algún crédito personal debe añadirlo pinchando en los tres puntos. Puede añadir tantos como tenga",
        highlightElement: wizardHighlightElement.MEMBERS_LOAN_ACTIONS,
        nextOnClick: true
      }
    ]
  },
  [wizardRequiredActions.INCOMES]: {
    title: getCustomRequiredActions(wizardRequiredActions.INCOMES),
    description: "Si obtiene algún ingreso por su trabajo tiene distintas formas de recogerlo.",
    listOptions: [
      // {
      //   id: 1,
      //   text: "Recoger automáticamente de hacienda. Requiere cl@ve pin."
      // },
      {
        id: 2,
        text: "Cargar IRPF manualmente. Requiere el pdf que le entregó hacienda al presentarlo."
      },
      {
        id: 3,
        text: "Introducir el importe manualmente."
      }
    ],
    actionsDiscard: "No tengo ingresos",
    stepsOptions: {
      3: [
        {
          description: "Introduce el valor directamente en el campo resaltado.",
          highlightElement: wizardHighlightElement.PREFIX_CHART_EVOLUTION_TITLE_FIELD(wizardRequiredActions.INCOMES),
          closeOnClick: false
        }
      ],
      2: [
        {
          description: "Navega a la página del repositorio y sube el último IRPF.",
          highlightElement: wizardHighlightElement.REPOSITORY_MEMBER_BUTTON,
          nextOnClick: true
        }
      ]
    }
  },
  [wizardRequiredActions.WORKINGLIFE]: {
    title: getCustomRequiredActions(wizardRequiredActions.WORKINGLIFE),
    description: "Obtener la información de vida laboral le permitirá saber cuándo se puede jubilar y estimar cuánto cobrará de pensión.",
    listOptions: [
      {
        id: 1,
        text: "Recoger automáticamente de la seguridad social. Requiere cl@ve pin."
      },
      {
        id: 2,
        text: "Cargar manualmente. Requiere el pdf del informe de tu vida laboral."
      }
    ],
    actionsDiscard: "Nunca he trabajado",
    stepsOptions: {
      1: [
        {
          description: "Boton actualizar vida laboral",
          highlightElement: wizardHighlightElement.WORKINGLIFE_MEMBER_BUTTON,
          nextOnClick: true
        }
      ],
      2: [
        {
          description: "Navega a la página del repositorio y sube el informe.",
          highlightElement: wizardHighlightElement.REPOSITORY_MEMBER_BUTTON,
          nextOnClick: true
        }
      ]
    }
  }
};
