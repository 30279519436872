import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { XMLHttpRequestInstrumentation } from "@opentelemetry/instrumentation-xml-http-request";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { context as contextOpentelemetry, trace, propagation } from "@opentelemetry/api";

// Ejemplo de uso de OpenTelemetry
// Hay que usar el método withOpentelemetry para envolver las llamadas a la API. El primer argumento es el nombre que le queramos
// poner al span y el segundo es la llamada a la API pasada como arrow function para ser llamada dentro de withOpentelemetry.
// El tercer argumento es opcional y son los headers que queramos enviar a la API.
//
// Ejemplo para llamada sin headers:
// context.$optlmClient.withOpentelemetry("nombreQueYoQuiera", () => context.$axios.$get(path), {});
// *************************************************************************************************************
// fetchVitalMonitors() {
//   const path = `${baseAPIURL}/wealthabouts/${context.params.wealthaboutid}/vitalmonitors`;
//   return context.$optlmClient.withOpentelemetry("Fetch Vital Monitors", () => context.$axios.$get(path), {});
// },
// *************************************************************************************************************
// Ejemplo para llamada con headers --> Como Opentelemetry met headers en la llamada, NO nos sirve con enviarle la petición a la API
// con los headers, ya que Opentelemetry los sobreescribirá. Por eso, hay que enviar los headers como parámetro de withOpentelemetry.
// context.$optlmClient.withOpentelemetry("nombreQueYoQuiera", () => context.$axios.$get(path), headers);
export default defineNuxtPlugin(nuxtApp => {
  const provider = new WebTracerProvider({
    resource: {
      attributes: {
        "service.name": "client-nuxt"
      }
    }
  });

  // Función para crear un span manual en el cliente
  const createSpan = () => {
    // La URL es la del proxy (SIN puerto, ya el proxy se encarga de reirigirlo al puerto adecuado)
    const url = nuxtApp.$env.OPENTELEMETRY_CLIENT_HOST || "http://localhost:4318";
    const exporter = new OTLPTraceExporter({
      url: `${url}/v1/traces` // Cambia al recolector adecuado
    });
    provider.addSpanProcessor(new BatchSpanProcessor(exporter));
    provider.register();

    // Instrumentación para capturar automáticamente las solicitudes HTTP (fetch y XMLHttpRequest)
    registerInstrumentations({
      instrumentations: [
        new XMLHttpRequestInstrumentation({
          propagateTraceHeaderCorsUrls: [/.*/] // Igual para solicitudes XMLHttpRequest
        })
      ]
    });
    const tracer = trace.getTracer("nuxt-client");

    // Opción para iniciar un span manual en el cliente
    const span = tracer.startSpan("client-initial-span");
    return { span, tracer };
  };

  const withOpentelemetry = (spanName, apiCall, existingHeaders = {}) => {
    // Merge existing headers with trace headers
    const headers = { ...existingHeaders };
    if (!nuxtApp.$env.OPENTELEMETRY_ACTIVE) {
      return apiCall(headers);
    }
    const { span } = createSpan();
    span.setAttribute("URL", spanName);
    const activeContext = trace.setSpan(contextOpentelemetry.active(), span);
    propagation.inject(trace.setSpan(activeContext, span), headers);

    return contextOpentelemetry.with(activeContext, () => {
      return apiCall(headers).finally(() => span.end());
    });
  };

  nuxtApp.provide("optlmClient", {
    withOpentelemetry
  });
});
