import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45guards_45global from "/app/middleware/01.guards.global.js";
import _02_45check_45wealthabout_45is_45loaded_45global from "/app/middleware/02.check-wealthabout-is-loaded.global.js";
import _03_45clean_45query_45global from "/app/middleware/03.clean-query.global.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45guards_45global,
  _02_45check_45wealthabout_45is_45loaded_45global,
  _03_45clean_45query_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sidebase-auth": () => import("/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/sidebase-auth.js")
}