import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68 from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_PBR8qfLHGJZWusxcohvsVMUol70TYsrsUuVtebtW5to from "/app/node_modules/nuxt-highcharts/lib/plugin.js";
import plugin_xp_hzh2CmddJ3Myd_cL2a86QZnSMAE8MuLZ6iluDhtI from "/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import api_T_F9P4Nd3v1LybjikoGjs5HLWkwssZ2wV1ZXWBNlFlE from "/app/assets/logic/api.js";
import tags_NXuu3CKexN6uCzrRc_CfKQsaA70ODDZiB1B8Y_SKq7I from "/app/assets/logic/tags.js";
import helpers_XmVsqG5gBdE7t5cHdSFreO4EoNy7MdDDTPQtVcTAbdI from "/app/assets/logic/helpers.js";
import axios_noAkUduv0vf78s7vht2Hls1EDqDfAXigM3_vcuRZv2s from "/app/plugins/axios.js";
import directives_0EDVG721PRmctY6MUb1e5EKa1aPra7R0g2S1KeEGom0 from "/app/plugins/directives.js";
import env_QQuq0ebJfRLOETbY0kkWCrBkd155MD0inz4mFHGgCUA from "/app/plugins/env.js";
import gsap_client_4O_KCpEZFi9No8UfLrmvzlvCUnQWraJAKw3RsIfcXlg from "/app/plugins/gsap.client.js";
import head_config_client_YP7Ebu1KbI8hQqwKlT37E_Nq3uKJsSF6TXpo_JHWn_I from "/app/plugins/head-config.client.js";
import highchart_client_UdMSFqdTx_ixnU1nsig5L_frWuT73o_3yP9CyOJ42yU from "/app/plugins/highchart.client.js";
import i18n_SMdHjDdqIId1QK07LHAPEyr3HZfMyKcn2rAtnZisyJE from "/app/plugins/i18n.js";
import notify__WyH5UDXqbvbD93pNb9Z0sSBT5qVqs7XUuYkVDT_gOI from "/app/plugins/notify.js";
import opentelemetry_client_mwCwmbpwms3T5pyquQ3erhGs1ofmmNZlFgyoEcgjzdY from "/app/plugins/opentelemetry.client.js";
import session_4mp_z0SaUhaSfTM9sC5JhzNQVAjCh5PjjXoCz76WIIo from "/app/plugins/session.js";
import vue_masonry_client_lfSV52KlrF1H5xYl0rAenAGx6_BT2RQOG0BtabgkvXQ from "/app/plugins/vue-masonry.client.js";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68,
  plugin_PBR8qfLHGJZWusxcohvsVMUol70TYsrsUuVtebtW5to,
  plugin_xp_hzh2CmddJ3Myd_cL2a86QZnSMAE8MuLZ6iluDhtI,
  api_T_F9P4Nd3v1LybjikoGjs5HLWkwssZ2wV1ZXWBNlFlE,
  tags_NXuu3CKexN6uCzrRc_CfKQsaA70ODDZiB1B8Y_SKq7I,
  helpers_XmVsqG5gBdE7t5cHdSFreO4EoNy7MdDDTPQtVcTAbdI,
  axios_noAkUduv0vf78s7vht2Hls1EDqDfAXigM3_vcuRZv2s,
  directives_0EDVG721PRmctY6MUb1e5EKa1aPra7R0g2S1KeEGom0,
  env_QQuq0ebJfRLOETbY0kkWCrBkd155MD0inz4mFHGgCUA,
  gsap_client_4O_KCpEZFi9No8UfLrmvzlvCUnQWraJAKw3RsIfcXlg,
  head_config_client_YP7Ebu1KbI8hQqwKlT37E_Nq3uKJsSF6TXpo_JHWn_I,
  highchart_client_UdMSFqdTx_ixnU1nsig5L_frWuT73o_3yP9CyOJ42yU,
  i18n_SMdHjDdqIId1QK07LHAPEyr3HZfMyKcn2rAtnZisyJE,
  notify__WyH5UDXqbvbD93pNb9Z0sSBT5qVqs7XUuYkVDT_gOI,
  opentelemetry_client_mwCwmbpwms3T5pyquQ3erhGs1ofmmNZlFgyoEcgjzdY,
  session_4mp_z0SaUhaSfTM9sC5JhzNQVAjCh5PjjXoCz76WIIo,
  vue_masonry_client_lfSV52KlrF1H5xYl0rAenAGx6_BT2RQOG0BtabgkvXQ,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]