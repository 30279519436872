import { defineStore } from "pinia";
import { CURRENT_YEAR, getYearFromStrDate } from "@/assets/logic/helpers";
import {
  memberTypes,
  roleTypes,
  userFeatureTypes,
  assetTypes,
  wealthaboutTypes,
} from "@/assets/logic/types";
import { useWizardStore } from "@/stores/wizardStore";
import { useVisibilityStore } from "@/stores/visibilityStore";
import { useNuxtApp } from "nuxt/app";

export const useWealthaboutStore = defineStore("wealthabout", {
  state: () => ({
    selectedYear: CURRENT_YEAR,
    isTodayValue: true,
    selectedBelongTo: null,
    members: null,
    assets: [],
    wealthabouts: null,
    clientId: null,
    currentWealthaboutId: null,
    categoriesExpenses: null,
    client: false,
    userAvailableFeatures: [],
    userBase: "main",
    managerCanAccessClient: true,
    loadingWealthaboutid: null,
    tutorialPhaseSelected: 1,
    authUserData: null,
  }),
  getters: {
    member(state) {
      if (!state.members) return null;
      let mainMember = state.members.find(
        x => x.relationship === memberTypes.OWNER
      );
      if (!mainMember)
        mainMember = state.members.find(
          x => x.relationship === memberTypes.HOLDING
        );
      return mainMember;
    },
    notFutureAssets(state) {
      const nuxt = useNuxtApp();
      return state.assets.filter(
        asset => nuxt.$helpers.toDate(asset.firstDate).getTime() < new Date().getTime()
      );
    },
    family: state =>
      state.members?.find(x => x.relationship === memberTypes.FAMILY) || null,
    portfolio: state =>
      state.assets?.find(x => x.type === assetTypes.FINANCIAL_INSTRUMENT) ||
      null,
    userData: state => {
      if (state.authUserData) return state.authUserData;
      state.authUserData = useAuth().data;
      return state.authUserData;
    },
    isAdmin() {
      return this.userData?.role === roleTypes.ADMIN;
    },
    isManager() {
      return this.userData?.role === roleTypes.MANAGER;
    },
    isClient() {
      return this.userData?.role === roleTypes.CLIENT;
    },
    isHolding() {
      if (!this.currentWealthabout) return false;
      return [
        wealthaboutTypes.holding,
        wealthaboutTypes.holdingSimulation,
        wealthaboutTypes.holdingMain,
      ].includes(this.currentWealthabout.type);
    },
    isMainSimulation() {
      if (!this.currentWealthabout) return false;
      return this.currentWealthabout.type === wealthaboutTypes.simulation;
    },
    isMain() {
      if (!this.currentWealthabout) return false;
      return this.currentWealthabout.type === wealthaboutTypes.main;
    },
    isSimulation() {
      if (!this.currentWealthabout) return false;
      return [
        wealthaboutTypes.simulation,
        wealthaboutTypes.holdingSimulation,
      ].includes(this.currentWealthabout.type);
    },
    isEarlyAdopter() {
      return this.userData?.role === roleTypes.EARLY_ADOPTER;
    },
    isTester(state) {
      return !!state.client?.mock_configuration_id || this.userData?.isTester;
    },
    hasWealthabout() {
      return this.isManager || this.isAdmin || this.userData?.wealthabout_id;
    },
    isBetaVersion() {
      return this.userData?.isBeta || false;
    },
    currentClientId(state) {
      return this.userData?.client_id || state.clientId;
    },
    mainWealthabout(state) {
      return state.wealthabouts?.find(wa => wa.type === wealthaboutTypes.main);
    },
    mainWealthaboutId(state) {
      return (
        this.userData?.wealthabout_id ||
        state.client?.wealthabout_id ||
        this.mainWealthabout.wealthaboutId
      );
    },
    currentWealthabout(state) {
      return (
        state.wealthabouts?.find(
          wa => wa.wealthaboutId === state.currentWealthaboutId
        ) || null
      );
    },
    parentWealthabout(state) {
      return this.currentWealthabout?.parentWealthaboutId
        ? state.wealthabouts?.find(
            wa =>
              wa.wealthaboutId === this.currentWealthabout.parentWealthaboutId
          )
        : null;
    },
    yearsRange() {
      if (this.currentWealthabout) {
        const firstYear = getYearFromStrDate(this.currentWealthabout.firstDate);
        const endYear = getYearFromStrDate(this.currentWealthabout.endDate);
        return Array.from(
          { length: endYear - firstYear + 1 },
          (_, i) => firstYear + i
        );
      }
      return [];
    },
    isExported: state => state.client?.is_exported || false,
    isLinkEntityAvailable: state =>
      state.userAvailableFeatures.includes(userFeatureTypes.LINKED_ENTITIES),
    isOpenfinanceLinkEntityAvailable: state =>
      state.userAvailableFeatures.includes(
        userFeatureTypes.OPENFINANCE_LINKED_ENTITIES
      ),
    isPortfolioSummaryAvailable: state =>
      state.userAvailableFeatures.includes(userFeatureTypes.PORTFOLIO_SUMMARY),
    finalYearOfWealthabout() {
      return this.yearsRange?.[this.yearsRange.length - 1] || null;
    },
    isBaseLite: state => state.userBase === "lite",
    isBaseBasicProjection: state => state.userBase === "basicProjection",
  },
  actions: {
    cleanUser() {
      this.userAvailableFeatures = [];
    },
    async loadWealthabout(params) {
      if (params.wealthaboutid) {
        this.currentWealthaboutId = params.wealthaboutid;
        this.clientId = params.clientid;
        const { fetchViewConfigurator } = useVisibilityStore();

        // Fetch para obtener los datos de la store de Wealthabout
        // Primero se ejecuta esta primera llamada para evitar que todas las llamadas carguen los WA
        await this.fetchWealthabouts();

        const calls = [
          this.fetchCategories(),
          this.updateWealthabout(),
          this.setUserAvailableFeatures(),
          fetchViewConfigurator(),
        ];
        await Promise.all(calls);

        // Usamos la store del wizard y hacemos fetch de los datos de madurez
        const { fetchMaturityData } = useWizardStore();
        fetchMaturityData();

        this.loadingWealthaboutid = null;
      }
    },
    async fetchWealthabouts() {
      const wealthabouts = await useNuxtApp().$api.fetchWealthaboutsMetadata();
      this.wealthabouts = wealthabouts;
    },
    async fetchAndProcessMembers() {
      const nuxt = useNuxtApp();
      const fetchedMembers = await nuxt.$api.fetchMembers();
      const familyMember = fetchedMembers.find(
        member => member.relationship === memberTypes.FAMILY
      );
      const holdingMember = fetchedMembers.find(
        member => member.relationship === memberTypes.HOLDING
      );
      const ownerMember = fetchedMembers.find(
        member => member.relationship === memberTypes.OWNER
      );
      const partnerMember = fetchedMembers.find(
        member => member.relationship === memberTypes.PARTNER
      );
      const childMembers = fetchedMembers
        .filter(member => member.relationship === memberTypes.CHILD)
        .sort(
          (a, b) =>
            nuxt.$helpers.toDate(a.birthdate) -
            nuxt.$helpers.toDate(b.birthdate)
        )
        .map((child, index) => ({ ...child, order: index }));
      const dependentMembers = fetchedMembers
        .filter(member => member.relationship === memberTypes.DEPENDENT)
        .sort(
          (a, b) =>
            nuxt.$helpers.toDate(a.birthdate) -
            nuxt.$helpers.toDate(b.birthdate)
        );
      const members = [
        familyMember,
        holdingMember,
        ownerMember,
        partnerMember,
        ...childMembers,
        ...dependentMembers,
      ].filter(Boolean);
      this.members = members;
    },
    async fetchAndProcessAssets() {
      const assets = await useNuxtApp().$api.fetchAssets();
      assets.sort(sortAssets);
      this.assets = assets;
    },
    async updateWealthabout() {
      await Promise.all([
        this.fetchAndProcessMembers(),
        this.fetchAndProcessAssets(),
      ]);
    },
    async fetchCategories() {
      if (!this.categoriesExpenses) {
        const categoriesExpenses = await useNuxtApp().$api.fetchCategories();
        this.categoriesExpenses = categoriesExpenses;
      }
    },
    async setUserAvailableFeatures() {
      const userFeatures = await useNuxtApp().$api.fetchUserFeatures();
      this.userAvailableFeatures = userFeatures.features;
      this.userBase = userFeatures.base;
    },
    async setClientId(value) {
      if (!this.client) {
        const client = await useNuxtApp().$api.fetchClient(value);
        this.client = client.data;
        this.setManagerCanAccessClient(!client.data.is_exported);
      }
      this.clientId = value;
    },
    setClient(value) {
      if (!this.client) {
        this.client = value;
        this.setManagerCanAccessClient(!value.is_exported);
      }
      this.clientId = value.client_id;
    },
    cleanClient() {
      this.selectedYear = CURRENT_YEAR;
      this.members = null;
      this.assets = [];
      this.wealthabouts = null;
      this.clientId = null;
      this.client = null;
      this.currentWealthaboutId = null;
      this.loadingWealthaboutid = null;
    },
    setManagerCanAccessClient(value) {
      this.managerCanAccessClient = value;
    },
    redirectToLoadingWealthabout({
      route,
      wealthaboutId,
      clientId,
      originUrl = null,
      showTutorial = false,
    }) {
      this.loadingWealthaboutid = wealthaboutId;
      return route({
        name: "clients-clientid-wealthabouts-wealthaboutid-loading",
        params: { clientid: clientId, wealthaboutid: wealthaboutId },
        query: {
          origin: originUrl,
          loading: true,
          showTutorial: showTutorial || undefined,
        },
      });
    },
    async redirectToAvailableClient({ navigateTo, to }) {
      const originUrl = to.query.origin || to.fullPath;
      return this.redirectToLoadingWealthabout({
        route: navigateTo,
        clientId: to.params.clientid,
        wealthaboutId: to.params.wealthaboutid,
        originUrl,
      });
    },
    setAuthUserData(data) {
      this.authUserData = data;
    },
  },
});

function sortAssets(a, b) {
  const getValue = asset =>
    asset.assetValue?.[CURRENT_YEAR] ?? asset.value[CURRENT_YEAR];
  const conditions = [
    // º1 Grupo propiedad vivienda habitual
    {
      grup: x => x.type === assetTypes.REAL_ESTATE && x.isHabitualLiving,
      sort: (a, b) => getValue(b) - getValue(a),
    },
    // º2 Grupo el resto de viviendas
    {
      grup: x => x.type === assetTypes.REAL_ESTATE,
      sort: (a, b) => getValue(b) - getValue(a),
    },
  ];
  for (const condition of conditions) {
    if (condition.grup(a) && !condition.grup(b)) return -1;
    if (!condition.grup(a) && condition.grup(b)) return 1;
    if (condition.grup(a) && condition.grup(b)) return condition.sort(a, b);
  }

  // Los demas assets se ordenan por valor
  return getValue(b) - getValue(a);
}
