
// @ts-nocheck


export const localeCodes =  [
  "es",
  "es-business"
]

export const localeLoaders = {
  es: [
    {
      key: "locale_es_46json_37bbce7d",
      load: () => import("#nuxt-i18n/37bbce7d" /* webpackChunkName: "locale_es_46json_37bbce7d" */),
      cache: true
    }
  ],
  "es-business": [
    {
      key: "locale_es_45business_46json_9fb2bd25",
      load: () => import("#nuxt-i18n/9fb2bd25" /* webpackChunkName: "locale_es_45business_46json_9fb2bd25" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "es",
      name: "Español",
      files: [
        "/app/i18n/locales/es.json"
      ]
    },
    {
      code: "es-business",
      name: "Español formal",
      files: [
        "/app/i18n/locales/es-business.json"
      ]
    }
  ],
  defaultLocale: "es",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: true,
  langDir: "locales/",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  legacy: false,
  globalInjection: true,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "es",
    name: "Español",
    files: [
      {
        path: "/app/i18n/locales/es.json",
        cache: undefined
      }
    ]
  },
  {
    code: "es-business",
    name: "Español formal",
    files: [
      {
        path: "/app/i18n/locales/es-business.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/