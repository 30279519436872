import { useNotifications } from "~/composable/useNotifications";

export default defineNuxtPlugin(() => {
  const { addNotification } = useNotifications();

  /**
   * $notify: Método global para añadir notificaciones a la aplicación.
   *
   * Uso:
   * this.$notify({
   *   type: 'success', // Tipo de notificación ('info', 'success', 'error', 'warning'). Por defecto: 'info'
   *   title: 'Título personalizado', // Título de la notificación (opcional)
   *   text: 'Mensaje a mostrar en la notificación', // Texto de la notificación
   *   closable: true, // Define si la notificación se puede cerrar manualmente (opcional, por defecto: true)
   *   duration: 5000, // Duración de la notificación en milisegundos antes de desaparecer automáticamente. Si closable es true, por defecto no se define duración.
   *   group: 'global', // Grupo al que pertenece la notificación. Por defecto: 'global'
   *   action: { text: 'Acción', onAction: () => console.log('Acción ejecutada') }, // Define una acción adicionales para la notificación (opcional)
   * });
   *
   * Nota importante:
   * Para que las notificaciones aparezcan visualmente en la interfaz de usuario, es necesario incluir el componente `<AppNotification>` en el layout o en la página correspondiente.
   * Este componente se encargará de mostrar las notificaciones que se añadan con `$notify`.
   *
   * Ejemplo de uso del componente en un layout o página:
   *
   * <AppNotification group="global" />
   *
   * El prop `group` se utiliza para especificar a qué grupo de notificaciones pertenece. Si no se especifica un grupo, se asignará al grupo 'global' por defecto.
   *
   * Si se necesita manejar diferentes grupos de notificaciones, se pueden definir varios componentes `<AppNotification>` con diferentes valores para el prop `group`.
   *
   * Ejemplo:
   * <AppNotification group="login" />
   * <AppNotification group="profile" />
   */
  const notify = params => {
    // Agregar la notificación utilizando el composable
    addNotification(params);
  };

  return {
    provide: {
      notify
    }
  };
});
