import { useWealthaboutStore } from "@/stores/wealthaboutStore";
import { navigateTo } from "nuxt/app";

// export default async function ({ route, redirect }) {
export default defineNuxtRouteMiddleware(async (to, from) => {
  // Si se esta cargando el wealthabout del params ignoramos la redirection, para evitar bucles infinitos
  const isLoading = !!to.query.loading;
  if (isLoading && to.name.includes("-loading")) return;
  const wealthaboutStore = useWealthaboutStore();
  // Comprobamos que el wealthabout cargado sea distinto al de la url
  if (to.params.wealthaboutid && wealthaboutStore.currentWealthaboutId !== to.params.wealthaboutid) {
    return await wealthaboutStore.redirectToAvailableClient({ navigateTo, to });
  }
  return;
});
