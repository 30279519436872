import members from "@/assets/logic/wizard/member.js";
import property from "@/assets/logic/wizard/property.js";
import shares from "@/assets/logic/wizard/shares.js";
import empty from "@/assets/logic/wizard/empty.js";
import documents from "@/assets/logic/wizard/ghostDocuments.js";

// JSON con los diferentes wizards con los datos y pasos a seguir
export const wizards = {
  ...members,
  ...property,
  ...shares,
  ...empty,
  ...documents
};
