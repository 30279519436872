export default defineNuxtPlugin(async nuxtApp => {
  // Realiza una solicitud al endpoint
  let envVars = {};
  if (import.meta.client) {
    envVars = await $fetch("/api/env");
  } else {
    envVars = await process.env;
  }
  // Proporciona las variables al resto de la aplicación
  nuxtApp.provide("env", envVars);
});
