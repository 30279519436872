import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import updateLocale from "dayjs/plugin/updateLocale";
import { custom } from "@/assets/logic/customize.js";
import {
  memberTypes,
  assetTypes,
  portfolioTypes,
  inventoryTypes,
  investmentType,
  monitorTypes,
  wizardEmptyTypes,
} from "@/assets/logic/types.js";
import { useWealthaboutStore } from "@/stores/wealthaboutStore";

dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
const inputFormat = "DD/MM/YYYY";
const monthsES = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];
dayjs.updateLocale("en", { months: monthsES });

export const CURRENT_DATE = () => new Date();
export const CURRENT_YEAR = CURRENT_DATE().getFullYear();

const orderList = function (arr, key, asc = false) {
  return arr.sort((a, b) => {
    let result = 0;
    const firstElement =
      typeof a[key] === "string" || a[key] instanceof String
        ? a[key].toLowerCase()
        : a[key];
    const secondElement =
      typeof b[key] === "string" || b[key] instanceof String
        ? b[key].toLowerCase()
        : b[key];
    if (
      firstElement !== null &&
      firstElement !== undefined &&
      (secondElement === null || secondElement === undefined)
    )
      return -1;
    if (
      secondElement !== null &&
      secondElement !== undefined &&
      (firstElement === null || firstElement === undefined)
    )
      return 1;
    if (firstElement < secondElement) {
      result = asc ? -1 : 1;
    } else if (firstElement > secondElement) {
      result = asc ? 1 : -1;
    }
    return result;
  });
};

const deepCopy = function (obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  const clone = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach(key => {
    clone[key] = deepCopy(obj[key]);
  });

  return clone;
};
// Formateo de fechas
// Transforma las fechas string (enviadas por el back) a Date. Maneja diferentes formatos.
const toDate = function (value) {
  if (!value || typeof value !== "string") {
    return null;
  }

  // Lista de formatos de fecha que se desean soportar
  const formats = [
    "YYYY-MM-DD",
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "YYYY-MM-DD HH:mm:ss",
    "DD-MM-YYYY HH:mm:ss",
    "DD/MM/YYYY HH:mm:ss",
  ];

  // Intentar parsear la fecha con cada formato hasta encontrar uno que coincida
  for (const format of formats) {
    const parsedDate = dayjs(value, format, true);
    if (parsedDate.isValid()) {
      return parsedDate.toDate();
    }
  }

  return null;
};
// Transforma un año, ya sea string o number a Date. Se utiliza para el appInputDate para las props (minDate, maxDate) cuando es de tipo año solo.
const yearToDate = function (value) {
  let year;

  if (typeof value === "string") {
    year = parseInt(value, 10);
  } else if (typeof value === "number") {
    year = value;
  } else {
    return null;
  }
  // Verificar si el año es válido
  if (isNaN(year) || year < 1 || year > 9999) {
    return null;
  }
  return new Date(year, 0, 1);
};
// String MM/YYYY -> Date
const monthYearToDate = function (value) {
  const [month, year] = value.split("/");
  return new Date(year + "-" + month + "-" + "01T20:00:00Z");
};
// Obtiene el año a partir de una fecha en String
const getYearFromStrDate = function (value) {
  const date = toDate(value);
  return date ? date.getFullYear() : null;
};
// Te permite transformar cualquier Date en String con el formato que le des. Se limita el uso en casos excepcionales
const date = function (value, format = "DD MMM YYYY") {
  if (!value) return null;
  const date =
    typeof value === "string" ? dayjs(value, inputFormat) : dayjs(value);
  return date.isValid() ? date.format(format) : null;
};
// Te permite transformar cualquier Date en String con el formato "dd/mm/yyy", se utiliza sustituyendo el date para no tener que poner le formato.
// Toda fecha que se envie por la api tiene que transformase con este metodo
const dateToApi = function (value) {
  return date(value, inputFormat);
};
// Te permite transformar cualquier Date en String (con el formato "dd mmm yyy" por defecto),  se utiliza sustituyendo el date para no tener que poner le formato.
// Toda fecha que se quiera mostrar al usuario tiene que transformarse con este metodo
const dateToTemplate = function (value, format = "DD MMM YYYY") {
  return date(value, format);
};
const dateMonthToTemplate = function (value) {
  return date(value, "MMM YY");
};
const dateToYear = function (value) {
  return value.getFullYear();
};
const isValidDate = function (date) {
  return date instanceof Date && !isNaN(date.getTime());
};
const isEqualsDate = function (date1, date2) {
  return (
    isValidDate(date1) &&
    isValidDate(date2) &&
    date1.getTime() === date2.getTime()
  );
};
const formatVueDatePickerToDate = function (value, type) {
  if (type === datePickerTypes.month.id) {
    return new Date(value.year, value.month, 1);
  } else if (type === datePickerTypes.year.id) {
    return new Date(value, 0, 1);
  }
  return value;
};
const dateToFormatVueDatePicker = function (date, type) {
  let value = date;
  if (isValidDate(value)) {
    if (type === datePickerTypes.month.id) {
      return { year: value.getFullYear(), month: value.getMonth() };
    } else if (type === datePickerTypes.year.id) {
      return value.getFullYear();
    }
    return value;
  }
};

const animateValue = function (start, end, duration, stepFunction, callback) {
  let startTime = null;

  const animation = currentTime => {
    if (!startTime) startTime = currentTime;

    const timeElapsed = currentTime - startTime;
    const progress = timeElapsed / duration;

    if (progress < 1) {
      const currentValue = start + (end - start) * progress;
      stepFunction(currentValue);
      requestAnimationFrame(animation);
    } else {
      stepFunction(end);
      if (callback) callback();
    }
  };

  requestAnimationFrame(animation);
};
const capitalize = function (data) {
  return data.charAt(0).toUpperCase() + data.toLowerCase().slice(1);
};
const formatCurrencyWithScaling = (num, currency = "$", truncate = false) => {
  let formattedNumber;

  // Check if the number is an integer or a float
  const isInteger = Number.isInteger(num);

  // Variable to store the suffix (if any)
  let suffix = "";

  // Format the number depending on whether it is an integer or a float
  if (!isInteger && !truncate) {
    // For float numbers, use a comma as the decimal separator and keep only significant decimals
    formattedNumber = parseFloat(num.toFixed(2)).toString().replace(".", ",");
  } else if (num >= 1000000) {
    formattedNumber = (num / 1000000).toString().replace(".", ",");
    suffix = "M";
  } else if (num >= 1000) {
    formattedNumber = (num / 1000).toString().replace(".", ",");
    suffix = "k";
  } else {
    formattedNumber = num.toString();
  }

  if (truncate) {
    formattedNumber = formattedNumber.split(",")[0];
  }

  // Replace dots with spaces to separate thousands and millions, if necessary
  formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Add the suffix (if any) and the currency symbol
  return formattedNumber + suffix + " " + currency;
};
const number = (
  value,
  decimals = 0,
  ignoreZeroDecimals = false,
  thousandsSep = ".",
  decimalsSep = ","
) => {
  const rounded = Math.round(value * 10 ** decimals) / 10 ** decimals;
  let [integer, decimal] = rounded.toFixed(decimals).split(".");
  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep);
  if (ignoreZeroDecimals && decimal) decimal = decimal.replace(/0+$/, "");
  return decimal ? [integer, decimal].join(decimalsSep) : integer;
};
const squareMeters = (value, decimals = 0, nullValue = null) => {
  if (
    (value === null || value === undefined || value === "") &&
    nullValue !== null
  )
    return nullValue;
  return `${number(value, decimals)} m²`;
};
const percentFloat = (value, decimals = 2) => {
  const rounded = value * 100;
  return parseFloat(Math.round(rounded * 10 ** decimals) / 10 ** decimals);
};
const percent = (
  value,
  decimals = 2,
  showPercentSymbol = false,
  nullValue = null,
  ignoreZeroDecimals = false
) => {
  if (
    (value === null || value === undefined || value === "") &&
    nullValue !== null
  )
    return nullValue;
  const rounded = number(
    percentFloat(value, decimals),
    decimals,
    ignoreZeroDecimals
  );
  return showPercentSymbol ? `${rounded} %` : rounded;
};

const downloadBlob = async function (blob, fileName, fileType) {
  try {
    const fileBlob = new Blob([blob], { type: "application/" + fileType });

    // Create a URL for the blob
    const url = URL.createObjectURL(fileBlob);

    // Create a link element in the DOM
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName || "documento." + fileType; // Default file name
    document.body.appendChild(link); // Required for Safari

    // Simulate a click to trigger the download
    link.click();

    // Clean up the DOM and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (e) {
    return "No se ha encontrado el documento";
  }
};

export default defineNuxtPlugin(nuxtApp => {
  const wealthaboutStore = useWealthaboutStore();
  return {
    provide: {
      helpers: {
        orderList,
        deepCopy,
        toDate,
        yearToDate,
        monthYearToDate,
        getYearFromStrDate,
        date,
        dateToApi,
        dateToTemplate,
        dateMonthToTemplate,
        dateToYear,
        isEqualsDate,
        dateToFormatVueDatePicker,
        formatVueDatePickerToDate,
        isValidDate,
        capitalize,
        formatCurrencyWithScaling,
        number,
        squareMeters,
        percentFloat,
        percent,
        downloadBlob,
        currency(
          value,
          decimals = 2,
          ignoreZeroDecimals = false,
          currency = "€",
          nullValue = null
        ) {
          if (
            (value === null || value === undefined || value === "") &&
            nullValue !== null
          )
            return nullValue;
          return `${this.number(value, decimals, ignoreZeroDecimals)} ${currency}`;
        },
        currencyWithoutSymbol(
          value = 0,
          decimals = 2,
          ignoreZeroDecimals = false
        ) {
          return `${this.number(value, decimals, ignoreZeroDecimals)}`;
        },
        uppercase(data) {
          return data.toUpperCase();
        },
        empty(data) {
          return data || data === 0 ? data : "";
        },
        linearMap(x, inMin, inMax, outMin, outMax) {
          return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
        },
        ellipsis(str) {
          const NUMBER_OF_DOTS = 3;
          const NUMBER_OF_LETTER_FROM_BEGGINING = 12;
          const NUMBER_OF_LETTER_FROM_END = 10;
          const MAX_ALLOWED_LENGTH =
            NUMBER_OF_LETTER_FROM_BEGGINING +
            NUMBER_OF_DOTS +
            NUMBER_OF_LETTER_FROM_END;
          if (str.length > MAX_ALLOWED_LENGTH)
            return `${str.substr(0, NUMBER_OF_LETTER_FROM_BEGGINING)}...${str.substr(str.length - NUMBER_OF_LETTER_FROM_END, str.length)}`;
          else return str;
        },
        groupBy(array, key) {
          return array.reduce((acc, obj) => {
            const property = obj[key];
            acc[property] = acc[property] || [];
            acc[property].push(obj);
            return acc;
          }, {});
        },
        setCookie(cookieName, cookieValue, expDays) {
          const date = new Date();
          date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
          const expires = "expires=" + date.toUTCString();
          document.cookie =
            cookieName + "=" + cookieValue + "; " + expires + "; path=/";
        },
        getCookie(cookieName) {
          const name = cookieName + "=";
          const cDecoded = decodeURIComponent(document.cookie);
          const cArr = cDecoded.split("; ");
          let res;
          cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length);
          });
          return res;
        },
        getAge(dateString) {
          const today = new Date();
          const dateParts = dateString.split("/");
          const birthDate = new Date(
            +dateParts[2],
            dateParts[1] - 1,
            +dateParts[0]
          );
          let age = today.getFullYear() - birthDate.getFullYear();
          const m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          return age;
        },
        getAgeText(dateString) {
          const age = this.getAge(dateString);
          return age === 1 ? age + " año" : age + " años";
        },
        parseJwt(token) {
          let jsonPayload;
          // procces.client es una propiedad que provee nuxt para saber si el código se ejecuta en el cliente
          if (import.meta.client) {
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            jsonPayload = decodeURIComponent(
              window
                .atob(base64)
                .split("")
                .map(function (c) {
                  return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
            );
          } else {
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            jsonPayload = Buffer.from(base64, "base64").toString("utf-8");
          }

          return JSON.parse(jsonPayload);
        },
        tokenHasExpired(token) {
          if (!token) return true;

          const tokenDecoded = this.parseJwt(token);
          return Date.now() > new Date(tokenDecoded.exp * 1000);
        },
        getCurrentDate() {
          return CURRENT_DATE();
        },
        getCurrentYear() {
          return CURRENT_YEAR;
        },
        getLastDateKey(dateValueObject) {
          let maxDate = null;
          for (const key in dateValueObject) {
            const date = toDate(key);
            if (date && (maxDate === null || date > maxDate)) {
              maxDate = date;
            }
          }
          return maxDate ? dateToApi(maxDate) : null;
        },
        isFutureMember(member) {
          let date = member.familyIncorporationDate
            ? member.familyIncorporationDate
            : member.birthdate;
          date = new Date(date.split("/").reverse());
          date.setHours(0, 0, 0, 0);
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return date > today;
        },
        isFutureAsset(asset) {
          const today = new Date();
          return toDate(asset.firstDate).getTime() > today.getTime();
        },
        copyToClipboard(text) {
          if (!navigator.clipboard) {
            return this.fallbackCopyToClipboard(text);
          } else {
            return navigator.clipboard.writeText(text);
          }
        },
        fallbackCopyToClipboard(text) {
          return new Promise((resolve, reject) => {
            const textarea = document.createElement("textarea");
            textarea.value = text;
            textarea.style.position = "fixed";
            textarea.style.display = "none";
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();

            try {
              const successful = document.execCommand("copy");
              if (successful) {
                resolve(successful);
              } else {
                reject(new Error("No se ha podido copiar"));
              }
            } catch (err) {
              reject(err);
            }

            document.body.removeChild(textarea);
          });
        },
        getResourcesOptions(resources) {
          const all = [...wealthaboutStore.members, ...wealthaboutStore.assets];
          return all
            .map(resource => {
              if (resources.includes(resource.id))
                return { id: resource.id, value: resource.alias };
              return false;
            })
            .filter(Boolean);
        },
        castSeriesToValue(obj, year) {
          // Si no es un objeto o es null, devolver el valor tal cual
          if (typeof obj !== "object" || obj === null) {
            return obj;
          }

          // Si es un array, procesar cada elemento del array
          if (Array.isArray(obj)) {
            return obj.map(item => this.castSeriesToValue(item, year));
          }

          // Si el objeto tiene la propiedad del año especificado, devolver su valor
          if (obj[year] !== undefined) {
            return obj[year];
          }

          // Si el objeto está vacío, devolverlo tal cual (o undefined según prefieras)
          if (Object.keys(obj).length === 0) {
            return obj; // O puedes devolver undefined si lo prefieres
          }

          // Si ninguna de las condiciones anteriores se cumple, procesar recursivamente sus propiedades
          const result = {};
          for (const [key, value] of Object.entries(obj)) {
            const processedValue = this.castSeriesToValue(value, year);
            // Solo agregar al resultado si el valor procesado no es undefined
            if (processedValue !== undefined) {
              result[key] = processedValue;
            }
          }
          return result;
        },
        focusNextElement(reverse = false) {
          // Obtener todos los elementos enfocables
          const focusableElements =
            'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select, [tabindex]:not([tabindex="-1"])';
          const elements = Array.from(
            document.querySelectorAll(focusableElements)
          );

          // Determinar el elemento actualmente enfocado
          const currentIndex = elements.indexOf(document.activeElement);

          // Determinar el índice del próximo elemento a enfocar
          let nextIndex = reverse ? currentIndex - 1 : currentIndex + 1;

          // Si se llega al final (o al inicio si se está invirtiendo), volver al inicio (o al final)
          if (nextIndex >= elements.length) {
            nextIndex = 0;
          } else if (nextIndex < 0) {
            nextIndex = elements.length - 1;
          }
          // Enfocar el elemento
          elements[nextIndex].focus();
        },
        simulateTabPress(reverse = false) {
          // Crear el evento
          const keyboardEvent = new KeyboardEvent("keydown", {
            key: "Tab",
            code: "Tab",
            which: 9, // Código de tecla para "Tab"
            bubbles: true,
            cancelable: true,
            shiftKey: reverse, // Si es true, simula Shift+Tab
          });

          // Establecer una propiedad para saber que es una simulación
          document.activeElement.isSimulatedTab = true;
          const beforeTrap = document.activeElement;
          // Enviar el evento al elemento activo (para que funcione directiva focus-trap)
          document.activeElement.dispatchEvent(keyboardEvent);

          // Si ha saltado el trap no pasamos al siguiente
          if (beforeTrap === document.activeElement) {
            // Enfocar el siguiente elemento
            this.focusNextElement(reverse);
          }
        },
        animateValue,
        getResourcePageByInventoryItem(item, monitorType) {
          if (
            [
              inventoryTypes.MEMBERS,
              inventoryTypes.PROPERTIES,
              inventoryTypes.OTHERS,
              inventoryTypes.EXTRAORDINARY,
              inventoryTypes.MERCANTILE,
              inventoryTypes.OTHER_ASSET,
            ].includes(item.inventoryType)
          ) {
            if (
              monitorType === monitorTypes.EARN ||
              monitorType === monitorTypes.SPEND
            ) {
              const possessorId =
                item.possessorIds.length > 0 ? item.possessorIds[0] : undefined;
              return possessorId;
            }
            return item.id;
          }
          // Si es un item asociado a una deuda devolvemos el possessorId de la deuda si hay mas de uno el id de la familia
          if (
            [
              inventoryTypes.CREDIT,
              inventoryTypes.MORTGAGE,
              inventoryTypes.REDEEM_DEBT,
            ].includes(item.inventoryType)
          ) {
            return item.possessorIds.length === 1
              ? item.possessorIds[0]
              : wealthaboutStore.family.id;
          }
          // Si es una cuenta devolvemos el entityId de la cuenta
          if (
            [
              inventoryTypes.ACCOUNTS_DEPOSITS,
              inventoryTypes.SECURITIES_ACCOUNT,
            ].includes(item.inventoryType)
          ) {
            if (item.type === investmentType.DEPOSIT)
              return item.possessorIds[0];
            return item.id;
          }
          // Si es una inversion o un plan de pensiones devolvemos el id del possessorIds, lo mismo para revalorizacion
          if (
            [
              inventoryTypes.MEMBERS,
              inventoryTypes.INVESTMENTS,
              inventoryTypes.PENSION_PLANS,
              inventoryTypes.REVALORIZATION,
              inventoryTypes.LABOUR,
              inventoryTypes.RETIREMENT,
            ].includes(item.inventoryType)
          ) {
            return item.possessorIds[0];
          }
        },
        getNotificationRouter(
          notificationLink,
          wealthaboutid = useRoute().params.wealthaboutid
        ) {
          if (!notificationLink)
            return {
              name: "clients-clientid-wealthabouts-wealthaboutid",
              params: { wealthaboutid },
            };

          const wizardEmptyTypesValues = Object.values(wizardEmptyTypes);
          if (wizardEmptyTypesValues.includes(notificationLink)) {
            let type = null;
            if (notificationLink === wizardEmptyTypes[assetTypes.OTHER])
              type = assetTypes.OTHER;
            else if (
              notificationLink === wizardEmptyTypes[assetTypes.PRIVATE_SHARES]
            )
              type = assetTypes.PRIVATE_SHARES;
            return {
              name:
                "clients-clientid-wealthabouts-wealthaboutid-assets-distributor-" +
                custom.common.customization[type].route,
              params: { wealthaboutid },
            };
          } else {
            return this.getRouter(notificationLink);
          }
        },
        getRouter(
          resourceId,
          formId,
          wealthaboutid = useRoute().params.wealthaboutid
        ) {
          const common = custom.common;
          const resource = this.getResource(resourceId);
          if (!resource) return null;
          // Si es un miembro
          if (resource.relationship) {
            if (
              resource.relationship === memberTypes.FAMILY ||
              resource.relationship === memberTypes.HOLDING
            ) {
              return {
                name:
                  "clients-clientid-wealthabouts-wealthaboutid-" +
                  common.customization[resource.relationship].route,
                params: { wealthaboutid },
              };
            } else {
              return {
                name: "clients-clientid-wealthabouts-wealthaboutid-members-memberid",
                params: {
                  clientid: useRoute().params.clientid,
                  wealthaboutid,
                  memberid: resourceId,
                },
              };
            }
            // Si es un asset
          } else {
            if (resource.firstYear > new Date().getFullYear()) {
              return {
                name:
                  "clients-clientid-wealthabouts-wealthaboutid-assets-distributor-" +
                  common.customization[resource.type].route,
                params: { wealthaboutid },
              };
            } else if (resource.type === assetTypes.FINANCIAL_INSTRUMENT) {
              return {
                name: "clients-clientid-wealthabouts-wealthaboutid-portfolio",
                params: { wealthaboutid },
              };
            } else if (
              [
                portfolioTypes.CURRENT_ACCOUNT,
                portfolioTypes.SECURITIES_ACCOUNT,
              ].includes(resource.type)
            ) {
              const accountTypeRoute =
                resource.type === portfolioTypes.CURRENT_ACCOUNT
                  ? "movements"
                  : "investments";
              return {
                name: `clients-clientid-wealthabouts-wealthaboutid-portfolio-bankassetid-${accountTypeRoute}`,
                params: { wealthaboutid, bankassetid: resourceId },
                query:
                  resource.type === portfolioTypes.CURRENT_ACCOUNT
                    ? {}
                    : { openForm: `["investmentId", "${formId}"]` },
              };
            }
            return {
              name: "clients-clientid-wealthabouts-wealthaboutid-assets-assetid",
              params: { wealthaboutid, assetid: resourceId },
            };
          }
        },
        getRelationship(member) {
          // Devuelve la relación de un miembro,
          // Para los child devuelve la relación y un numérico entre 1 y 6 (ya que son los custom que manejamos para childs)
          if (member.relationship !== memberTypes.CHILD)
            return member.relationship;
          const memberFind = wealthaboutStore.members.find(
            x => x.id === member.id
          );
          return memberTypes.CHILD + ((memberFind.order % 6) + 1);
        },
        getResource(resourceId) {
          const portfolio = wealthaboutStore.assets.find(
            asset => asset.type === assetTypes.FINANCIAL_INSTRUMENT
          );
          const portfolioAsset = portfolio?.assets;
          const portfolioEntities = Object.entries(portfolio?.entities).map(
            ([key, value]) => ({
              id: key,
              type: assetTypes.FINANCIAL_INSTRUMENT,
              ...value,
            })
          );
          const all = [
            ...wealthaboutStore.members,
            ...wealthaboutStore.assets,
            ...Object.entries(portfolioAsset).map(([key, value]) => ({
              id: key,
              ...value,
            })),
            ...portfolioEntities,
          ];
          return all.find(r => r.id === resourceId);
        },
      },
    },
  };
});
const datePickerTypes = {
  year: {
    id: "year",
    mask: {
      focus: "####",
      blur: "####",
    },
    format: {
      focus: "yyyy",
      blur: "yyyy",
    },
    regex: /^\d{4}$/,
    getDate: yearToDate,
  },
  month: {
    id: "month",
    mask: {
      focus: "##/####",
      blur: "@@@ ####",
    },
    format: {
      focus: "MM/yyyy",
      blur: "MMM yyyy",
    },
    regex: /^(0[1-9]|1[0-2])\/\d{4}$/,
    getDate: monthYearToDate,
  },
  date: {
    id: "date",
    mask: {
      focus: "##/##/####",
      blur: "## @@@ ####",
    },
    format: {
      focus: "dd/MM/yyyy",
      blur: "dd MMM yyyy",
    },
    regex: /^\d{2}\/\d{2}\/\d{4}$/,
    getDate: toDate,
  },
};

export {
  orderList,
  deepCopy,
  toDate,
  yearToDate,
  monthYearToDate,
  getYearFromStrDate,
  date,
  dateToApi,
  dateToTemplate,
  dateToYear,
  isEqualsDate,
  isValidDate,
  capitalize,
  squareMeters,
  animateValue,
  formatCurrencyWithScaling,
  dateMonthToTemplate,
  datePickerTypes,
  dateToFormatVueDatePicker,
  formatVueDatePickerToDate,
  percent,
  downloadBlob,
};
