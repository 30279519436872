import { defineStore } from "pinia";

export const useVisibilityStore = defineStore("visibility", {
  state: () => ({
    settingsVisibility: {},
    distributorListMode: false,
  }),

  getters: {
    visibility(state) {
      return state.settingsVisibility || {};
    },
  },

  actions: {
    async fetchViewConfigurator() {
      const nuxt = useNuxtApp();
      // Verifica las condiciones para evitar Lite y BasicProjection
      if (!nuxt.$tags.isLiteOnly() && !nuxt.$tags.isBasicProjectionOnly()) {
        try {
          const response = await nuxt.$api.fetchVisibilitySettings();
          this.SET_VISIBILITY(response.visibility);
        } catch (error) {
          console.error(
            "Error al obtener configuraciones de visibilidad:",
            error
          );
        }
      }
    },

    async patchVisibility({ resourceId, listCardsDisabled }) {
      try {
        await useNuxtApp().$api.visibilitySettingsUpdate(
          resourceId,
          listCardsDisabled
        );
        await this.fetchViewConfigurator();
      } catch (error) {
        console.error("Error al actualizar la visibilidad del miembro:", error);
      }
    },

    SET_VISIBILITY(visibility) {
      // Actualiza el estado si la visibilidad tiene datos válidos
      if (visibility) {
        this.settingsVisibility = visibility;
      }
    },

    toggleDistributorListMode() {
      this.distributorListMode = !this.distributorListMode;
    },
  },
});
