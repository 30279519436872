import { defineStore } from "pinia";
import { wizards } from "@/assets/logic/wizard/index";

const wizardsData = wizards;

export const useWizardStore = defineStore("wizardStore", {
  state: () => ({
    maturityProgress: 0,
    maturityLevel: "Principiante",
    warnedItems: {},
    emptyItemTypes: [],
    currentSteps: null,
    currentStepIndex: null,
    clipPath: "",
    currentField: "",
    currentAlertPosition: null,
    currentIdResource: "",
    currentResourceType: "",
    currentType: "",
    currentIndexAlert: 0,
    currentWizardFields: [],
    modalPositionState: null,
    hiddenModal: false,
  }),

  getters: {
    warnedMembers: state => state.warnedItems.members || {},
    warnedAssets: state => state.warnedItems.assets || {},
    listWarnedActive: state => {
      const listWarnedResources = Object.entries(state.warnedItems).reduce(
        (pre, [, list]) => {
          const listSections = Object.entries(list);
          const idField = listSections.reduce((prev, [id, fields]) => {
            if (fields.length === 0) {
              return [...prev];
            } else {
              return [...prev, ...fields.map(field => id + field), id];
            }
          }, []);
          return [...pre, ...idField];
        },
        []
      );
      const emptyItems = state.emptyItemTypes.reduce((prev, curr) => {
        return [...prev, curr, curr + curr];
      }, []);
      return [...listWarnedResources, ...emptyItems];
    },
    currentStep: state =>
      state.currentSteps && state.currentStepIndex !== null
        ? state.currentSteps[state.currentStepIndex]
        : null,
    currentHighlightElement(state) {
      const template = this.currentDataTemplate;
      if (template?.isDynamicHighlight) {
        return [state.currentIdResource, template?.highlightElement].join("-");
      }
      return template?.highlightElement || "none";
    },
    currentIdWizard: state => state.currentIdResource + state.currentField,
    currentIdField: state =>
      state.currentWizardFields.length > 0
        ? state.currentWizardFields[state.currentIndexAlert]
        : "",
    currentDataField(state) {
      return state.currentWizardFields.length > 0
        ? wizardsData[this.currentIdField]
        : null;
    },
    currentDataTemplate(state) {
      if (this.currentStep) {
        return { title: this.currentDataField.title, ...this.currentStep };
      } else if (this.currentDataField) {
        return {
          ...this.currentDataField,
          numberFields: state.currentWizardFields.length,
        };
      }
      return null;
    },
    modalPosition: state => {
      const position = state.currentHighlightElement
        ? state.modalPositionState
        : state.currentAlertPosition;
      return position || { top: 0, left: 0 };
    },
  },

  actions: {
    setWizardData(maturityData) {
      this.maturityProgress = maturityData.progress * 100;
      this.maturityLevel = maturityData.level;
      this.warnedItems = maturityData.data.warnedItems;
      this.emptyItemTypes = maturityData.data.emptyItemTypes;
    },
    setCurrentSteps(wizard) {
      // if (location) location.hash = wizard.;
      this.currentSteps = wizard;
      this.currentStepIndex = 0;
    },
    setClipPath(clipPath) {
      this.clipPath = clipPath;
    },
    setCurrentWizardFields(currentWizardFields) {
      this.currentWizardFields = currentWizardFields;
    },
    setCurrentType(type) {
      this.currentType = type;
    },
    setModalPositionAndSize(position) {
      this.currentAlertPosition = position;
      this.modalPositionState = position || { width: 1, height: 1 };
    },
    setCurrentIndexAlert(index) {
      if (index < 0 && this.currentWizardFields.length > 0) {
        this.currentIndexAlert = this.currentWizardFields.length - 1;
      } else if (index > this.currentWizardFields.length - 1) {
        this.currentIndexAlert = 0;
      } else {
        this.currentIndexAlert = index;
      }
    },
    async fetchMaturityData() {
      if (!useWealthaboutStore().currentWealthabout) return false;
      if (this.currentStep) {
        return false;
      }
      const maturityData = await useNuxtApp().$api.fetchMaturityData();
      this.setWizardData(maturityData);
    },
    nextStep() {
      if (
        this.currentSteps &&
        this.currentSteps.length - 1 > this.currentStepIndex
      ) {
        this.currentStepIndex += 1;
      } else {
        this.closeWizard();
      }
    },
    setCurrentWizard({ steps = null, title = "" }) {
      this.setCurrentSteps(steps);
    },
    setCurrentWizardField(idField) {
      this.currentField = idField;
    },
    setCurrentWizardIdResource(currentIdResource) {
      this.currentIdResource = currentIdResource;
    },
    onSelectWizardOptions({ stepsOptions, idOption }) {
      this.setCurrentSteps(stepsOptions[idOption]);
    },
    onHighlightElementClick() {
      if (this.currentDataTemplate?.nextOnClick) {
        this.nextStep();
        return false;
      }
      return this.currentDataTemplate?.closeOnClick === undefined
        ? true
        : this.currentDataTemplate?.closeOnClick;
    },
    onHighlightElementSuccessful({ discardAlert } = { discardAlert: false }) {
      if (discardAlert) this.discardWizardElement();
      this.nextStep();
      return true;
    },
    onNext() {
      this.setCurrentIndexAlert(this.currentIndexAlert + 1);
      this.setCurrentSteps(null);
      this.clipPath = "initial";
    },
    onBack() {
      this.setCurrentIndexAlert(this.currentIndexAlert - 1);
      this.setCurrentSteps(null);
      this.clipPath = "initial";
    },
    openWizard({ position, fields, resourceType, resourceId, type }) {
      this.currentResourceType = resourceType;
      this.setModalPositionAndSize(position);
      this.currentWizardFields = fields;
      this.setCurrentWizardIdResource(resourceId);
      this.currentType = type;
      this.clipPath = fields.length > 0 ? "initial" : "";
      scroll(0, document.documentElement.scrollTop + 1);
    },
    closeWizard() {
      this.setCurrentWizardField(null);
      this.setCurrentWizardIdResource(null);
      this.clipPath = "";
      this.currentWizardFields = [];
      this.setModalPositionAndSize(null);
      this.currentType = "";
      this.currentIndexAlert = 0;
      this.currentStepIndex = null;
      this.currentResourceType = null;
      this.setCurrentWizard({});
    },
    async discardWizardElement() {
      const field = this.currentType ? null : this.currentIdField; // Si hay type, no puede tener más campos
      const data = {
        field,
        resourceId: this.currentIdResource,
        resourceType: this.currentResourceType,
        type: this.currentType,
      };
      await useNuxtApp().$api.patchMaturityData(data);
      this.closeWizard();
      await this.fetchMaturityData();
    },
  },
});
