import { wizardEmptyTypes, wizardHighlightElement, assetTypes } from "@/assets/logic/types";

export default {
  [wizardEmptyTypes[assetTypes.PRIVATE_SHARES]]: {
    title: "Inversiones empresariales",
    description: "¿Tienes alguna empresa?",
    actionsDiscard: "No tengo",
    actionsContinue: "Tengo",
    steps: [
      {
        description: "Si tiene alguna empresa debe añadirla aquí, pinchando en el +. Puede añadir tantas como tenga",
        highlightElement: wizardHighlightElement.ACTIONS_DISTRIBUTOR,
        nextOnClick: true
      }
    ]
  },
  [wizardEmptyTypes[assetTypes.OTHER]]: {
    title: "Otros activos",
    description: "¿Tienes algún activo?",
    actionsDiscard: "No tengo",
    actionsContinue: "Tengo",
    steps: [
      {
        description: "Si tiene algún activo, puede añadirlo aquí, pinchando en el +. Puede añadir tantos como tenga",
        highlightElement: wizardHighlightElement.ACTIONS_DISTRIBUTOR,
        nextOnClick: true
      }
    ]
  },
  [wizardEmptyTypes[assetTypes.FINANCIAL_INSTRUMENT]]: {
    title: "Portfolio financiero",
    description: "¿Tienes entidades bancarias?",
    actionsDiscard: "No tengo",
    actionsContinue: "Tengo",
    steps: [
      {
        description: "Puede vincular sus entidades bancarias y las de su cónyuge para agregar todos sus datos en su wealthabout",
        highlightElement: wizardHighlightElement.NEW_ITEM_CARD,
        nextOnClick: true
      }
    ]
  }
};
