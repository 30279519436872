import { ref, nextTick } from "vue";
import colors from "~/assets/logic/colors.js";

// Definir el array de notificaciones como un ref reactivo
const notifications = ref([]);
const timeoutMap = new Map();

// Valores por defecto según el tipo de notificación
const defaultConfig = {
  info: {
    title: "¡Hey! Esto podría interesarte",
    icon: "info",
    color: colors.info,
    secondaryColor: colors.infoSecondary,
    borderColor: colors.infoBorder
  },
  success: {
    title: "¡Genial! Todo salió bien",
    icon: "check_circle",
    color: colors.success,
    secondaryColor: colors.successSecondary,
    borderColor: colors.successBorder
  },
  error: {
    title: "¡Ups! Algo ha ido mal",
    icon: "error",
    color: colors.error,
    secondaryColor: colors.errorSecondary,
    borderColor: colors.errorBorder
  },
  warning: {
    title: "¡Ojo! Algo podría ir mal",
    icon: "warning",
    color: colors.warning,
    secondaryColor: colors.warningSecondary,
    borderColor: colors.warningBorder
  }
};

/**
 * useNotifications Composable
 *
 * addNotification: Añade una notificación al sistema. Si ya existe una notificación con el mismo id, se elimina y se vuelve a añadir para reiniciar su animación.
 * removeNotification: Elimina una notificación por su ID.
 *
 * Parámetros para addNotification:
 *  - type (string): Tipo de la notificación ('info', 'success', 'error', 'warning'). Valor por defecto: 'info'.
 *  - group (string): Grupo de notificaciones al que pertenece ('global' o personalizado). Valor por defecto: 'global'.
 *  - closable (boolean): Indica si la notificación se puede cerrar manualmente. Valor por defecto: true.
 *  - id (number): Identificador único de la notificación. Valor por defecto: Date.now() para garantizar un ID único.
 *  - duration (number): Tiempo en milisegundos antes de que la notificación se elimine automáticamente. Si closable es false, el valor por defecto es 10,000ms. Si closable es true, no se define una duración por defecto.
 *  - params (object): Otros parámetros que pueden incluir `title`, `text`, `icon`, `action` entre otros.
 */
export function useNotifications() {
  // Método para añadir una notificación
  const addNotification = async ({
    type = "info",
    group = "global",
    closable = true,
    id = Date.now(),
    duration = closable ? 0 : 10000,
    ...params
  }) => {
    const config = defaultConfig[type] || defaultConfig.info;
    // Eliminar notificación existente si el id ya está presente
    removeNotification(id);

    await nextTick();

    notifications.value.push({
      group,
      closable,
      id,
      type,
      title: params.title || config.title,
      icon: params.icon || config.icon,
      color: params.color || config.color,
      secondaryColor: params.secondaryColor || config.secondaryColor,
      borderColor: params.borderColor || config.borderColor,
      action: params.action || null,
      ...params
    });

    // Manejar la duración si está especificada
    if (duration > 0) {
      const timeout = setTimeout(() => {
        removeNotification(id);
      }, duration);
      timeoutMap.set(id, timeout);
    }
  };

  // Método para eliminar una notificación por su id
  const removeNotification = id => {
    // Limpiar el timeout si existe
    if (timeoutMap.has(id)) {
      clearTimeout(timeoutMap.get(id));
      timeoutMap.delete(id);
    }
    notifications.value = notifications.value.filter(notif => notif.id !== id);
  };

  // Devolver el array de notificaciones y los métodos
  return {
    notifications,
    addNotification,
    removeNotification
  };
}
