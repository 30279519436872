import { getCustomRequiredActions } from "@/assets/logic/wizard/utils.js";
import { wizardRequiredActions, wizardHighlightElement } from "@/assets/logic/types";

export default {
  [wizardRequiredActions.HOLDINGWEALTHABOUTID]: {
    title: getCustomRequiredActions(wizardRequiredActions.HOLDINGWEALTHABOUTID),
    description: "Visite el Wealthabout asociado a la Sociedad Patrimonial para rellenar sus datos de patrimonio.",
    highlightElement: wizardHighlightElement.HOLDING_WEALTHABOUT_LINK,
    actionsDiscard: "Descartar"
  }
};
