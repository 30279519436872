import { CURRENT_YEAR, toDate } from "@/assets/logic/helpers";
import {
  monitorTypes,
  expensesFilterTypes,
  inventoryTypes,
} from "@/assets/logic/types";
import { useWealthaboutStore } from "@/stores/wealthaboutStore";

export default defineNuxtPlugin(nuxtApp => {
  const baseAPIURL = "/api";
  const bankingAPIURL = "/openbanking";
  const digitalfootprintAPIURL = "/digitalfootprint";
  const router = useRouter();
  const route = router.currentRoute;
  return {
    provide: {
      api: {
        getUser() {
          const path = `${baseAPIURL}/users`;
          return nuxtApp.$axios.get(path);
        },
        createUserFromToken(data) {
          const path = `${baseAPIURL}/users/signup`;
          return nuxtApp.$axios.post(path, data);
        },
        inviteUser(data) {
          const path = `${baseAPIURL}/users/invite`;
          return nuxtApp.$axios.post(path, data);
        },
        fetchCompanyData() {
          const path = `${baseAPIURL}/companies/me`;
          return nuxtApp.$axios.get(path);
        },
        fetchManagerData(managerId) {
          const path = `${baseAPIURL}/companies/me/managers/${managerId}`;
          return nuxtApp.$axios.get(path);
        },
        updateLinkedClientsToManager(managerId, data) {
          const path = `${baseAPIURL}/companies/me/managers/${managerId}/link-clients`;
          return nuxtApp.$axios.patch(path, data);
        },
        deleteManager(managerId) {
          const path = `${baseAPIURL}/companies/me/managers/${managerId}`;
          return nuxtApp.$axios.delete(path);
        },
        postFeedback(data, files) {
          const formData = new FormData();
          Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
          });
          for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
          }
          const path = `${baseAPIURL}/users/feedback`;
          const headers = { "Content-Type": "multipart/form-data" };
          return nuxtApp.$axios.post(path, formData, { ...headers });
        },
        fetchVitalMonitors(isDeflacted) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/vitalmonitors`;
          const params = { isDeflacted };
          return nuxtApp.$axios.get(path, { params });
        },
        fetchClientOrders() {
          const path = `${baseAPIURL}/client-orders`;
          return nuxtApp.$axios.get(path);
        },
        fetchClientOrder(id) {
          const path = `${baseAPIURL}/client-orders/${id}`;
          return nuxtApp.$axios.get(path);
        },
        updateOrder(id, data) {
          const path = `${baseAPIURL}/client-orders/${id}`;
          return nuxtApp.$axios.patch(path, data);
        },
        fetchUserFeatures() {
          const params = route.value.params.wealthaboutid
            ? { wealthaboutId: route.value.params.wealthaboutid }
            : {};
          const path = `${baseAPIURL}/feature-toggle`;
          return nuxtApp.$axios.get(path, { params });
        },
        async fetchAnnualMonitors() {
          const waYearsRange = useWealthaboutStore().yearsRange.slice();
          const waInitialYear = waYearsRange.shift();
          const waFinalYear = waYearsRange.pop();

          const years = [useWealthaboutStore().selectedYear];
          useWealthaboutStore().selectedYear !== waInitialYear &&
            years.push(useWealthaboutStore().selectedYear - 1);
          useWealthaboutStore().selectedYear !== waFinalYear &&
            years.push(useWealthaboutStore().selectedYear + 1);

          const response = await Promise.allSettled([
            ...years.map(y =>
              this.fetchInventory({
                year: y,
                belongTo: null,
                today: null,
                monitorType: [
                  monitorTypes.OWE,
                  monitorTypes.HAVE,
                  monitorTypes.SAVE,
                  monitorTypes.EARN,
                  monitorTypes.SPEND,
                ],
                excludeItems: true,
                groupedOption: expensesFilterTypes.CATEGORY,
                includeProductivity: false,
                includePercentage: false,
              })
            ),
          ]).then(results => {
            const mapMonitor = data => ({
              total: data.totalValue,
              ownership: data.ownership,
              types: Object.keys(data.types).reduce((acc, key) => {
                acc[key] = data.types[key].value.value;
                return acc;
              }, {}),
            });
            return results.map(x => {
              const assets = mapMonitor(x.value[monitorTypes.HAVE]);
              // Se filtran los tipos accountsDeposits, investments, pensionPlans
              const typesWithoutDissagregatedPortfolio = Object.keys(
                assets.types
              )
                .filter(
                  key =>
                    ![
                      inventoryTypes.ACCOUNTS_DEPOSITS,
                      inventoryTypes.INVESTMENTS,
                      inventoryTypes.PENSION_PLANS,
                    ].includes(key)
                )
                .reduce((acc, key) => {
                  acc[key] = assets.types[key];
                  return acc;
                }, {});
              assets.types = typesWithoutDissagregatedPortfolio;

              return {
                assets: { value: assets },
                liabilities: { value: mapMonitor(x.value[monitorTypes.OWE]) },
                incomes: { value: mapMonitor(x.value[monitorTypes.EARN]) },
                expenses: { value: mapMonitor(x.value[monitorTypes.SPEND]) },
                savings: { value: mapMonitor(x.value[monitorTypes.SAVE]) },
              };
            });
          });

          let [CURR, PREV, NEXT] = Array(3).fill({
            assets: {},
            liabilities: {},
            incomes: {},
            expenses: {},
            savings: {},
          });

          if (
            useWealthaboutStore().selectedYear === waInitialYear &&
            useWealthaboutStore().selectedYear !== waFinalYear
          ) {
            // Recuperar año actual y siguiente
            [CURR, NEXT] = response;
          } else if (useWealthaboutStore().selectedYear === waFinalYear) {
            // Recuperar año actual y anterior
            [CURR, PREV] = response;
          } else {
            // Recuperar año actual, anterior y siguente
            [CURR, PREV, NEXT] = response;
          }

          return {
            assets: {
              previous: PREV.assets,
              current: CURR.assets,
              next: NEXT.assets,
            },
            liabilities: {
              previous: PREV.liabilities,
              current: CURR.liabilities,
              next: NEXT.liabilities,
            },
            incomes: {
              previous: PREV.incomes,
              current: CURR.incomes,
              next: NEXT.incomes,
            },
            expenses: {
              previous: PREV.expenses,
              current: CURR.expenses,
              next: NEXT.expenses,
            },
            savings: {
              previous: PREV.savings,
              current: CURR.savings,
              next: NEXT.savings,
            },
          };
        },
        fetchWealthaboutReporting(params) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/reporting`;
          return nuxtApp.$axios({
            method: "get",
            url: path,
            params,
            responseType: "blob",
          });
        },

        // Members
        async fetchMembers(waId) {
          const wealthaboutid = waId || route.value.params.wealthaboutid;
          const path = `${baseAPIURL}/wealthabouts/${wealthaboutid}/members`;
          const members = await nuxtApp.$axios.get(path);
          // TODO: El back podría devolver todas las colecciones como arrays con id
          return Object.entries(members).map(x => ({ id: x[0], ...x[1] }));
        },
        createMember(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/members`;
          return nuxtApp.$axios.post(path, data);
        },
        fetchMember(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/members/${id}`;
          return nuxtApp.$axios.get(path);
        },
        async calculatorRetirement(retirementDate, memberId) {
          const data = {
            wealthaboutId: route.value.params.wealthaboutid,
            memberId,
            retirementDate,
          };
          const path = `${baseAPIURL}/calculators/retirement`;
          return await nuxtApp.$axios.post(path, data);
        },
        updateMember(id, data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/members/${id}`;
          return nuxtApp.$axios.patch(path, data);
        },
        removeMember(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/members/${id}`;
          return nuxtApp.$axios.delete(path);
        },
        fetchMemberReporting(params) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/members/${route.value.params.memberid}/reporting`;
          return nuxtApp.$axios({
            method: "get",
            url: path,
            params,
            responseType: "blob",
          });
        },

        // Assets
        async fetchAssets(year, belongTo) {
          const params = {};
          if (year) params.year = year;
          if (belongTo) params.belongTo = belongTo;
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/assets`;
          const fetchedAssets = await nuxtApp.$axios.get(path, { params });
          const assets = Object.entries(fetchedAssets).map(x => ({
            id: x[0],
            ...x[1],
          }));
          return new Promise(resolve => resolve(assets));
        },
        createAsset(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/assets`;
          return nuxtApp.$axios.post(path, data);
        },
        fetchAsset(id, wealthaboutId) {
          const wealthaboutIdRequest =
            wealthaboutId || route.value.params.wealthaboutid;
          const path = `${baseAPIURL}/wealthabouts/${wealthaboutIdRequest}/assets/${id}`;
          return nuxtApp.$axios.get(path);
        },
        updateAsset(id, data, wealthaboutId) {
          const wealthaboutIdRequest =
            wealthaboutId || route.value.params.wealthaboutid;
          const path = `${baseAPIURL}/wealthabouts/${wealthaboutIdRequest}/assets/${id}`;
          return nuxtApp.$axios.patch(path, data);
        },
        removeAsset(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/assets/${id}`;
          return nuxtApp.$axios.delete(path);
        },
        // Liabilities
        async fetchLiabilities(resourceId, year, type) {
          const params = {};
          if (year) {
            params.year = year;
          } else if (useWealthaboutStore().selectedYear !== CURRENT_YEAR) {
            params.year = useWealthaboutStore().selectedYear;
          }
          if (resourceId) {
            params.belongTo = resourceId;
          }
          if (type) {
            params.type = type;
          }
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/liabilities`;
          const fetchedLiabilities = await nuxtApp.$axios.get(path, { params });
          const liabilities = Object.entries(fetchedLiabilities).map(x => ({
            id: x[0],
            ...x[1],
          }));
          return new Promise(resolve => resolve(liabilities));
        },
        async fetchLiability(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/liabilities/${id}`;
          const liability = await nuxtApp.$axios.get(path);
          // TODO: Todos los objetos deverían venir con su id desde el back
          liability.id = id;
          return new Promise(resolve => resolve(liability));
        },
        async fetchAssetMortgage(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/properties/${id}/mortgage`;
          const mortgage = await nuxtApp.$axios.get(path);
          return new Promise(resolve => resolve(mortgage));
        },
        createLiability(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/liabilities`;
          return nuxtApp.$axios.post(path, data);
        },
        updateLiability(id, data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/liabilities/${id}`;
          return nuxtApp.$axios.patch(path, data);
        },
        removeLiability(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/liabilities/${id}`;
          return nuxtApp.$axios.delete(path);
        },

        // Inheritances
        async fetchInheritances() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/inheritances`;
          const fetchedInheritances = await nuxtApp.$axios.get(path);
          return await Promise.resolve(fetchedInheritances);
        },
        createInheritance(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/inheritances`;
          return nuxtApp.$axios.post(path, data);
        },
        updateInheritance(id, data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/inheritances/${id}`;
          return nuxtApp.$axios.patch(path, data);
        },
        removeInheritance(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/inheritances/${id}`;
          return nuxtApp.$axios.delete(path);
        },

        // Expenses
        async fetchExpenses(resourceId, amountType, resourceIds = null) {
          const params = {};
          if (resourceId) {
            params.resourceId = resourceId;
          }
          if (amountType) {
            params.amountType = amountType;
          }
          if (resourceIds) {
            params.resourceIds = resourceIds;
          }
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/expenses`;
          const fetchedExpenses = await nuxtApp.$axios.get(path, { params });
          return Object.entries(fetchedExpenses).map(x => ({
            id: x[0],
            ...x[1],
          }));
        },
        createExpense(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/expenses`;
          return nuxtApp.$axios.post(path, data);
        },
        updateExpense(id, data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/expenses/${id}`;
          return nuxtApp.$axios.patch(path, data);
        },
        removeExpense(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/expenses/${id}`;
          return nuxtApp.$axios.delete(path);
        },

        // Incomes
        async fetchIncomes(resourceId, amountType) {
          const params = {};
          if (resourceId) {
            params.resourceId = resourceId;
          }
          if (amountType) {
            params.amountType = amountType;
          }
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/incomes`;
          const fetchedIncomes = await nuxtApp.$axios.get(path, { params });
          return Object.entries(fetchedIncomes).map(x => ({
            id: x[0],
            ...x[1],
          }));
        },
        createIncome(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/incomes`;
          return nuxtApp.$axios.post(path, data);
        },
        updateIncome(id, data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/incomes/${id}`;
          return nuxtApp.$axios.patch(path, data);
        },
        removeIncome(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/incomes/${id}`;
          return nuxtApp.$axios.delete(path);
        },
        // Portfolio
        async fetchPortfolio(year = undefined, belongTo = undefined) {
          if (year === undefined) year = useWealthaboutStore().selectedYear;
          const params = { year };
          if (belongTo) params.belongTo = belongTo;
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio`;
          return await nuxtApp.$axios.get(path, { params });
        },
        async fetchPortfolioAssets(hidden, year = undefined) {
          if (year === undefined) year = useWealthaboutStore().selectedYear;
          const params = { year };
          if (hidden) {
            params.hidden = hidden;
          }
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets`;
          return await nuxtApp.$axios.get(path, { params });
        },
        portfolioUpdate(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio`;
          return nuxtApp.$axios.patch(path, data);
        },
        portfolioCreateAsset(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets`;
          return nuxtApp.$axios.post(path, data);
        },
        async portfolioGetAsset(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets/${id}`;
          return await nuxtApp.$axios.get(path);
        },
        portfolioUpdateAsset(id, data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets/${id}`;
          return nuxtApp.$axios.patch(path, data);
        },
        portfolioRemoveAsset(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets/${id}`;
          return nuxtApp.$axios.delete(path);
        },
        async fetchPortfolioAnalysis(year, today) {
          const params = {};
          if (year) {
            params.year = year;
          }
          if (today) {
            params.today = today;
          }
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/analysis`;
          return await nuxtApp.$axios.get(path, { params });
        },
        async checkIban(accountNumber, type) {
          const params = { accountNumber, type };
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets/validate`;
          return await nuxtApp.$axios.post(path, { ...params });
        },
        portfolioCreateEntity(data, wealthaboutid) {
          const path = `${baseAPIURL}/wealthabouts/${wealthaboutid || route.value.params.wealthaboutid}/financialportfolio/entities`;
          return nuxtApp.$axios.post(path, data);
        },
        portfolioUpdateEntity(id, data, wealthaboutid) {
          const path = `${baseAPIURL}/wealthabouts/${wealthaboutid || route.value.params.wealthaboutid}/financialportfolio/entities/${id}`;
          return nuxtApp.$axios.patch(path, data);
        },
        portfolioRemoveEntity(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/entities/${id}`;
          return nuxtApp.$axios.delete(path);
        },
        portfolioIsEntityFullfilled(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/update/entities/${id}`;
          return nuxtApp.$axios.post(path);
        },
        async portfolioMovements(id, limit, offset) {
          const params = { limit, offset };
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets/${id}/movements`;
          return await nuxtApp.$axios.get(path, { params });
        },
        async portfolioMovementsAll(limit, offset, onlyExpenses, year) {
          const params = { limit, offset, onlyExpenses, year };
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/movements`;
          return await nuxtApp.$axios.get(path, { params });
        },
        async portfolioUpadateMovement(movementId, data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/movements/${movementId}`;
          return await nuxtApp.$axios.patch(path, data);
        },
        async portfolioInvestments() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/investments`;
          return await nuxtApp.$axios.get(path);
        },
        async portfolioAssetInvestments(id, limit, offset) {
          const params = { limit, offset };
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets/${id}/investments`;
          const data = await nuxtApp.$axios.get(path, { params });
          return data;
        },
        async portfolioAssetInvestmentsTransactions(id, limit, offset) {
          const params = { limit, offset };
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets/${id}/investments/transactions`;
          const response = await nuxtApp.$axios.get(path, { params });
          response.results = response.results.map(transactions => {
            const [[, value]] = Object.entries(transactions);

            value.operationDate = toDate(value.operationDate);

            return value;
          });
          return response;
        },
        async getPortfolioInvestments(investmentId) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/investments/${investmentId}`;
          return await nuxtApp.$axios.get(path);
        },
        async postPortfolioAssetInvestments(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets/${route.value.params.bankassetid}/investments`;
          return await nuxtApp.$axios.post(path, data);
        },
        async patchPortfolioAssetInvestments(data, accountId, investmentId) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets/${accountId}/investments/${investmentId}`;
          return await nuxtApp.$axios.patch(path, data);
        },
        async deletePortfolioAssetInvestments(accountId, investmentId) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/assets/${accountId}/investments/${investmentId}`;
          return await nuxtApp.$axios.delete(path);
        },
        async portfolioFlows(limit, offset) {
          let params = {};
          if (limit !== undefined && offset !== undefined) {
            params = { limit, offset };
          }
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/flows`;
          return await nuxtApp.$axios.get(path, { params });
        },
        async portfolioUpdateEntities() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/financialportfolio/update`;
          return await nuxtApp.$axios.post(path);
        },
        // Documents
        async fetchDocumentsByResource(resourceId) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/documents?belongTo=${resourceId}`;
          const documents = await nuxtApp.$axios.get(path);
          return Object.entries(documents).map(x => ({ id: x[0], ...x[1] }));
        },
        async uploadDocument(
          resourceId,
          document,
          visible = true,
          wealthaboutId = route.value.params.wealthaboutid
        ) {
          const path = `${baseAPIURL}/wealthabouts/${wealthaboutId}/documents`;
          const formData = new FormData();
          formData.append("belongTo", resourceId);
          formData.append("file", document);
          formData.append("isVisibleInRepo", visible);
          const headers = { "Content-Type": "multipart/form-data" };
          return await nuxtApp.$axios.post(path, formData, { ...headers });
        },
        updateDocument(id, data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/documents/${id}`;
          return nuxtApp.$axios.patch(path, data);
        },
        async deleteDocument(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/documents/${id}`;
          return await nuxtApp.$axios.delete(path);
        },
        async fetchBlob(id) {
          if (!id) return null;
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/documents/${id}`;
          return await nuxtApp.$axios.get(path, { responseType: "blob" });
        },
        downloadFile(blob, description, type) {
          const hasExtension = /[\s\S]+\.[0-9a-z]+$/i.test(description);
          const fileName = hasExtension
            ? description
            : `${description}.${type}`;
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = window.document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          window.document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        },
        async openDocument(document) {
          // Obtenemos el blob del backend
          const data = await this.fetchBlob(document.id);
          nuxtApp.$helpers.downloadBlob(data, document.name, document.fileType);
        },
        // Import & export wealthabout file
        async fetchClient(id) {
          const clientId =
            id ||
            route.value.params.clientid ||
            nuxtApp.$auth.$state.user.client_id;
          const path = `${baseAPIURL}/clients/${clientId}`;
          return await nuxtApp.$axios.get(path);
        },
        async fetchWap(id) {
          const clientId = id || route.value.params.clientid;
          const path = `${baseAPIURL}/waps/${clientId}`;
          return await nuxtApp.$axios({
            method: "get",
            url: path,
            responseType: "blob",
            headers: { "Cache-Control": "no-cache" },
          });
        },
        uploadWealthabout(file, id) {
          const clientId = id || route.value.params.clientid;
          const path = `${baseAPIURL}/waps/${clientId}`;
          const formData = new FormData();
          formData.append("file", file);
          const headers = { "Content-Type": "multipart/form-data" };
          return nuxtApp.$axios.post(path, formData, { ...headers });
        },
        exportedWealthabout(id) {
          const clientId = id || route.value.params.clientid;
          const path = `${baseAPIURL}/waps/${clientId}`;
          return nuxtApp.$axios.delete(path);
        },

        // Clients
        fetchClients() {
          const path = `${baseAPIURL}/clients/me`;
          return nuxtApp.$axios.get(path);
        },
        fetchClientByNIF(nif) {
          const path = `${baseAPIURL}/clients/me`;
          const params = { nif };
          return nuxtApp.$axios.get(path, { params });
        },
        createClient(formData) {
          const path = `${baseAPIURL}/clients`;
          const headers = { "Content-Type": "multipart/form-data" };
          return nuxtApp.$axios.post(path, formData, { ...headers });
        },
        deleteClient(id) {
          const path = `${baseAPIURL}/clients/${id}`;
          return nuxtApp.$axios.delete(path);
        },

        // Automatic Updater
        getUserConfiguration() {
          const path = `${baseAPIURL}/users/me/configuration`;
          return nuxtApp.$axios.get(path);
        },
        updateUserConfiguration(automaticUpdateEnabled) {
          const path = `${baseAPIURL}/users/me/configuration`;
          return nuxtApp.$axios.patch(path, { automaticUpdateEnabled });
        },

        // Categories
        fetchCategories() {
          const path = `${baseAPIURL}/categories`;
          return nuxtApp.$axios.get(path);
        },

        // Tax report
        fetchTaxes() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/taxes`;
          return nuxtApp.$axios.get(path);
        },
        fetchTaxBreakdown({ taxType, belongTo, year }) {
          const params = { taxType, belongTo, year };
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/taxes-breakdown`;
          return nuxtApp.$axios.get(path, { params });
        },

        // Wealthabouts
        async fetchWealthaboutsMetadata(client_id) {
          const clientId = client_id || route.value.params.clientid;
          const path = `${baseAPIURL}/wealthabouts?client_id=${clientId}`;
          return await nuxtApp.$axios.get(path);
        },
        async fetchWealthaboutMetadata(waid) {
          const id = waid || route.value.params.wealthaboutid;
          const path = `${baseAPIURL}/wealthabouts/${id}`;
          return await nuxtApp.$axios.get(path);
        },

        // Simulaciones
        async createWealthabout({ alias, description, clientId, image }) {
          const data = {
            clientId: clientId || route.value.params.clientid,
            alias,
            description,
            image,
          };
          const path = `${baseAPIURL}/wealthabouts`;
          return await nuxtApp.$axios.post(path, data);
        },
        async updateWealthabout({ alias, description, wealthaboutId }) {
          const data = {
            alias,
            description,
          };
          const path = `${baseAPIURL}/wealthabouts/${wealthaboutId}`;
          return await nuxtApp.$axios.patch(path, data);
        },
        async deleteWealthabout(wealthaboutId) {
          const path = `${baseAPIURL}/wealthabouts/${wealthaboutId}`;
          return await nuxtApp.$axios.delete(path);
        },

        // IPC (populationData)
        async fetchIPC() {
          const path = `${baseAPIURL}/population-data/ipc/rates?last`;
          return await nuxtApp.$axios.get(path);
        },
        async fetchBCE() {
          const path = `${baseAPIURL}/population-data/ipc/estimation-rate`;
          return await nuxtApp.$axios.get(path);
        },
        async fetchDefaultEducationCost() {
          const path = `${baseAPIURL}/population-data/education-cost`;
          return await nuxtApp.$axios.get(path);
        },

        // euribor (populationData)
        async fetchEuribor(fromYear, toYear) {
          const params = { fromYear, toYear };
          const path = `${baseAPIURL}/population-data/euribor`;
          const data = await nuxtApp.$axios.get(path, { params });
          return Object.entries(data.euribor).sort((a, b) => {
            return toDate(a[0]) - toDate(b[0]);
          });
        },
        //  Drive
        connectToDrive(platform) {
          const path = `${baseAPIURL}/drive/connect`;
          const data = { platform };
          return nuxtApp.$axios.post(path, data);
        },
        disconnectFromDrive() {
          const path = `${baseAPIURL}/drive/disconnect`;
          return nuxtApp.$axios.delete(path);
        },
        driveCallback(data) {
          const path = `${baseAPIURL}/drive/callback`;
          return nuxtApp.$axios.post(path, data);
        },
        driveStatus() {
          const path = `${baseAPIURL}/drive/drive-status`;
          return nuxtApp.$axios.get(path);
        },
        driveTransferStatus() {
          const path = `${baseAPIURL}/drive/transfer-status`;
          return nuxtApp.$axios.get(path);
        },

        // Wizard
        fetchMaturityData() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/maturity`;
          return nuxtApp.$axios.get(path);
        },
        patchMaturityData(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/maturity`;
          return nuxtApp.$axios.patch(path, data);
        },

        // Eventos del wealthabout
        fetchEvents(types = [], savedId = null) {
          const params = {
            types: types.length > 0 ? types.join(",") : [],
            savedId: savedId,
          };
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/events`;
          return nuxtApp.$axios.get(path, { params });
        },
        createEvent(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/events`;
          return nuxtApp.$axios.post(path, data);
        },
        updateEvent(id, data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/events/${id}`;
          return nuxtApp.$axios.patch(path, data);
        },
        deleteEvent(id) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/events/${id}`;
          return nuxtApp.$axios.delete(path);
        },

        // Settings del wealthabout
        fetchWealthaboutSettings(wealthaboutId) {
          const wealthaboutIdRequest =
            wealthaboutId || route.value.params.wealthaboutid;
          const path = `${baseAPIURL}/wealthabouts/${wealthaboutIdRequest}/settings`;
          return nuxtApp.$axios.get(path);
        },
        patchWealthaboutSettings(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/settings`;
          return nuxtApp.$axios.patch(path, data);
        },
        fetchVisibilitySettings() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/settings/visibility`;
          return nuxtApp.$axios.get(path);
        },
        async visibilitySettingsUpdate(resourceId, data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/settings/visibility/${resourceId}`;
          return await nuxtApp.$axios.patch(path, { visibilityItem: data });
        },

        // Reestablacer contraseña
        resetPassword(data) {
          const path = `${baseAPIURL}/reset-password`;
          return nuxtApp.$axios.post(path, data);
        },
        changePassword(data) {
          const path = `${baseAPIURL}/change-password`;
          return nuxtApp.$axios.post(path, data);
        },

        // Banking
        postAvailableBanks(data) {
          const path = `${bankingAPIURL}/available-banks`;
          return nuxtApp.$axios.post(path, data);
        },
        postBankingEntity(data) {
          const path = `${bankingAPIURL}/entities`;
          return nuxtApp.$axios.post(path, data);
        },
        getSCAToken(externalId, wealthaboutId) {
          const path = `${bankingAPIURL}/entities/${externalId}/sca-token?wealthabout_id=${wealthaboutId}`;
          return nuxtApp.$axios.get(path);
        },
        postForceTransactions(externalId, data) {
          const path = `${bankingAPIURL}/entities/${externalId}/force-transactions`;
          return nuxtApp.$axios.post(path, data);
        },
        patchBankingCredentials(externalId, data) {
          const path = `${bankingAPIURL}/entities/${externalId}/credentials`;
          return nuxtApp.$axios.patch(path, data);
        },
        fetchPortfolioEntities(externalIds) {
          let idsQueryParams = "";
          externalIds.forEach(id => {
            idsQueryParams += `&entities_ids=${id}`;
          });
          const path = `${bankingAPIURL}/entities?wealthabout_id=${route.value.params.wealthaboutid}${idsQueryParams}`;
          return nuxtApp.$axios.get(path);
        },
        async searchISIN(isin) {
          const params = {
            isin,
          };
          const path = `${bankingAPIURL}/symbols/search`;
          const response = await nuxtApp.$axios
            .get(path, { params })
            .catch(error => {
              if (error.response.status === 404) return [];
            });
          return response;
        },
        async fetchInvestmentsType() {
          const path = `${bankingAPIURL}/openbanking/types`;
          return await nuxtApp.$axios.get(path);
        },
        async fetchInvestmentsCurrency() {
          const path = `${bankingAPIURL}/openbanking/currencies`;
          return await nuxtApp.$axios.get(path);
        },
        async fetchInvestmentsRisk() {
          const path = `${bankingAPIURL}/openbanking/risks`;
          return await nuxtApp.$axios.get(path);
        },
        async fetchInvestmentsRegion() {
          const path = `${bankingAPIURL}/openbanking/regions`;
          return await nuxtApp.$axios.get(path);
        },
        async fetchinvestmentsMic() {
          const path = `${bankingAPIURL}/openbanking/mics`;
          return await nuxtApp.$axios.get(path);
        },
        // Digital Footprint
        postDigitalFootprintTransaction(data) {
          const path = `${digitalfootprintAPIURL}/transactions`;
          return nuxtApp.$axios.post(path, data);
        },

        fetchDigitalFootprintTransaction(id) {
          const path = `${digitalfootprintAPIURL}/transactions/${id}`;
          return nuxtApp.$axios.get(path);
        },
        // Analysis
        async fetchWealthaboutAnalysis(monitorType, year, belongTo) {
          const params = {};
          if (monitorType) params.monitorType = monitorType;
          if (year) params.year = year;
          if (belongTo) params.belongTo = belongTo;
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/analysis`;
          const data = await nuxtApp.$axios.get(path, { params });
          return nuxtApp.$helpers.castSeriesToValue(data, year);
        },
        async fetchAnalysisResources(analysisType, year) {
          const params = {};
          if (analysisType) {
            params.analysisType = analysisType;
          }
          if (year) {
            params.year = year;
          }
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/analysis/resources`;
          const fetchedLiabilitiesResources = await nuxtApp.$axios.get(path, {
            params,
          });
          return fetchedLiabilitiesResources;
        },
        async fetchAnalysisAssetProfitability() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/analysis/assets/${route.value.params.assetid}/profitability`;
          return await nuxtApp.$axios.get(path);
        },
        // Inventory
        async fetchInventory(params) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/inventory`;
          let data = await nuxtApp.$axios.get(path, { params });
          data = nuxtApp.$helpers.castSeriesToValue(data, params.year);
          return data;
        },
        // Analysis portfolio
        async fetchAnalysisPortfolio() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/analysis/portfolio/assets`;
          const data = await nuxtApp.$axios.get(path);
          return data;
        },
        async analysisGroupedPorfolio(year, today) {
          const params = {};
          if (year) params.year = year;
          if (today) params.today = today;
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/analysis/portfolio/grouped-assets`;
          const groupedAssets = await nuxtApp.$axios.get(path, { params });
          // Si esta filtrado por año eliminamos la serie de valor y dejamos solo el valor del año seleccionado
          if (year) {
            return nuxtApp.$helpers.castSeriesToValue(groupedAssets, year);
          }
          return groupedAssets;
        },
        // Analysis ratios
        async fetchAnalysisRatios(ratiosList, year = null, today = false) {
          const params = {};
          if (ratiosList) params.ratios = ratiosList;
          if (year) params.year = year;
          if (today) params.today = today;
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/analysis/ratios`;
          return await nuxtApp.$axios.get(path, { params });
        },
        // Goals
        async fetchGoals() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/goals`;
          const data = await nuxtApp.$axios.get(path);
          return data;
        },
        async fetchGoalsAnalysis() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/goals/analysis`;
          const data = await nuxtApp.$axios.get(path);
          return data;
        },
        async fetchAllowData(
          year,
          amount,
          initialContribution,
          monthlyContribution = null
        ) {
          const params = {
            year,
            amount,
            initialContribution,
            monthlyContribution,
          };
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/goals/allow-data`;
          return await nuxtApp.$axios.post(path, { ...params });
        },
        async createGoal(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/goals`;
          return await nuxtApp.$axios.post(path, data);
        },
        async updateGoal(goalId, data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/goals/${goalId}`;
          return await nuxtApp.$axios.patch(path, data);
        },
        async deleteGoal(goalId) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/goals/${goalId}`;
          return await nuxtApp.$axios.delete(path);
        },
        async calculatorAssetIncome(assetId, year, income) {
          const data = {
            wealthaboutId: route.value.params.wealthaboutid,
            assetId,
            year,
            income,
          };
          const path = `${baseAPIURL}/calculators/asset-income`;
          return await nuxtApp.$axios.post(path, data);
        },
        // Notifications
        async fetchNotifications() {
          const path = `${baseAPIURL}/notifications`;
          const params = { sourceId: route.value.params.wealthaboutid };
          if (params.sourceId) {
            const response = await nuxtApp.$axios.get(path, { params });
            return response.notifications;
          }
        },
        async postWatchAllInfoNotifications() {
          const path = `${baseAPIURL}/notifications/watched-all`;
          const data = {
            sourceId: route.value.params.wealthaboutid,
            type: "INFO",
          };
          return await nuxtApp.$axios.post(path, data);
        },
        deleteNotifications() {
          const path = `${baseAPIURL}/notifications`;
          const params = {
            sourceId: route.value.params.wealthaboutid,
            watched: true,
          };
          return nuxtApp.$axios.delete(path, { params });
        },
        // Propuesta de cartera
        async fetchProposals() {
          return await nuxtApp.$axios.get(
            `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/proposals/assets-options`
          );
        },
        async fetchProposalAssetStrategyTypes(proposalId) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/proposals/${proposalId}/strategy-types`;
          return await nuxtApp.$axios.get(path);
        },
        async fetchProposalPortfolioStrategyTypes() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/proposals/portfolio/strategy-types`;
          return await nuxtApp.$axios.get(path);
        },
        async fetchAssetProposal(proposalId) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/proposals/${proposalId}`;
          return await nuxtApp.$axios.get(path);
        },
        async fetchProposalPortfolio() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/proposals/portfolio`;
          return await nuxtApp.$axios.get(path);
        },
        async patchAssetProposal(data, proposalId) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/proposals/${proposalId}`;
          return await nuxtApp.$axios.patch(path, data);
        },
        async patchPortfolioProposal(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/proposals/portfolio`;
          return await nuxtApp.$axios.patch(path, data);
        },
        async resetAssetProposal(proposalId) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/proposals/${proposalId}/reset`;
          return await nuxtApp.$axios.post(path);
        },
        // Education
        async fetchEducation() {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/education`;
          return await nuxtApp.$axios.get(path);
        },
        async patchMemberEducation(data) {
          const path = `${baseAPIURL}/wealthabouts/${route.value.params.wealthaboutid}/education`;
          return await nuxtApp.$axios.patch(path, data);
        },
      },
    },
  };
});
