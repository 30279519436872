import Highcharts from "highcharts";

// import borderRadius from "highcharts-border-radius";
// import draggablePoints from "highcharts/modules/draggable-points";

// borderRadius(Highcharts);
// draggablePoints(Highcharts);

export default defineNuxtPlugin(nuxtApp => {
  // Añadimos el componente con Mayúscula para que sea compatible con Vue 3
  if (import.meta.client) {
    const HighchartComponent = nuxtApp.vueApp.component("highchart");
    if (HighchartComponent) {
      nuxtApp.vueApp.component("Highchart", HighchartComponent);
    }
  }

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      rangeSelectorZoom: "",
      thousandsSep: ".",
    },
    xAxis: {
      labels: {
        style: {
          fontSize: "11px", // Ajusta el tamaño del texto en el eje X
        },
      },
    },
    yAxis: {
      labels: {
        style: {
          fontSize: "11px", // Ajusta el tamaño del texto en el eje Y
          rotation: -45, // Rotar etiquetas a -45 grados (también puedes probar 45)
          align: "right", // Alinea las etiquetas para mejor legibilidad
        },
      },
    },
  });
});
