// Para añadir una pagina su navigationGrupo, añade la propiedad navigationGroup en el componente de la página:
/*
export default {
  components: {
  },
  navigationGroup: "public", // De esta forma
  data(() => {.....})
*/
import { navigationGroupTypes, assetTypes } from "@/assets/logic/types";
import { useWealthaboutStore } from "@/stores/wealthaboutStore";

function useGuards() {
  // Obtenemos la instancia de la aplicación
  const nuxtApp = useNuxtApp();
  const wealthaboutStore = useWealthaboutStore(nuxtApp.$pinia);
  const { status } = useAuth();
  const goMap = to => ({
    name: "clients-clientid-wealthabouts-wealthaboutid-map",
    params: {
      clientid: to.params.clientid || wealthaboutStore.currentClientId,
      wealthaboutid:
        to.params.wealthaboutid || wealthaboutStore.mainWealthaboutId,
    },
    query: to.query,
  });

  // Diccionario de guardas (similar a tus funciones originales adaptadas a este contexto)
  const guardDictionary = {
    // Bloquear entrada al grupo si esta logueado y redirigir segun el tipo de usuario
    isloggedGoIn: (to, from) => {
      // comprobamos si el usuario está logueado
      if (status.value === "authenticated") {
        // Si es admin, redirigir a panel
        if (nuxtApp.$tags.isAdmin()) {
          return { name: "panel" };
          // Si es manager, redirigir a clients
        } else if (nuxtApp.$tags.isManager()) {
          return { name: "clients" };
          // Si no tiene wealthabout, redirigir onboarding
        } else if (!wealthaboutStore.hasWealthabout) {
          return { name: "onboarding" };
          // Si no es admin ni manager
        } else {
          return goMap(to);
        }
      }
    },
    // Bloquear entrada al grupo si no esta logueado
    isNotloggedGoLogin: (to, from) => {
      // comprobamos si el usuario está logueado
      if (status.value === "unauthenticated") {
        return { name: "login" };
      }
    },
    // Bloquear si su rol no pertenece a los gestores
    isNotManagerGoWA: (to, from) => {
      // comprobamos si es manager
      if (!nuxtApp.$tags?.isManager() && !nuxtApp.$tags?.isAdmin()) {
        return goMap(to);
      }
    },
    // Bloquear si su rol no pertenece a los administradores
    goPanelAndIsNotAdminGoClients: (to, from) => {
      // comprobamos si es admin
      if (!nuxtApp.$tags?.isAdmin() && to.name === "panel") {
        return { name: "clients" };
      }
    },
    // Si viene de loading y va a upload, permitir la redirección y si tiene wealthabout redirigir al mapa
    fromLoadingToUploadGoElseHasWealthaboutGoIn: (to, from) => {
      // Comprobamos si venimos desde loading y vamos a upload
      if (
        from?.name === "clients-clientid-wealthabouts-wealthaboutid-loading" &&
        to.name === "clients-clientid-upload-wa"
      ) {
        return null;
      }
      // Si no, comprobamos si tiene wealthabout y redirigimos al mapa
      if (wealthaboutStore.hasWealthabout) {
        return goMap(to);
      }
    },
    // Si no existe wealthabout, ir a Onboarding
    hasNotWealthaboutGoOnboarding: (to, from) => {
      if (!wealthaboutStore.hasWealthabout) {
        return { name: "onboarding" };
      }
    },
    // Si es wa-type holding no permitimos entrar en simulations
    isHoldingAndToSimulationsGoMap: (to, from) => {
      if (
        nuxtApp.$tags.isHolding() &&
        to.name === "clients-clientid-wealthabouts-wealthaboutid-simulations"
      ) {
        return goMap(to);
      }
    },
    // Comprueba que la ruta de redirección sea válida para ciertas versiones
    isRedirectValidForVersions: (to, from) => {
      // Esto replica la lógica del original para rutas permitidas según la versión
      const goSummary = {
        name: "clients-clientid-wealthabouts-wealthaboutid-summary",
        params: {
          clientid: to.params.clientid || wealthaboutStore.currentClientId,
          wealthaboutid:
            to.params.wealthaboutid || wealthaboutStore.mainWealthaboutId,
        },
      };
      // Si es una versión Lite o superior (no retail)
      if (nuxtApp.$tags?.isLiteOrHigher()) {
        const liteRoutes = [
          "clients-clientid-wealthabouts-wealthaboutid-loading",
          "clients-clientid-wealthabouts-wealthaboutid-summary",
          "clients-clientid-wealthabouts-wealthaboutid-portfolio",
          "clients-clientid-wealthabouts-wealthaboutid-portfolio-current-situation",
          "clients-clientid-wealthabouts-wealthaboutid-portfolio-settings",
          "clients-clientid-wealthabouts-wealthaboutid-portfolio-bankassetid-movements",
          "clients-clientid-wealthabouts-wealthaboutid-portfolio-bankassetid-investments",
          "clients-clientid-wealthabouts-wealthaboutid-portfolio-bankassetid-transactions",
          "clients-clientid-wealthabouts-wealthaboutid-members-family",
          "clients-clientid-wealthabouts-wealthaboutid-module-portfolioproposal",
          "clients-clientid-wealthabouts-wealthaboutid-module-portfolioproposal-settings",
          "clients-clientid-wealthabouts-wealthaboutid-module-assetproposal",
          "clients-clientid-wealthabouts-wealthaboutid-module-assetproposal-settings",
        ];

        if (nuxtApp.$tags?.isLiteOnly()) {
          if (!liteRoutes.includes(to.name)) {
            return goSummary;
          }
        }

        const pbRoutes = liteRoutes.concat([
          "clients-clientid-wealthabouts-wealthaboutid-incomes-expenses",
          "clients-clientid-wealthabouts-wealthaboutid-portfolio-future-projection",
          "clients-clientid-wealthabouts-wealthaboutid-situation",
          "clients-clientid-wealthabouts-wealthaboutid-vitalmonitors",
          "clients-clientid-wealthabouts-wealthaboutid-settings",
          "clients-clientid-wealthabouts-wealthaboutid-module-education",
          "clients-clientid-wealthabouts-wealthaboutid-module-education-settings",
        ]);

        if (!nuxtApp.$tags?.isLiteOnly()) {
          // asumiendo que es PB o superior
          if (!pbRoutes.includes(to.name)) {
            return goSummary;
          }
        }
      }
      return null;
    },
    // Si vas a modulo de propiedades y no tienes propiedades, redirigir a distribuidora de inmuebles
    ifToAssetModuleAndNotPropertyGoDistributor(to, from) {
      if (
        to.name ===
          "clients-clientid-wealthabouts-wealthaboutid-module-assetproposal" &&
        wealthaboutStore.assets.filter(x => x.type === assetTypes.REAL_ESTATE)
          .length === 0
      ) {
        return {
          name: "clients-clientid-wealthabouts-wealthaboutid-assets-distributor-real-estate",
          params: {
            clientid: to.params.clientid || wealthaboutStore.currentClientId,
            wealthaboutid:
              to.params.wealthaboutid || wealthaboutStore.mainWealthaboutId,
          },
        };
      }
    },
  };
  return { guardDictionary };
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { status, getSession } = useAuth();
  if (status.value === "unauthenticated") {
    await getSession();
  }
  // Si la ruta es nula, redirigir a home
  if (!to.name) return navigateTo({ name: "home" });

  // Obtener el contador de redirecciones de la query actual
  const redirectCount = parseInt(to.query.redirectCount || "0");
  // Establecer el umbral de redirecciones permitidas
  const THRESHOLD = 5;

  if (redirectCount > THRESHOLD) {
    // Se ha detectado un bucle de redirecciones
    console.error("Se ha detectado un bucle infinito de redirecciones.");
    console.error("Desde", from?.name, "a", to?.name);

    // Cerrar sesión (ejemplo: eliminar token de autenticación)
    const nuxtApp = useNuxtApp();
    nuxtApp.$session.logout();

    // Si estamos en el cliente, refrescamos el navegador
    if (import.meta.client) {
      window.location.reload();
    }
    return navigateTo({ name: "login" });
  }

  const { guardDictionary } = useGuards();

  const definePolicyGroupPages = {
    [navigationGroupTypes.PUBLIC]: {
      guards: [],
    },
    [navigationGroupTypes.PUBLIC_NOT_LOGGIN]: {
      guards: [guardDictionary.isloggedGoIn],
    },
    [navigationGroupTypes.MANAGEMENT]: {
      guards: [
        guardDictionary.isNotloggedGoLogin,
        guardDictionary.isNotManagerGoWA,
        guardDictionary.goPanelAndIsNotAdminGoClients,
      ],
    },
    [navigationGroupTypes.WA_NOT_DATA]: {
      guards: [
        guardDictionary.isNotloggedGoLogin,
        guardDictionary.fromLoadingToUploadGoElseHasWealthaboutGoIn,
      ],
    },
    [navigationGroupTypes.WA]: {
      guards: [
        guardDictionary.isNotloggedGoLogin,
        guardDictionary.hasNotWealthaboutGoOnboarding,
        guardDictionary.isHoldingAndToSimulationsGoMap,
        guardDictionary.isRedirectValidForVersions,
        guardDictionary.ifToAssetModuleAndNotPropertyGoDistributor,
      ],
    },
  };

  // Detectar el grupo de navegación
  const navigationGroup = to.meta.navigationGroup || navigationGroupTypes.WA; // Por defecto, WA

  // Obtener las guardas para el grupo de navegación
  const policyGroup = definePolicyGroupPages[navigationGroup];
  if (!policyGroup) {
    console.error(`Navigation group ${navigationGroup} not found`);
    return;
  }

  for (const guard of policyGroup.guards) {
    const redirect = guard(to, from);
    if (redirect) {
      // Incrementar el contador de redirecciones en la query y redirigir
      const newRedirectCount = redirectCount + 1;
      return navigateTo({
        ...redirect,
        query: {
          ...redirect.query,
          redirectCount: newRedirectCount,
        },
      });
    }
  }
});
