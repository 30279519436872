<template>
  <div class="cookies-policy">
    <public-header :has-max-width="true" not-button-access />
    <div class="container">
      <h1 class="cookies-policy__heading">Política de cookies</h1>
      <section class="section section--split">
        <h2 class="section__heading">Introducción</h2>
        <p class="section__p">
          Wealthabout, S.L. se compromete a proteger tu información personal
          cuando utilices nuestros servicios. Queremos que nuestros servicios
          sean bajo un marco seguro. Hemos creado esta política de cookies
          separada para proporcionar información completa sobre el uso de las
          mismas en nuestro sitio web.
        </p>
        <p class="section__p">
          Con el fin de proporcionarte la gama completa de servicios de
          Wealthabout, S.L., a veces se nos pide que recopilemos información
          sobre nuestros visitantes.
        </p>
        <p class="section__p">
          Nuestra empresa se compromete a salvaguardar tu información personal.
          Siempre que nos proporciones dicha información, estamos legalmente
          obligados a utilizar tu información de acuerdo con todas las leyes
          relativas a la protección de la información personal, incluyendo la
          Regulación General de Protección de Datos (GDPR) que entró en vigor el
          25 de mayo en todo el territorio de la UE.
        </p>
      </section>
      <section class="section section--split">
        <h2 class="section__heading">Cómo utilizamos las cookies</h2>
        <p class="section__p">
          Una cookie es un pequeño archivo que pide permiso para ser colocado en
          el disco duro de tu ordenador. El archivo se agrega y la cookie ayuda
          a analizar el tráfico de la web o le permite saber cuándo visitas un
          sitio en particular. La aplicación web puede adaptar sus operaciones a
          sus necesidades y preferencias reuniendo y recordando información
          sobre sus las mismas.
        </p>
        <p class="section__p">
          En general, las cookies nos ayudan a ofrecerle un mejor sitio web al
          permitirnos controlar qué páginas te resultan útiles y cuáles no. Una
          cookie no nos da acceso de ninguna manera a tu ordenador ni a ninguna
          información sobre usted, aparte de los datos que decidas compartir con
          nosotros.
        </p>
        <p class="section__p">
          Puedes elegir entre aceptar o rechazar las cookies. La mayoría de los
          navegadores web aceptan automáticamente las cookies, pero normalmente
          puedes modificar la configuración del navegador para rechazarlas si lo
          prefiere. Al continuar utilizando el sitio, estás aceptando el uso de
          cookies.
        </p>
        <p class="section__p">
          En la siguiente tabla se explican las cookies que utilizamos y por
          qué:
        </p>
      </section>
      <section class="section">
        <div class="table-container">
          <table role="table">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader">Cookie</th>
                <th role="columnheader">Nombre</th>
                <th role="columnheader">Objetivo</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <tr role="row">
                <td role="cell">Cookie banner</td>
                <td role="cell" columnspan="2">wealthabout.cookiespolicy</td>
                <td role="cell">
                  Esta cookie se usa para controlar la apariencia del banner
                  informativo de las cookies. Esta cookie se configura por
                  defecto a la llegada al sitio. Expira después de un tiempo
                  determinado y no se vuelve a configurar de nuevo al menos que
                  el usuario cambie sus configuraciones de cookies.
                </td>
              </tr>
              <tr role="row">
                <td role="cell">Autenticación</td>
                <td role="cell">
                  wealthabout._token.local<br />wealthabout._refresh_token.local<br />wealthabout.redirect<br />wealthabout.strategy
                </td>
                <td role="cell">
                  Estas cookies se utilizan para controlar la autenticación del
                  usuario en el sitio. Se configuran por defecto a la llegada al
                  sitio y se eliminan al cerrar el navegador.
                </td>
              </tr>
              <tr role="row">
                <td role="cell">Google Analytics</td>
                <td role="cell">_ga<br />_gat<br />_gid<br /></td>
                <td role="cell">
                  Estas cookies se utilizan para recoger información sobre las
                  vistas a nuestro sitio web. Usamos dicha información para
                  generar informes sobre los modos de uso que nos ayudan a
                  mejorarlo. Las cookies recogen información anómina de las
                  visitas incluyendo el número de visitantes, qué páginas
                  visitan y las características del dispositivo utilizado.
                </td>
              </tr>
            </tbody>
          </table>
          <div class="table-footer">
            <p class="table-footer__intro">
              Utilizamos principalmente dos tipos de cookies:
            </p>
            <div class="table-footer__content">
              <div class="table-footer__item">
                <p class="table-footer__title">01 Cookies de "sesión"</p>
                <p class="table-footer__p">
                  Las cookies de sesión son bits temporales de información que
                  se borran una vez que sales de la ventana de tu navegador o
                  apagas el ordenador. Las cookies de sesión se utilizan, por
                  ejemplo, para mejorar la navegación en nuestros sitios web y
                  para recopilar información estadística agregada.
                </p>
              </div>
              <div class="table-footer__item">
                <p class="table-footer__title">02 Cookies "persistentes"</p>
                <p class="table-footer__p">
                  Las cookies persistentes son bits de información más
                  permanentes que se colocan en el disco duro de su ordenador y
                  permanecen allí a menos que usted las elimine. Las cookies
                  persistentes almacenan información en su ordenador para varios
                  propósitos, como la recuperación de cierta información que
                  usted ha proporcionado previamente y la personalización del
                  sitio web en función de sus preferencias de forma continuada.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section section--split">
        <h2 class="section__heading">¿Qué es un navegador?</h2>
        <p class="section__p">
          Un navegador es una aplicación que te permite navegar por Internet.
          Los navegadores más comunes son Chrome, Internet Explorer, Firefox y
          Safari. La mayoría de los navegadores son seguros y ofrecen formas
          rápidas y sencillas de eliminar información como las cookies.
        </p>
        <p class="section__p">
          Puedes bloquear las cookies utilizando la configuración de tu
          navegador de Internet. Sin embargo, si configuras tu navegador para
          bloquear todas las cookies, es posible que no puedas utilizar todo o
          parte de nuestro sitio.
        </p>
        <p class="section__p">
          Puedes elegir entre aceptar o rechazar las cookies. La mayoría de los
          navegadores web aceptan las cookies automáticamente, pero normalmente
          puedes modificar la configuración del navegador para rechazarlas si lo
          prefieres. Al continuar utilizando el sitio, aceptas el uso de
          cookies.
        </p>
        <ul class="browsers">
          <li class="browsers__item">
            <a
              class="link"
              href="https://support.google.com/accounts/answer/32050?hl=en&amp;co=GENIE.Platform%3DDesktop"
              target="_blank"
              >Chrome</a
            >
          </li>
          <li class="browsers__item">
            <a
              class="link"
              href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
              target="_blank"
              >Firefox</a
            >
          </li>
          <li class="browsers__item">
            <a
              class="link"
              href="http://windows.microsoft.com/es-es/windows-10/edge-privacy-faq"
              target="_blank"
              >Edge</a
            >
          </li>
          <li class="browsers__item">
            <a
              class="link"
              href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
              target="_blank"
              >Safari</a
            >
          </li>
          <li class="browsers__item browsers__item--spaced">
            <a
              class="link"
              href="https://support.google.com/accounts/answer/32050?hl=en&amp;co=GENIE.Platform%3DAndroid"
              target="_blank"
              >Android</a
            >
          </li>
          <li class="browsers__item">
            <a
              class="link"
              href="https://support.apple.com/en-us/HT201265"
              target="_blank"
              >iPhone</a
            >
          </li>
        </ul>
      </section>
    </div>
    <public-footer class="footer" :is-dark="false" />
  </div>
</template>

<script>
import PublicHeader from "@/components/public/PublicHeader.vue";
import PublicFooter from "@/components/public/PublicFooter.vue";
import { navigationGroupTypes } from "@/assets/logic/types";

const cookieName = "wealthabout.cookiespolicy";
export default {
  components: {
    PublicHeader,
    PublicFooter,
  },
  setup() {
    definePageMeta({
      layout: "public",
      navigationGroup: navigationGroupTypes.PUBLIC,
    });
  },
  auth: false,
  data: () => ({
    isAccepted: false,
  }),
  mounted() {
    if (this.getCookieValue()) {
      this.isAccepted = true;
    }
  },
  methods: {
    getCookieValue() {
      if (process.browser) {
        return this.$helpers.getCookie(cookieName);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cookies-policy {
  position: relative;
  padding-top: 12rem;

  .container {
    position: relative;
    width: 100%;
    max-width: 140rem;
    padding-right: $gap-s;
    padding-left: $gap-s;
    margin-right: auto;
    margin-left: auto;
  }

  &__heading {
    @include font-header-s;
    text-align: center;
    color: $color-background-main;
    margin: 2rem 0;
  }

  .section {
    position: relative;
    margin-top: 5rem;

    &__heading {
      @include font-title-s;
      text-align: center;
      color: $color-background-main;
      margin-bottom: 2rem;
    }

    &__p {
      @include font-body-m;
      text-align: justify;
      line-height: 2.4rem;
      margin: 0 0 2.5rem;
      color: $color-grey-500;
    }

    &__cookies {
      display: flex;
      * {
        margin-right: $gap-s;
      }
    }
  }

  .table-container {
    max-width: 106rem;

    th {
      @include font-title-xs;
      color: $color-gray-light;
      text-align: left;
      padding-bottom: 1.5rem;
    }

    td {
      @include font-body-m;
      line-height: 2.4rem;
      color: $color-grey-500;
      padding: 2rem 0;
    }
  }

  .table-footer {
    @include font-body-m;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: $color-grey-500;
    margin-top: 4rem;

    &__intro {
      max-width: 22rem;
      padding-right: 2rem;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &__content {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }

  .browsers {
    @include font-body-m;
    color: $color-grey-500;
    padding: 0;
    list-style-type: none;

    &__item {
      padding: 0.1rem 0;

      &--spaced {
        padding-top: 3rem;
      }
    }
  }

  .footer {
    margin-top: 5rem;
    max-width: 140rem;
    padding-right: $gap-s;
    padding-left: $gap-s;
    margin-right: auto;
    margin-left: auto;
  }
}

@include tablet-landscape {
  .cookies-policy {
    .section {
      margin-top: 5rem;
      padding-top: 5rem;

      .section__heading {
        position: absolute;
        top: 0;
        margin: 0;
        text-align: left;
      }

      &--split {
        column-count: 2;
        column-gap: 18rem;
      }

      &__p {
        text-align: left;
      }
    }
    .table-container {
      tr {
        display: flex;
      }

      td,
      th {
        width: 33.3%;
        padding-left: 0.8 rem;
        text-align: left;
      }
    }
  }
}

@include custom-device-max($screen-tablet-landscape-min) {
  .cookies-policy .table-container {
    table,
    tbody,
    td,
    tr {
      display: block;
    }

    thead {
      display: none;
    }

    td:first-of-type {
      border-top: 0.1rem solid $background;
    }

    td {
      position: relative;
      padding-left: 30%;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: justify;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 21%;
        white-space: nowrap;
        padding-top: 2rem;
      }

      &:first-of-type:before {
        content: "Cookie";
      }

      &:nth-of-type(2):before {
        content: "Nombre";
      }

      &:nth-of-type(3):before {
        content: "Objetivo";
      }
    }
  }
  .cookies-policy .table-footer {
    display: flex;
    flex-direction: column;
    max-width: none;

    &__intro {
      max-width: none;
      margin: 0;
    }

    &__p {
      text-align: justify;
    }
  }
}
</style>
