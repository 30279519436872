import { fieldsWizardRequiredActions } from "@/assets/logic/customize";

export function getFieldsEmpty(listWarnings, resourceId) {
  return listWarnings
    .filter(idAlert => idAlert.includes(resourceId))
    .map(idAlert => idAlert.replace(resourceId, ""))
    .filter(Boolean)
    .map(idAlert => {
      return getCustomRequiredActions(idAlert);
    });
}

export function getCustomRequiredActions(idField) {
  const fieldParsed = idField.split("-")[0];
  const typeRequiredActions = fieldsWizardRequiredActions;
  return typeRequiredActions.customization[fieldParsed];
}
